import { useTranslation } from 'react-i18next';

import { DetailUserModel } from 'api/v3/models';
import { usePermissions } from 'modules/shared/permissions';
import { DetailForm, DetailFormVariant } from 'shared/components/detailForm';
import { UserBasicInformationHeader } from './components/UserBasicInformationHeader';
import { UserBasicInformationRightSection } from './components/UserBasicInformationRightSection';
import { ActivityLog } from './forms/ActivityLog';
import { BasicInformation } from './forms/BasicInformation';
import { Groups } from './forms/Groups';
import { Roles } from './forms/Roles';
import { getDetailFormUser } from './mappings';
import { DetailUserFormProvider, detailUserFormSchema, DetailUserFormType, useDetailUserForm } from './UserFormProvider';

type Props = {
    user: DetailUserModel;
    onSubmit?: (UserFormValues: DetailUserFormType) => Promise<void>;
    onCancel?: () => void;
    variant?: DetailFormVariant;
};

export const UserDetailForm = ({ user, variant = DetailFormVariant.CREATE, onSubmit }: Props) => {
    const { t } = useTranslation();
    const defaultUser = getDetailFormUser(user);

    const form = useDetailUserForm({
        initialValues: defaultUser
    });

    const canAssignRoles = usePermissions('AssignRole');
    const canAssignGroups = usePermissions('Update');

    const handleConfirm = async () => {
        onSubmit && (await onSubmit(form.values));
        form.resetDirty();
    };

    const handleReset = () => {
        form.reset();
    };

    return (
        <DetailUserFormProvider form={form}>
            <DetailForm form={form} validationSchema={detailUserFormSchema} variant={variant} onConfirm={handleConfirm} onReset={handleReset}>
                <BasicInformation
                    form={form}
                    header={<UserBasicInformationHeader form={form} />}
                    label={t('basicInformation')}
                    labelRightSection={<UserBasicInformationRightSection form={form} />}
                    name="basicInformation"
                    schemaPath="basicInformation"
                    variant={variant}
                />
                <Roles disabled={!canAssignRoles} form={form} label={t('roles')} name="roles" schemaPath="roles" variant={variant} />
                <Groups disabled={!canAssignGroups} form={form} label={t('groups')} name="groups" schemaPath="groups" variant={variant} />
                <ActivityLog label={t('activityLog')} name="activityLog" userId={form.values.basicInformation.id} variant={variant} />
            </DetailForm>
        </DetailUserFormProvider>
    );
};
