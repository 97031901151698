import { Radio as ExternalRadio, RadioGroupProps as ExternalRadioGroupProps } from '@mantine/core';
import { forwardRef, RefAttributes } from 'react';

import { IMantineFormControl } from './ExtendedInputControls';
import { InputLabel, InputLabelProps } from './InputLabel';

export type RadioGroupProps = IMantineFormControl &
    InputLabelProps &
    Omit<ExternalRadioGroupProps, 'form'> &
    RefAttributes<HTMLInputElement>;

export const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(
    (
        { form, propertyPath: propertyName, label, iconDescription, optional, children, ...others }: RadioGroupProps,
        ref
    ) => {
        const props = form.getInputProps(propertyName, { type: 'checkbox' });

        return (
            <ExternalRadio.Group
                {...props}
                ref={ref}
                {...others}
                label={<InputLabel iconDescription={iconDescription} label={label} optional={optional} />}
            >
                {children}
            </ExternalRadio.Group>
        );
    }
);

RadioGroup.displayName = 'RadioGroup';
