import { UseFormReturnType } from '@mantine/form';

import { IRowData } from './interfaces';

export function handleRemoveItem<TRowType extends IRowData>(
    index: number,
    item: TRowType,
    /* eslint-disable @typescript-eslint/no-explicit-any */
    form: UseFormReturnType<any, any>,
    propertyPathBase: string
) {
    if (item.id) {
        form.setFieldValue(`${propertyPathBase}.${index}.isRemoved`, true);
    } else {
        form.removeListItem(propertyPathBase, index);
    }
}

export function handleDeleteSelectedItems<TRowType extends IRowData>({
    selectedItems,
    data,
    form,
    propertyPathBase
}: {
    selectedItems: TRowType[];
    data: TRowType[] /* eslint-disable @typescript-eslint/no-explicit-any */;
    form: UseFormReturnType<any, any>;
    propertyPathBase: string;
}) {
    const indexToDelete = selectedItems
        .map((selectedItem) => data.findIndex((item) => item.key === selectedItem.key))
        .sort()
        .reverse();

    indexToDelete.forEach((index) => handleRemoveItem(index, data[index], form, propertyPathBase));
}

export function handleAddItem<TRowType extends IRowData>(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    form: UseFormReturnType<any, any>,
    propertyPathBase: string,
    generateNewItem: () => TRowType
) {
    form.insertListItem(propertyPathBase, generateNewItem());
}
