import { OidcUserInfo } from '@axa-fr/oidc-client';
import { Badge } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { DetailGroupModel, GetRoleAssignmentResponseItem, PrincipalModel } from 'api/v3/models';
import { useGetTenantById } from 'api/v3/tenant/tenant';
import { usePermissions } from 'modules/shared/permissions';
import { DetailForm, DetailFormVariant } from 'shared/components/detailForm';
import { BasicInformation } from './forms/BasicInformation';
import { Members } from './forms/Members';
import { Roles } from './forms/Roles';
import { GroupFormChildHeader } from './GroupFormChildHeader';
import { GroupFormProvider, GroupFormType, groupFormSchema, useGroupForm } from './GroupFormProvider';
import { getFormGroup } from './mappings';

type Props = {
    currentUser?: OidcUserInfo;
    group?: DetailGroupModel;
    members?: PrincipalModel[];
    roles?: GetRoleAssignmentResponseItem[];
    onSubmit: (GroupFormValues: GroupFormType) => Promise<void>;
    onCancel?: () => void;
    variant?: DetailFormVariant;
    title?: string;
};

export const GroupForm = ({
    currentUser,
    group,
    members,
    roles,
    variant = DetailFormVariant.CREATE,
    onSubmit,
    title
}: Props) => {
    const { t } = useTranslation();
    const defaultGroup = getFormGroup(group, members, roles, currentUser);
    const canUpdateOrCreate = usePermissions(['Update', 'Create']);
    const { data: tenant } = useGetTenantById(defaultGroup.basicInformation.tenantId, undefined, {
        query: { enabled: !!defaultGroup.basicInformation.tenantId }
    });

    const form = useGroupForm({
        initialValues: defaultGroup
    });

    const handleConfirm = async () => {
        await onSubmit(form.values);
        form.resetDirty();
    };

    const handleReset = () => {
        form.reset();
    };

    return (
        <GroupFormProvider form={form}>
            <DetailForm
                form={form}
                title={title}
                validationSchema={groupFormSchema}
                variant={variant}
                onConfirm={canUpdateOrCreate ? handleConfirm : undefined}
                onReset={handleReset}
            >
                <BasicInformation
                    header={<GroupFormChildHeader data={group} name="basicInformation" />}
                    label={t('basicInformation')}
                    labelRightSection={tenant && <Badge>{`${t('tenant')}: ${tenant?.data.name}`}</Badge>}
                    name="basicInformation"
                    schemaPath="basicInformation"
                    variant={variant}
                />
                <Members
                    header={<GroupFormChildHeader data={group} name="members" />}
                    label={t('members')}
                    name="members"
                    schemaPath="members"
                    variant={variant}
                />
                <Roles
                    header={<GroupFormChildHeader data={group} name="roles" />}
                    label={t('roles')}
                    name="roles"
                    schemaPath="roles"
                    variant={variant}
                />
            </DetailForm>
        </GroupFormProvider>
    );
};
