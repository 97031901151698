import { Icon } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMoveClient } from 'api/v3/clients/clients';
import { BasicClientModel } from 'api/v3/models';
import { usePermissions } from 'modules/shared/permissions';
import { ItemCardAction } from 'shared/components/ItemCardAction';
import { MoveClientModal } from './MoveClientModal';

type Props = {
    client: BasicClientModel;
};

export const MoveClientAction = ({ client }: Props) => {
    const { t } = useTranslation();
    const [isOpened, setIsOpened] = useState(false);
    const { mutateAsync } = useMoveClient();
    const canDelete = usePermissions('Delete');

    if (!canDelete) {
        return null;
    }

    const handleMoveClient = async (applicationId: string) => {
        await mutateAsync({ id: client.id, applicationId });
        setIsOpened(false);
    };

    return (
        <>
            {/* Only render MoveClientModal when modal is really open to prevent fetching the application list when the modal is not yet displayed */}
            {isOpened && <MoveClientModal client={client} opened={isOpened} onCancel={() => setIsOpened(false)} onConfirm={handleMoveClient} />}
            <ItemCardAction icon={<Icon icon="move_item" />} title={t('moveClientDescription')} onClick={() => setIsOpened(true)} />
        </>
    );
};
