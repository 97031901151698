import { Button, Card } from '@mantine/core';
import { Icon } from '@uag/react-core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Placeholder } from 'shared/components/placeholder';
import itemNotFound from './assets/itemNotFound.svg';

type Props = {
    itemId: string;
    itemType: string;
    goBackRoute?: string;
    errorStatus?: number;
};

export const ItemNotFound = ({ itemId, itemType, goBackRoute, errorStatus }: Props) => {
    const { t } = useTranslation();

    const baseStyle: React.CSSProperties = {
        height: '100%',
        width: '100%'
    };
    return (
        <Card padding="lg" radius="md" shadow="lg" style={baseStyle}>
            <Placeholder
                action={
                    goBackRoute && (
                        <Button component={Link} leftSection={<Icon icon="arrow_left_alt" />} to={goBackRoute}>
                            {t('goBackTo')}
                        </Button>
                    )
                }
                description={
                    <Trans
                        components={{ bold: <strong /> }}
                        i18nKey={errorStatus === 422 ? 'itemIdNotValid' : 'itemNotExists'}
                        ns="translations"
                        values={{ itemId, itemType }}
                    />
                }
                picture={itemNotFound}
                text={t('itemNotFound', { itemType })}
            />
        </Card>
    );
};
