import { HoverCard, MantineThemeOverride, NativeSelect, Popover } from '@mantine/core';

export const getCustomeThemeOverrides = (): MantineThemeOverride => ({
    components: {
        Popover: Popover.extend({
            defaultProps: {
                unstyled: true
            },
            classNames: {
                dropdown: 'card p-md'
            }
        }),
        NativeSelect: NativeSelect.extend({
            defaultProps: {
                unstyled: true
            },
            classNames: {
                root: 'select-container',
                label: 'select-label',
                wrapper: 'select-wrapper',
                input: 'select'
            }
        }),
        HoverCard: HoverCard.extend({
            classNames: {
                dropdown: 'absolute card'
            }
        })
    }
});
