import { useQueryClient } from '@tanstack/react-query';
import { toastNotifications } from '@uag/react-core';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import { UserState, BasicUserModelApiResponse } from 'api/v3/models';
import { useDeleteUserById, getSearchUsersQueryKey, useHardDeleteUserById } from 'api/v3/user/user';
import { usePermissions } from 'modules/shared/permissions';
import { DeleteItemAction } from 'shared/components/DeleteItemAction';
import { RowActionVariant } from 'shared/components/extendedDataTable';

export const DeleteUserAction = (user: BasicUserModelApiResponse, index: number, rowActionVariant: RowActionVariant) => {
    const { t } = useTranslation();
    const { mutateAsync: deleteUser } = useDeleteUserById();
    const { mutateAsync: hardDelete } = useHardDeleteUserById();
    const queryClient = useQueryClient();
    const id = useId();
    const canDelete = usePermissions('SoftDeleteUser', 'User', user);

    const handleDeleteUser = async () => {
        toastNotifications.loading({
            id,
            message: t('willBe', {
                type: t('user'),
                name: user.data.displayName,
                action: user.data.state === UserState.Deleted ? t('deletedPermanent') : t('deleted')
            })
        });
        try {
            if (user.data.state === UserState.Deleted) {
                await hardDelete({ id: user.data.id });
            } else {
                await deleteUser({ id: user.data.id });
            }
            await queryClient.invalidateQueries({ queryKey: getSearchUsersQueryKey() });

            toastNotifications.sucess({
                message: t('wasSuccessful', {
                    type: t('user'),
                    name: user.data.displayName,
                    action: t('delete')
                })
            });
        } catch {
            toastNotifications.error({
                message: t('wasNotSuccessful', {
                    type: t('user'),
                    name: user.data.displayName,
                    action: user.data.state === UserState.Deleted ? t('hardDelete') : t('delete')
                })
            });
        } finally {
            toastNotifications.hide(id);
        }
    };

    return (
        canDelete && (
            <DeleteItemAction
                itemType="user"
                labelKey={user.data.state === UserState.Deleted ? 'hardDelete' : 'delete'}
                name={user.data.displayName ?? undefined}
                rowActionVariant={rowActionVariant}
                titleKey={user.data.state === UserState.Deleted ? 'hardDeleteItem' : 'deleteItem'}
                onDeleteItem={handleDeleteUser}
            />
        )
    );
};
