import { Button, Modal, Stack, Text, Title } from '@mantine/core';
import { LoadingAnimation } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { useGetApiResourceScopeClients } from 'api/v3/api-resources/api-resources';
import { listQueryDefault } from 'app/queryDefaults';
import { OverviewGrid } from 'shared/components/OverviewGrid';

type PrincipalsSharingScopeProps = {
    scopeId: string;
    onClose: () => void;
};

export const ClientsUsingScopeModal = ({ scopeId, onClose: handleClose }: PrincipalsSharingScopeProps) => {
    const { t } = useTranslation();
    const { data: clientsUsingScope, error, isLoading: areClientsUsingScopeLoading } = useGetApiResourceScopeClients(scopeId, listQueryDefault);

    if (!clientsUsingScope && !areClientsUsingScopeLoading) {
        return null;
    }

    return (
        <Modal size="xl" title={t('clientsUsingScope')} opened onClose={handleClose}>
            <Stack>
                {areClientsUsingScopeLoading ? (
                    <LoadingAnimation />
                ) : (
                    <>
                        <Title order={4}>{clientsUsingScope?.data.displayName}</Title>
                        {clientsUsingScope?.data.description && <Text>{clientsUsingScope.data.description}</Text>}
                        <OverviewGrid
                            data={clientsUsingScope?.data.clients ?? []}
                            error={error}
                            fieldDescriptions={[
                                {
                                    header: t('itemName', { itemType: t('client') }),
                                    data: 'name'
                                },
                                {
                                    header: t('displayName'),
                                    data: 'displayName'
                                },
                                {
                                    header: t('description'),
                                    data: 'description'
                                }
                            ]}
                        />
                    </>
                )}
                <Button ml="auto" onClick={handleClose}>
                    {t('gotIt')}
                </Button>
            </Stack>
        </Modal>
    );
};
