import { Tooltip, ActionIcon, Menu } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { Icon } from '@uag/react-core';
import { ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailFormContext } from '../detailForm';
import { DataTable } from './DataTable';
import { handleAddItem, handleDeleteSelectedItems, handleRemoveItem } from './ExtendedDataTable.functions';
import { AddItem, RemoveItem } from './HeaderActions';
import { IFieldDescription, IRowData } from './interfaces';
import { RowActionVariant } from './Row';

type Props<TRowType extends IRowData> = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturnType<any, any>;
    propertyPathBase: string;
    fieldDescriptions: IFieldDescription<TRowType>[];
    data: TRowType[];
    generateNewItem?: () => TRowType;
    placeholder?: ReactNode;
    additionalRowActions?: ((value: TRowType, index: number, variant: RowActionVariant) => ReactNode)[];
    renderEdit?: (value: TRowType, index: number, onItemSaved?: () => void) => ReactNode;
    maxHeight?: number | string;
    isReadOnly?: boolean;
    canCreate?: boolean;
    canEdit?: boolean;
    canRemove?: boolean;
    error?: unknown;
    canRemoveItem?: (item: TRowType) => boolean;
    canEditItem?: (item: TRowType) => boolean;
};

export const ExtendedDataTable = <TRowType extends IRowData>({
    form,
    propertyPathBase,
    data,
    placeholder,
    fieldDescriptions,
    generateNewItem,
    additionalRowActions,
    renderEdit,
    maxHeight,
    isReadOnly,
    canCreate = true,
    canEdit = true,
    canRemove = true,
    error,
    canRemoveItem,
    canEditItem
}: Props<TRowType>) => {
    const { t } = useTranslation();
    const [selectedItems, setSelectedItems] = useState<TRowType[]>([]);
    const detailForm = useContext(DetailFormContext);

    const removeRow = (item: TRowType, index: number, rowActionVariant: RowActionVariant) => {
        if (!(canRemoveItem ? canRemoveItem(item) : true) || item.isEditable || item.isRemoved || isReadOnly || !canRemove) {
            return null;
        }

        return rowActionVariant === RowActionVariant.DEFAULT ? (
            <Tooltip label={t('remove')}>
                <ActionIcon onClick={() => handleRemoveItem(index, item, form, propertyPathBase)}>
                    <Icon icon="close" />
                </ActionIcon>
            </Tooltip>
        ) : (
            <Menu.Item leftSection={<Icon icon="close" />} onClick={() => handleRemoveItem(index, item, form, propertyPathBase)}>
                {t('remove')}
            </Menu.Item>
        );
    };

    const editRow = (item: TRowType, index: number, rowActionVariant: RowActionVariant) => {
        if (
            !(canEditItem ? canEditItem(item) : true) ||
            data.some((d) => d.isEditable) ||
            !fieldDescriptions.some((fieldDescription) => !fieldDescription.lockUpdate) ||
            item.isRemoved ||
            !canEdit
        ) {
            return null;
        }

        return rowActionVariant === RowActionVariant.DEFAULT ? (
            <Tooltip label={t('edit')}>
                <ActionIcon
                    disabled={data.some((item) => item.isEditable)}
                    onClick={() => form.setFieldValue(`${propertyPathBase}.${index}.isEditable`, true)}
                >
                    <Icon icon="edit" />
                </ActionIcon>
            </Tooltip>
        ) : (
            <Menu.Item
                disabled={data.some((item) => item.isEditable)}
                leftSection={<Icon icon="edit" />}
                onClick={() => form.setFieldValue(`${propertyPathBase}.${index}.isEditable`, true)}
            >
                {t('edit')}
            </Menu.Item>
        );
    };

    const saveItem = (item: TRowType, index: number) => {
        if (!item.isEditable || !canRemove) {
            return null;
        }
        return (
            <Tooltip label={t('save')}>
                <ActionIcon
                    onClick={() => {
                        if (!detailForm || detailForm.onValidate()) {
                            form.setFieldValue(`${propertyPathBase}.${index}.isEditable`, false);
                            generateNewItem && handleAddItem<TRowType>(form, propertyPathBase, generateNewItem);
                        }
                    }}
                >
                    <Icon icon="check" />
                </ActionIcon>
            </Tooltip>
        );
    };

    const cancelEditItem = (item: TRowType, index: number) => {
        if (!item.isEditable || item.id) {
            return null;
        }
        return (
            <Tooltip label={t('cancel')}>
                <ActionIcon
                    onClick={() => {
                        form.removeListItem(propertyPathBase, index);
                    }}
                >
                    <Icon icon="close" />
                </ActionIcon>
            </Tooltip>
        );
    };

    const handleItemsSelected = (items: TRowType[]) => {
        setSelectedItems(items);
    };

    const rowActions = [...(additionalRowActions ?? []), ...[editRow, removeRow, saveItem, cancelEditItem]];

    return (
        <DataTable
            data={data}
            error={error}
            fieldDescriptions={fieldDescriptions}
            form={form}
            headerActions={[
                generateNewItem && !isReadOnly && canCreate && (
                    <AddItem key="Add" data={data} onClick={() => handleAddItem<TRowType>(form, propertyPathBase, generateNewItem)} />
                ),
                canRemove && !isReadOnly && (
                    <RemoveItem
                        key="Delete"
                        canRemoveItem={canRemoveItem}
                        selectedItems={selectedItems}
                        onClick={() => handleDeleteSelectedItems<TRowType>({ selectedItems, data, form, propertyPathBase })}
                    />
                )
            ]}
            maxHeight={maxHeight}
            placeholder={placeholder}
            propertyPathBase={propertyPathBase}
            renderEdit={renderEdit}
            rowActions={rowActions}
            withSelection={canRemove} // selection only makes sense when user can remove items
            withHeaders
            withSearch
            onItemsSelected={handleItemsSelected}
            onRowSaved={() => {
                generateNewItem && handleAddItem<TRowType>(form, propertyPathBase, generateNewItem);
            }}
        />
    );
};
