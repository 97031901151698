import { ApiResponseMetadata, GetPermissionAssignmentsResponse, RoleAssignmentContextType } from 'api/v3/models';
import { PermittedItem } from './types';

const permissionPrefix = 'UmdaschGroup.Identity';

export const PermissionUtils = {
    getPermittedItem(...permittedItems: (PermittedItem | undefined)[]): PermittedItem | undefined {
        return permittedItems.find((item) => item?.metadata?.permissions);
    },
    hasPermission(
        contextType: RoleAssignmentContextType,
        desiredPermission: string,
        permittedItem?: { metadata?: ApiResponseMetadata | null }
    ): boolean {
        return (
            !!permittedItem?.metadata?.permissions &&
            permittedItem.metadata.permissions.some((permission) => permission === this.buildPermissionIdentifier(contextType, desiredPermission))
        );
    },
    buildPermissionIdentifier(contextType: RoleAssignmentContextType, permission: string): string {
        return [permissionPrefix, contextType, permission].join('.');
    },
    getPermittedItemFromPermissionResponse(response: GetPermissionAssignmentsResponse): PermittedItem {
        return {
            metadata: {
                permissions: response.data.map((permission) => permission.permission.permissionIdentifier)
            }
        };
    }
};
