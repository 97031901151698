import { Button, Tooltip, ActionIcon, Menu } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { Icon } from '@uag/react-core';
import { MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { usePermissions } from 'modules/shared/permissions';
import { DetailFormChildProps, DetailFormVariant } from 'shared/components/detailForm';
import { ExtendedDataTable, RowActionVariant } from 'shared/components/extendedDataTable';
import { Placeholder } from 'shared/components/placeholder';
import { useDataModalState } from 'shared/hooks/useDataModalState';
import { RolesFormType, useApiFormContext } from '../ApiFormProvider';
import rolesEmpty from '../assets/roles_empty.svg';
import { ManageRoleMembersModal } from '../components/ManageRoleMembersModal';

const generateNewRole = () =>
    ({
        key: randomId(),
        name: '',
        displayName: '',
        description: '',
        isEditable: true,
        isRemoved: false
    }) as RolesFormType;

const AddRole = () => {
    const form = useApiFormContext();
    const { t } = useTranslation();

    return (
        <Button
            disabled={form.values.roles.some((item) => item.isEditable)}
            leftSection={<Icon icon="add" />}
            onClick={() => form.insertListItem('roles', generateNewRole())}
        >
            {t('addItem', { itemType: t('role') })}
        </Button>
    );
};

const NoRolesPlaceholder = () => {
    const { t } = useTranslation();
    const canCreate = usePermissions('Create', 'ApiResourceRole');
    return <Placeholder action={canCreate ? <AddRole /> : undefined} picture={rolesEmpty} text={t('noRolesDefined')} />;
};

type Props = DetailFormChildProps;

export const Roles = ({ variant }: Props) => {
    const { t } = useTranslation();
    const form = useApiFormContext();
    const {
        open: openManageMembersModal,
        isOpen: isManageMembersModalOpen,
        close: closeManageMembersModal,
        data: manageMembersModalData
    } = useDataModalState<RolesFormType>();
    const canCreate = usePermissions('Create', 'ApiResourceRole');
    const canEdit = usePermissions('Update', 'ApiResourceRole');
    const canDelete = usePermissions('Delete', 'ApiResourceRole');

    const ManageMembersAction = (item: RolesFormType, index: number, rowActionVariant: RowActionVariant) => {
        if (item.isEditable || !item.id || item.isRemoved || variant === DetailFormVariant.CREATE) {
            return null;
        }

        const handleOpenManageMembersModal = (event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            openManageMembersModal(item);
        };

        return rowActionVariant === RowActionVariant.DEFAULT ? (
            <Tooltip label={t('manageMembers')}>
                <ActionIcon onClick={handleOpenManageMembersModal}>
                    <Icon icon="group" />
                </ActionIcon>
            </Tooltip>
        ) : (
            <Menu.Item leftSection={<Icon icon="group" />} onClick={handleOpenManageMembersModal}>
                {t('manageMembers')}
            </Menu.Item>
        );
    };

    return (
        <>
            {isManageMembersModalOpen && manageMembersModalData && (
                <ManageRoleMembersModal role={manageMembersModalData} onClose={() => closeManageMembersModal()} />
            )}
            <ExtendedDataTable
                additionalRowActions={[ManageMembersAction]}
                canCreate={canCreate}
                canEdit={canEdit}
                canRemove={canDelete}
                data={form.values.roles}
                fieldDescriptions={[
                    {
                        header: t('name'),
                        prefix: (item) => (!item.id ? `${form.values.api.name}/` : null),
                        description: <Trans i18nKey="scopeRoleNameDescription" />,
                        placeholder: 'administrator',
                        data: 'name',
                        lockUpdate: true
                    },
                    { header: t('displayName'), data: 'displayName' },
                    { header: t('description'), data: 'description' }
                ]}
                form={form}
                generateNewItem={() => generateNewRole()}
                placeholder={<NoRolesPlaceholder />}
                propertyPathBase="roles"
            />
        </>
    );
};
