import { useState } from 'react';

export function useDataModalState<TModelData>(initialData?: TModelData) {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState<TModelData | undefined>(initialData);

    function open(data: TModelData) {
        setData(data);
        setIsOpen(true);
    }

    function close() {
        setData(undefined);
        setIsOpen(false);
    }

    return { isOpen, open, close, data };
}
