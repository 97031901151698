import { createFormContext } from '@mantine/form';
import { z } from 'zod';

import { AssignmentPrincipalType, ClientAccessibility, PrincipalType } from 'api/v3/models';
import { i18n } from 'i18n';
import { uriRegex, validCorsOrigin } from 'modules/shared/regex';
import { IRowData } from 'shared/components/extendedDataTable/interfaces';

const validUri = z.string().regex(uriRegex, i18n.t('invalidUri'));
const requiredUri = validUri.min(1, { message: i18n.t('fieldRequired') });

const basicInformationSchema = z.object({
    name: z.string().min(1, { message: i18n.t('fieldRequired') }),
    id: z.string().optional(),
    clientPictureUri: z.string().nullish(),
    description: z.string().nullish(),
    allowedGrantTypes: z
        .object({
            authorizationCode: z.boolean(),
            clientCredentials: z.boolean(),
            tokenExchange: z.boolean()
        })
        .superRefine((val, ctx) => {
            if (!!val.authorizationCode || !!val.clientCredentials || !!val.tokenExchange) {
                return z.NEVER;
            }

            Object.keys(val).forEach((key) =>
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: i18n.t('noGrantTypeSelected'),
                    path: [key]
                })
            );
        }),
    enabled: z.boolean(),
    clientBackgroundUri: z.string().nullish()
});

const uriSettingsSchema = z.object({
    baseUri: validUri.nullish(),
    redirectUris: requiredUri.array(),
    postLogoutRedirectUris: requiredUri.array(),
    allowedCorsOrigins: requiredUri.regex(validCorsOrigin, i18n.t('invalidCorsOrigin')).array(),
    frontChannelLogoutUri: validUri.nullish(),
    backChannelLogoutUri: validUri.nullish()
});

const accessibilitySettingsSchema = z.object({
    accessibility: z.nativeEnum(ClientAccessibility),
    principalsWithAccess: z
        .object({
            id: z.string().min(1, { message: i18n.t('fieldRequired') }),
            displayName: z.string().nullish(),
            email: z.string().nullish(),
            type: z.nativeEnum(PrincipalType),
            assignmentType: z.nativeEnum(AssignmentPrincipalType).optional()
        })
        .array(),

    visibleToUsers: z.boolean()
});

const permissionSettingsSchema = z.object({
    clientScopes: z
        .object({
            id: z.string().min(1, { message: i18n.t('fieldRequired') }),
            name: z.string().min(1, { message: i18n.t('fieldRequired') }),
            displayName: z.string().nullish(),
            description: z.string().nullish()
        })
        .array(),
    roleAssignments: z
        .object({
            id: z.string().min(1, { message: i18n.t('fieldRequired') }),
            name: z.string().min(1, { message: i18n.t('fieldRequired') }),
            displayName: z.string().nullish()
        })
        .array(),
    relatedClients: z
        .object({
            id: z.string().min(1, { message: i18n.t('fieldRequired') }),
            displayName: z.string().min(1, { message: i18n.t('fieldRequired') })
        })
        .array(),
    identityPermissions: z.object({
        isOpenIdScopeRequestAllowed: z.boolean(),
        isProfileScopeRequestAllowed: z.boolean(),
        isEmailScopeRequestAllowed: z.boolean(),
        isPhoneScopeRequestAllowed: z.boolean(),
        isRolesScopeRequestAllowed: z.boolean()
    })
});

const securitySettingsSchema = z.object({
    enablePKCE: z.boolean(),
    identityTokenLifetime: z.string(),
    accessTokenLifetime: z.string(),
    refreshTokenEnabled: z.boolean(),
    refreshTokenLifetime: z.string(),
    includeUserClaims: z.boolean(),
    refreshTokenRotation: z.boolean(),
    refreshTokenSlidingLifetime: z.string(),
    requireClientSecret: z.boolean(),
    authorizationCodeLifetime: z.string()
});

export const clientFormSchema = z.object({
    basicInformation: basicInformationSchema,
    uriSettings: uriSettingsSchema,
    accessibilitySettings: accessibilitySettingsSchema,
    permissionSettings: permissionSettingsSchema,
    securitySettings: securitySettingsSchema
});

type ZodClientFormValues = z.infer<typeof clientFormSchema>;

export type ClientFormValues = Omit<ZodClientFormValues, 'permissionSettings' | 'accessibilitySettings'> & {
    permissionSettings: Omit<ZodClientFormValues['permissionSettings'], 'clientScopes' | 'roleAssignments' | 'relatedClients'> & {
        clientScopes: (ZodClientFormValues['permissionSettings']['clientScopes'][0] & IRowData)[];
        roleAssignments: (ZodClientFormValues['permissionSettings']['roleAssignments'][0] & IRowData)[];
        relatedClients: (ZodClientFormValues['permissionSettings']['relatedClients'][0] & IRowData)[];
    };
    accessibilitySettings: Omit<ZodClientFormValues['accessibilitySettings'], 'principalsWithAccess'> & {
        principalsWithAccess: (ZodClientFormValues['accessibilitySettings']['principalsWithAccess'][0] & IRowData)[];
    };
};

export type PrincipalsWithAccessGridType = ClientFormValues['accessibilitySettings']['principalsWithAccess'][0];
export type RelatedClientGridType = ClientFormValues['permissionSettings']['relatedClients'][0];

// You can give context variables any name
export const [ClientFormProvider, useClientFormContext, useClientForm] = createFormContext<ClientFormValues>();
