import { useQueryClient } from '@tanstack/react-query';
import { LoadingAnimation } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
    useGetGroupDetailsById,
    useUpdateGroup,
    useGetAllGroupMembers,
    useAddRemoveGroupMembers,
    useAssignRevokeGroupRoles,
    getGetGroupByIdQueryKey
} from 'api/v3/groups/groups';
import { useGetApiResourceRoleAssignments } from 'api/v3/role/role';
import { listQueryDefault } from 'app/queryDefaults';
import { PermissionProvider } from 'modules/shared/permissions';
import { DetailFormVariant } from 'shared/components/detailForm';
import { ItemNotFound } from 'shared/components/ItemNotFound';
import { GroupForm } from './GroupForm';
import { GroupFormType } from './GroupFormProvider';
import { getGroupModel, getGroupAssigneeActions, getRoleAssignmentActions } from './mappings';

export const EditGroup = () => {
    const { groupId = '' } = useParams();
    const { t } = useTranslation();
    const { mutateAsync: updateGroupAsync } = useUpdateGroup();
    const { mutateAsync: assignRemoveGroupMemberAsync } = useAddRemoveGroupMembers();
    const { mutateAsync: assignRemoveGroupRolesAsync } = useAssignRevokeGroupRoles();

    const queryClient = useQueryClient();

    const {
        data: groupApiResponse,
        isLoading: isGroupLoading,
        error,
        queryKey: groupQueryKey
    } = useGetGroupDetailsById(groupId, { includePermissions: true });
    const {
        data: groupMembersApiResponse,
        queryKey: groupMembersQueryKey,
        isLoading: areMembersLoading
    } = useGetAllGroupMembers(groupId, { $top: 999 }, listQueryDefault);

    const {
        data: roles,
        isLoading: areRolesLoading,
        queryKey: rolesQueryKey
    } = useGetApiResourceRoleAssignments(
        {
            principalType: 'GroupId',
            principalIdentifier: groupId,
            includeInheritedPrincipalAssignments: false
        },
        listQueryDefault
    );

    if (error) {
        return <ItemNotFound errorStatus={error.response?.status} goBackRoute="../" itemId={groupId} itemType={t('group')} />;
    }

    if (!groupApiResponse || isGroupLoading || areMembersLoading || areRolesLoading) {
        return <LoadingAnimation />;
    }
    const groupMembers = groupMembersApiResponse?.data;

    const handleSubmit = async (groupFormValues: GroupFormType) => {
        const groupToUpdate = getGroupModel(groupFormValues);
        const memberAssigneeActions = getGroupAssigneeActions(groupFormValues.members, groupMembers);
        const roleAssignmentActions = getRoleAssignmentActions(groupFormValues.roles, roles?.data);

        await updateGroupAsync({ id: groupId, data: groupToUpdate });

        await assignRemoveGroupMemberAsync({
            id: groupId,
            data: {
                memberAssigneeActions
            }
        });

        await assignRemoveGroupRolesAsync({
            id: groupId,
            data: {
                roleAssignmentActions
            }
        });

        await queryClient.invalidateQueries({ queryKey: groupQueryKey });
        await queryClient.invalidateQueries({ queryKey: groupMembersQueryKey });
        await queryClient.invalidateQueries({ queryKey: rolesQueryKey });
        await queryClient.invalidateQueries({ queryKey: getGetGroupByIdQueryKey(groupId) });
    };

    return (
        <PermissionProvider context="Group" item={groupApiResponse}>
            <GroupForm
                group={groupApiResponse.data}
                members={groupMembers}
                roles={roles?.data}
                variant={DetailFormVariant.EDIT}
                onSubmit={handleSubmit}
            />
        </PermissionProvider>
    );
};
