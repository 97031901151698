import { Text } from '@mantine/core';

import { useDeleteApiResource } from 'api/v3/api-resources/api-resources';
import { BasicApiResourceModel, BasicApiResourceModelApiResponse } from 'api/v3/models';
import { DuplicateApiAction } from 'modules/applications/api/components/DuplicateApiAction';
import { usePermissions } from 'modules/shared/permissions';
import { ShowRoleAssignmentsAction } from 'modules/shared/roleAssignments/ShowRoleAssignmentsAction';
import { ItemCard } from 'shared/components';
import { ItemCardPropsBase } from 'shared/components/ItemCard';
import { MoveApiAction } from './moveApi';

type Props = ItemCardPropsBase<BasicApiResourceModelApiResponse>;

type ApiCardContentProps = {
    api: BasicApiResourceModel;
};

const ApiCardContent = ({ api }: ApiCardContentProps) => {
    return (
        <>
            <Text fw={500} lineClamp={1} size="lg">
                {api.displayName}
            </Text>

            <Text c="dimmed" lineClamp={4} size="sm" style={{ flex: '1' }}>
                {api.name}
            </Text>
        </>
    );
};

export const ApiCard = ({ item: api, onItemDelete }: Props) => {
    const { mutateAsync: deleteApiResourceMutation } = useDeleteApiResource();

    const canReadBasic = usePermissions('ReadBasic');
    const canDelete = usePermissions('Delete');

    const handleDeleteApi = async () => {
        await deleteApiResourceMutation({ id: api.data.id });
        onItemDelete();
    };

    return (
        <ItemCard
            actions={[
                <DuplicateApiAction key="duplicateApi" apiId={api.data.id} />,
                <ShowRoleAssignmentsAction
                    key="showRoleAssignments"
                    contextId={api.data.id}
                    contextType="ApiResource"
                    itemName={api.data.name}
                />,
                <MoveApiAction key="moveApi" api={api.data} />
            ]}
            deleteConfirmation={api.data.displayName ?? undefined}
            itemType="api"
            name={api.data.displayName ?? undefined}
            to={canReadBasic ? api.data.id : undefined}
            onDelete={canDelete ? handleDeleteApi : undefined}
        >
            <ApiCardContent api={api.data} />
        </ItemCard>
    );
};
