import { ActionIcon, Center, Menu, Modal, Tooltip } from '@mantine/core';
import { Icon, LoadingAnimation } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetGroupDetailsById, useGetAllGroupMembers } from 'api/v3/groups/groups';
import { useGetApiResourceRoleAssignments } from 'api/v3/role/role';
import { usePermissions } from 'modules/shared/permissions';
import { RowActionVariant } from 'shared/components/extendedDataTable';
import { CreateGroup } from '../CreateGroup';

import type { BasicGroupModelApiResponse, DetailGroupModel, GetRoleAssignmentResponseItem, PrincipalModel } from 'api/v3/models';

export const DuplicateGroupAction = (groupApiResponse: BasicGroupModelApiResponse, _: number, variant: RowActionVariant) => {
    const canCreate = usePermissions('Create');
    const { t } = useTranslation();
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);

    if (!canCreate) {
        return null;
    }

    return (
        <>
            {variant === RowActionVariant.DEFAULT ? (
                <Tooltip label={t('duplicateItem', { itemType: t('group') })}>
                    <ActionIcon
                        onClick={(event) => {
                            event.stopPropagation();
                            setIsDuplicateModalOpen(true);
                        }}
                    >
                        <Icon icon="content_copy" />
                    </ActionIcon>
                </Tooltip>
            ) : (
                <Menu.Item
                    leftSection={<Icon icon="content_copy" />}
                    onClick={() => {
                        setIsDuplicateModalOpen(true);
                    }}
                >
                    {t('duplicateItem', { itemType: t('group') })}
                </Menu.Item>
            )}

            {isDuplicateModalOpen && <DuplicateGroupModal groupId={groupApiResponse.data.id} onClose={() => setIsDuplicateModalOpen(false)} />}
        </>
    );
};

type DuplicateGroupModalProps = {
    groupId: string;
    onClose: () => void;
};

const DuplicateGroupModal = ({ onClose: handleClose, groupId }: DuplicateGroupModalProps) => {
    const { t } = useTranslation();
    const { data: fullGroupResponse } = useGetGroupDetailsById(groupId, undefined);
    const { data: resourceRolesResponse } = useGetApiResourceRoleAssignments({
        principalType: 'GroupId',
        principalIdentifier: groupId,
        includeInheritedPrincipalAssignments: false
    });
    const { data: membersResponse } = useGetAllGroupMembers(groupId);

    const duplicatedGroupDetails: DetailGroupModel | undefined = fullGroupResponse && {
        ...fullGroupResponse?.data,
        id: '',
        name: `${fullGroupResponse.data.name} - copy`
    };

    const duplicatedResourceRoles: GetRoleAssignmentResponseItem[] | undefined = structuredClone(resourceRolesResponse?.data);

    const duplicatedMembers: PrincipalModel[] | undefined = structuredClone(membersResponse?.data);

    return (
        <Modal
            closeOnClickOutside={false}
            opened={true}
            size={1000}
            title={t('duplicateItem', { itemType: t('group') })}
            centered
            onClick={(event) => {
                event.stopPropagation();
            }}
            onClose={handleClose}
        >
            {!duplicatedGroupDetails ? (
                <Center>
                    <LoadingAnimation />
                </Center>
            ) : (
                <CreateGroup
                    initialValues={{
                        group: duplicatedGroupDetails,
                        roles: duplicatedResourceRoles,
                        members: duplicatedMembers
                    }}
                    onCancel={handleClose}
                />
            )}
        </Modal>
    );
};
