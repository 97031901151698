import { Button, Stack } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { getPagingParams, ItemsPerPageType, Pagination, Icon } from '@uag/react-core';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BasicTenantModelApiResponse } from 'api/v3/models';
import { getGetTenantsQueryKey, useDeleteTenant, useGetTenants, useSearchTenants } from 'api/v3/tenant/tenant';
import { listQueryDefault } from 'app/queryDefaults';
import { PermissionProvider, usePermissions } from 'modules/shared/permissions';
import { ShowRoleAssignmentsAction } from 'modules/shared/roleAssignments/ShowRoleAssignmentsAction';
import groupsEmpty from 'modules/userManagement/groups/assets/groups_empty.svg';
import { CreateItemModal } from 'shared/components';
import { DeleteItemAction } from 'shared/components/DeleteItemAction';
import { OverviewGrid } from 'shared/components/OverviewGrid';
import { Placeholder } from 'shared/components/placeholder';
import { getTotalPages } from 'utils/paginations';
import { CreateTenant } from './CreateTenant';

const NoTenantsPlaceholder = () => {
    const { t } = useTranslation();
    const canCreate = usePermissions('Create');

    return (
        <Placeholder
            action={
                canCreate && (
                    <CreateItemModal AddButton={AddTenant} AddDialogType={CreateTenant} title={t('createItem', { itemType: 'localTenant' })} />
                )
            }
            picture={groupsEmpty}
            text={t('noTenants')}
        />
    );
};

const AddTenant = ({ onClick }: { onClick: () => void }) => {
    const { t } = useTranslation();
    return (
        <Button leftSection={<Icon icon="add" />} onClick={() => onClick()}>
            {t('addItem', { itemType: t('tenant') })}
        </Button>
    );
};

const DeleteTenantAction = (tenant: BasicTenantModelApiResponse) => {
    const { mutateAsync: deleteTenantMutationAsync } = useDeleteTenant();
    const canDelete = usePermissions('Delete', 'Tenant', tenant);
    const queryClient = useQueryClient();

    if (!canDelete) {
        return undefined;
    }

    const handleDeleteTenant = async () => {
        await deleteTenantMutationAsync({ id: tenant.data.id });
        await queryClient.invalidateQueries({ queryKey: getGetTenantsQueryKey() });
    };

    return <DeleteItemAction confirmationText={tenant.data.name} itemType="tenant" name={tenant.data.name} onDeleteItem={handleDeleteTenant} />;
};

const ShowRoleAssignments = (tenant: BasicTenantModelApiResponse) => {
    return (
        <PermissionProvider context="Tenant" item={tenant}>
            <ShowRoleAssignmentsAction contextId={tenant.data.id} contextType="Tenant" itemName={tenant.data.name} />
        </PermissionProvider>
    );
};

export const Tenants = () => {
    const navigate = useNavigate();

    const [itemsPerPage, setItemsPerPage] = useState<ItemsPerPageType>(25);
    const [page, setPage] = useState(1);

    const [searchValue, setSearchValue] = useState<string>();
    const [debounceSearchValue] = useDebouncedValue(searchValue, 300);

    const {
        data: tenants,
        error: tenantsError,
        isLoading: areTenantsLoading
    } = useGetTenants(
        {
            includePermissions: true,
            ...getPagingParams(page, itemsPerPage)
        },
        {
            ...listQueryDefault,
            query: { enabled: !debounceSearchValue }
        }
    );
    const { t } = useTranslation();

    const {
        data: searchTenants,
        isLoading: areSearchTenantsLoading,
        error: searchTenantsError
    } = useSearchTenants(
        {
            searchTerm: debounceSearchValue,
            includePermissions: true,
            ...getPagingParams(page, itemsPerPage)
        },
        { ...listQueryDefault, query: { enabled: !!debounceSearchValue } }
    );

    const handleOnSearch = (text: string) => {
        setPage(1);
        setSearchValue(text);
    };

    const canCreate = usePermissions('Create', 'Tenant', searchTenants, tenants);

    const handleRowClicked = (_event: MouseEvent, item: BasicTenantModelApiResponse) => {
        if (item.data.id) {
            navigate(item.data.id);
        }
    };

    const totalPages = getTotalPages(itemsPerPage, debounceSearchValue, searchTenants, tenants);

    return (
        <PermissionProvider context="Tenant" items={[tenants, searchTenants]}>
            <Stack className="card w-full p-xl">
                <div className="text-4xl">{t('tenants')}</div>
                <OverviewGrid
                    AddDialogType={canCreate ? CreateTenant : undefined}
                    addDialogTitle={t('createItem', { itemType: t('localTenant') })}
                    additionalRowActions={[DeleteTenantAction, ShowRoleAssignments]}
                    data={(debounceSearchValue ? searchTenants?.data : tenants?.data) ?? []}
                    error={searchTenantsError ?? tenantsError}
                    fieldDescriptions={[
                        {
                            header: t('name'),
                            data: 'data.name',
                            lockUpdate: true
                        }
                    ]}
                    isLoading={areSearchTenantsLoading || areTenantsLoading}
                    placeholder={!debounceSearchValue && <NoTenantsPlaceholder />}
                    onRowClicked={handleRowClicked}
                    onSearch={(text) => handleOnSearch(text)}
                />
                <Pagination
                    disabled={areSearchTenantsLoading || areTenantsLoading}
                    itemsPerPage={itemsPerPage}
                    page={page}
                    totalPages={totalPages}
                    withSkip
                    onItemsPerPageChange={setItemsPerPage}
                    onPageChange={setPage}
                />
            </Stack>
        </PermissionProvider>
    );
};
