import { ReactNode, createContext, useContext } from 'react';

import { RoleAssignmentContextType } from 'api/v3/models';
import { PermissionUtils } from './PermissionUtils';
import { PermittedItem } from './types';

export type PermissionContextProps = {
    item?: PermittedItem;
    items?: (PermittedItem | undefined)[];
    context?: RoleAssignmentContextType;
};

export const PermissionContext = createContext<PermissionContextProps | null>(null);

type PermissionProviderProps = {
    children: ReactNode;
} & PermissionContextProps;

export const PermissionProvider = ({ item, items, context, children }: PermissionProviderProps) => {
    const permissionContext = useContext(PermissionContext);

    const permittedItem = PermissionUtils.getPermittedItem(item, ...(items ?? []));

    return (
        <PermissionContext.Provider
            value={{ item: permittedItem ?? permissionContext?.item, context: context ?? permissionContext?.context }}
        >
            {children}
        </PermissionContext.Provider>
    );
};
