/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */

export type RoleAssignmentContextType = typeof RoleAssignmentContextType[keyof typeof RoleAssignmentContextType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleAssignmentContextType = {
  Global: 'Global',
  Group: 'Group',
  Tenant: 'Tenant',
  Client: 'Client',
  Application: 'Application',
  ApiResource: 'ApiResource',
  ApiResourceRole: 'ApiResourceRole',
  User: 'User',
  ApiResourceScope: 'ApiResourceScope',
  IdentityRole: 'IdentityRole',
} as const;
