import { Notification, NotificationProps } from '@mantine/core';
import { variants } from '@uag/react-core';

import { ErrorNotificationContent } from './ErrorNotificationContent';

export type Props = {
    title: string;
    error?: unknown;
} & NotificationProps;

export const ErrorNotification = ({ title, error, ...others }: Props & Omit<NotificationProps, 'message'>) => {
    if (!error) {
        return null;
    }

    return (
        <Notification {...others} variant={variants.notification.inlineNotificationAlert} withCloseButton={false}>
            <ErrorNotificationContent error={error} title={title} />
        </Notification>
    );
};
