/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */

export type TenantLoginProviderType = typeof TenantLoginProviderType[keyof typeof TenantLoginProviderType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TenantLoginProviderType = {
  Local: 'Local',
  AzureAD: 'AzureAD',
} as const;
