import { OidcUserInfo } from '@axa-fr/oidc-client';

import { CreateGroupModel, DetailGroupModel, GetRoleAssignmentResponseItem, PrincipalModel } from 'api/v3/models';
import { getAssignmentTypeForPrincipalType } from 'modules/shared/PrincipalSelector';
import { GroupFormType } from './GroupFormProvider';

export { getGroupAssigneeActions } from './mappings.members';
export { getRoleAssignmentActions } from './mappings.roles';

export const getGroupModel = (groupFormValue: GroupFormType): CreateGroupModel => ({
    name: groupFormValue.basicInformation.name,
    description: groupFormValue.basicInformation.description,
    tenantId: groupFormValue.basicInformation.tenantId,
    owners: groupFormValue.basicInformation.owners.map((owner) => ({
        identifier: owner.id ?? owner.email ?? '',
        type: owner.id === owner.email ? 'UserEmail' : 'UserId'
    }))
});

export function getFormGroup(
    group?: DetailGroupModel,
    groupMembers?: PrincipalModel[],
    roles?: GetRoleAssignmentResponseItem[],
    currentUser?: OidcUserInfo
): GroupFormType {
    return group && groupMembers && roles
        ? getFormGroupFromExisting(group, groupMembers, roles)
        : getNewFormGroup(currentUser);
}

export function getFormGroupFromExisting(
    group: DetailGroupModel,
    groupMembers: PrincipalModel[],
    roles: GetRoleAssignmentResponseItem[]
): GroupFormType {
    return {
        basicInformation: {
            ...group
        },
        members: groupMembers.map((principal) => ({
            identifier: principal.id,
            principalType: principal.principalType,
            email: principal.email,
            displayName: principal.displayName ? principal.displayName : principal.id,
            assignmentType: getAssignmentTypeForPrincipalType(principal.principalType)
        })),
        roles: roles.map((role) => ({
            roleId: role.role.roleId,
            name: role.role.roleIdentifier
        }))
    };
}

export function getNewFormGroup(currentUser?: OidcUserInfo): GroupFormType {
    const homeTenant =
        (currentUser && 'identitytenantid' in currentUser ? (currentUser.identitytenantid as string) : undefined) ?? '';

    return {
        basicInformation: {
            name: '',
            description: '',
            tenantId: homeTenant,
            owners: currentUser
                ? [
                      {
                          id: currentUser.sub,
                          principalType: 'User',
                          displayName: currentUser.name as string,
                          email: currentUser.email ?? ''
                      }
                  ]
                : []
        },
        members: [],
        roles: []
    };
}
