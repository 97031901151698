import { Route, Routes } from 'react-router-dom';

import { RoleAssignments } from './RoleAssignments';

export const RoleAssignmentsBaseRoute = 'roleAssignments';

export const RoleAssignmentsRoutes = () => (
    <Routes>
        <Route element={<RoleAssignments />} path="/" />
    </Routes>
);
