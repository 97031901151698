/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AddRemoveGroupMembersRequestModel,
  AssignRevokeGroupRolesRequestModel,
  BulkResponse,
  CreateGroupModel,
  CreateOrUpdateGroupModel,
  GetAllGroupMembersParams,
  GetAllGroupMembersResponse,
  GetGroupByIdParams,
  GetGroupByIdResponse,
  GetGroupDetailsByIdParams,
  GetGroupDetailsByIdResponse,
  GetGroupsParams,
  GetGroupsResponse,
  ProblemDetails,
  SearchGroupsParams
} from '.././models'
import { customMutator } from '../../customMutator-v3';
import type { ErrorType } from '../../customMutator-v3';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Returns the basic information of a group<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/groups.read
 */
export const getGroupById = (
    id: string,
    params?: GetGroupByIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetGroupByIdResponse>(
      {url: `/groups/${id}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetGroupByIdQueryKey = (id: string,
    params?: GetGroupByIdParams,) => {
    return [`/groups/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetGroupByIdQueryOptions = <TData = Awaited<ReturnType<typeof getGroupById>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetGroupByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGroupByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroupById>>> = ({ signal }) => getGroupById(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGroupById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGroupByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getGroupById>>>
export type GetGroupByIdQueryError = ErrorType<ProblemDetails>


export function useGetGroupById<TData = Awaited<ReturnType<typeof getGroupById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetGroupByIdParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGroupById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetGroupById<TData = Awaited<ReturnType<typeof getGroupById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetGroupByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGroupById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetGroupById<TData = Awaited<ReturnType<typeof getGroupById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetGroupByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetGroupById<TData = Awaited<ReturnType<typeof getGroupById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetGroupByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetGroupByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Updates a group<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/groups.write
 */
export const updateGroup = (
    id: string,
    createOrUpdateGroupModel: CreateOrUpdateGroupModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetGroupByIdResponse>(
      {url: `/groups/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: createOrUpdateGroupModel
    },
      options);
    }
  


export const getUpdateGroupMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateGroup>>, TError,{id: string;data: CreateOrUpdateGroupModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof updateGroup>>, TError,{id: string;data: CreateOrUpdateGroupModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateGroup>>, {id: string;data: CreateOrUpdateGroupModel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateGroup(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateGroupMutationResult = NonNullable<Awaited<ReturnType<typeof updateGroup>>>
    export type UpdateGroupMutationBody = CreateOrUpdateGroupModel
    export type UpdateGroupMutationError = ErrorType<ProblemDetails>

    export const useUpdateGroup = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateGroup>>, TError,{id: string;data: CreateOrUpdateGroupModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateGroup>>,
        TError,
        {id: string;data: CreateOrUpdateGroupModel},
        TContext
      > => {

      const mutationOptions = getUpdateGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Deletes a group<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/groups.write
 */
export const deleteGroup = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/groups/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteGroupMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteGroup>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteGroup>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteGroup>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteGroup(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteGroupMutationResult = NonNullable<Awaited<ReturnType<typeof deleteGroup>>>
    
    export type DeleteGroupMutationError = ErrorType<ProblemDetails>

    export const useDeleteGroup = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteGroup>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteGroup>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns the details of a group<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/groups.read
 */
export const getGroupDetailsById = (
    id: string,
    params?: GetGroupDetailsByIdParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetGroupDetailsByIdResponse>(
      {url: `/groups/${id}/detail`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetGroupDetailsByIdQueryKey = (id: string,
    params?: GetGroupDetailsByIdParams,) => {
    return [`/groups/${id}/detail`, ...(params ? [params]: [])] as const;
    }

    
export const getGetGroupDetailsByIdQueryOptions = <TData = Awaited<ReturnType<typeof getGroupDetailsById>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetGroupDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupDetailsById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGroupDetailsByIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroupDetailsById>>> = ({ signal }) => getGroupDetailsById(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGroupDetailsById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGroupDetailsByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getGroupDetailsById>>>
export type GetGroupDetailsByIdQueryError = ErrorType<ProblemDetails>


export function useGetGroupDetailsById<TData = Awaited<ReturnType<typeof getGroupDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetGroupDetailsByIdParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupDetailsById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGroupDetailsById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetGroupDetailsById<TData = Awaited<ReturnType<typeof getGroupDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetGroupDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupDetailsById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGroupDetailsById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetGroupDetailsById<TData = Awaited<ReturnType<typeof getGroupDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetGroupDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupDetailsById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetGroupDetailsById<TData = Awaited<ReturnType<typeof getGroupDetailsById>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetGroupDetailsByIdParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupDetailsById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetGroupDetailsByIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns a list of groups<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/groups.read
 */
export const getGroups = (
    params?: GetGroupsParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetGroupsResponse>(
      {url: `/groups`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetGroupsQueryKey = (params?: GetGroupsParams,) => {
    return [`/groups`, ...(params ? [params]: [])] as const;
    }

    
export const getGetGroupsQueryOptions = <TData = Awaited<ReturnType<typeof getGroups>>, TError = ErrorType<ProblemDetails>>(params?: GetGroupsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroups>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGroupsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroups>>> = ({ signal }) => getGroups(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGroups>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getGroups>>>
export type GetGroupsQueryError = ErrorType<ProblemDetails>


export function useGetGroups<TData = Awaited<ReturnType<typeof getGroups>>, TError = ErrorType<ProblemDetails>>(
 params: undefined |  GetGroupsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroups>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGroups>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetGroups<TData = Awaited<ReturnType<typeof getGroups>>, TError = ErrorType<ProblemDetails>>(
 params?: GetGroupsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroups>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGroups>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetGroups<TData = Awaited<ReturnType<typeof getGroups>>, TError = ErrorType<ProblemDetails>>(
 params?: GetGroupsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroups>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetGroups<TData = Awaited<ReturnType<typeof getGroups>>, TError = ErrorType<ProblemDetails>>(
 params?: GetGroupsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroups>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetGroupsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Creates a new group<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/groups.write
 */
export const createGroup = (
    createGroupModel: CreateGroupModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetGroupByIdResponse>(
      {url: `/groups`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createGroupModel
    },
      options);
    }
  


export const getCreateGroupMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGroup>>, TError,{data: CreateGroupModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof createGroup>>, TError,{data: CreateGroupModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGroup>>, {data: CreateGroupModel}> = (props) => {
          const {data} = props ?? {};

          return  createGroup(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateGroupMutationResult = NonNullable<Awaited<ReturnType<typeof createGroup>>>
    export type CreateGroupMutationBody = CreateGroupModel
    export type CreateGroupMutationError = ErrorType<ProblemDetails>

    export const useCreateGroup = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGroup>>, TError,{data: CreateGroupModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof createGroup>>,
        TError,
        {data: CreateGroupModel},
        TContext
      > => {

      const mutationOptions = getCreateGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns a list of groups, based on the given search term<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/groups.read
 */
export const searchGroups = (
    params?: SearchGroupsParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetGroupsResponse>(
      {url: `/groups/search`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getSearchGroupsQueryKey = (params?: SearchGroupsParams,) => {
    return [`/groups/search`, ...(params ? [params]: [])] as const;
    }

    
export const getSearchGroupsQueryOptions = <TData = Awaited<ReturnType<typeof searchGroups>>, TError = ErrorType<ProblemDetails>>(params?: SearchGroupsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchGroups>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchGroupsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchGroups>>> = ({ signal }) => searchGroups(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchGroups>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof searchGroups>>>
export type SearchGroupsQueryError = ErrorType<ProblemDetails>


export function useSearchGroups<TData = Awaited<ReturnType<typeof searchGroups>>, TError = ErrorType<ProblemDetails>>(
 params: undefined |  SearchGroupsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchGroups>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchGroups>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSearchGroups<TData = Awaited<ReturnType<typeof searchGroups>>, TError = ErrorType<ProblemDetails>>(
 params?: SearchGroupsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchGroups>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchGroups>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSearchGroups<TData = Awaited<ReturnType<typeof searchGroups>>, TError = ErrorType<ProblemDetails>>(
 params?: SearchGroupsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchGroups>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSearchGroups<TData = Awaited<ReturnType<typeof searchGroups>>, TError = ErrorType<ProblemDetails>>(
 params?: SearchGroupsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchGroups>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSearchGroupsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Returns a list of all members of a group<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/groups.read
 */
export const getAllGroupMembers = (
    id: string,
    params?: GetAllGroupMembersParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetAllGroupMembersResponse>(
      {url: `/groups/${id}/members`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAllGroupMembersQueryKey = (id: string,
    params?: GetAllGroupMembersParams,) => {
    return [`/groups/${id}/members`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAllGroupMembersQueryOptions = <TData = Awaited<ReturnType<typeof getAllGroupMembers>>, TError = ErrorType<ProblemDetails>>(id: string,
    params?: GetAllGroupMembersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllGroupMembers>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllGroupMembersQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllGroupMembers>>> = ({ signal }) => getAllGroupMembers(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllGroupMembers>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllGroupMembersQueryResult = NonNullable<Awaited<ReturnType<typeof getAllGroupMembers>>>
export type GetAllGroupMembersQueryError = ErrorType<ProblemDetails>


export function useGetAllGroupMembers<TData = Awaited<ReturnType<typeof getAllGroupMembers>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params: undefined |  GetAllGroupMembersParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllGroupMembers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllGroupMembers>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAllGroupMembers<TData = Awaited<ReturnType<typeof getAllGroupMembers>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetAllGroupMembersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllGroupMembers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllGroupMembers>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAllGroupMembers<TData = Awaited<ReturnType<typeof getAllGroupMembers>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetAllGroupMembersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllGroupMembers>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetAllGroupMembers<TData = Awaited<ReturnType<typeof getAllGroupMembers>>, TError = ErrorType<ProblemDetails>>(
 id: string,
    params?: GetAllGroupMembersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllGroupMembers>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAllGroupMembersQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Assigns and removes group members in bulk<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/groups.write
 */
export const addRemoveGroupMembers = (
    id: string,
    addRemoveGroupMembersRequestModel: AddRemoveGroupMembersRequestModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<BulkResponse>(
      {url: `/groups/${id}/members/bulk`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addRemoveGroupMembersRequestModel
    },
      options);
    }
  


export const getAddRemoveGroupMembersMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addRemoveGroupMembers>>, TError,{id: string;data: AddRemoveGroupMembersRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof addRemoveGroupMembers>>, TError,{id: string;data: AddRemoveGroupMembersRequestModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addRemoveGroupMembers>>, {id: string;data: AddRemoveGroupMembersRequestModel}> = (props) => {
          const {id,data} = props ?? {};

          return  addRemoveGroupMembers(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddRemoveGroupMembersMutationResult = NonNullable<Awaited<ReturnType<typeof addRemoveGroupMembers>>>
    export type AddRemoveGroupMembersMutationBody = AddRemoveGroupMembersRequestModel
    export type AddRemoveGroupMembersMutationError = ErrorType<ProblemDetails>

    export const useAddRemoveGroupMembers = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addRemoveGroupMembers>>, TError,{id: string;data: AddRemoveGroupMembersRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof addRemoveGroupMembers>>,
        TError,
        {id: string;data: AddRemoveGroupMembersRequestModel},
        TContext
      > => {

      const mutationOptions = getAddRemoveGroupMembersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Assigns and removes roles to a group in bulk<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/groups.write
 */
export const assignRevokeGroupRoles = (
    id: string,
    assignRevokeGroupRolesRequestModel: AssignRevokeGroupRolesRequestModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<BulkResponse>(
      {url: `/groups/${id}/roles/bulk`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assignRevokeGroupRolesRequestModel
    },
      options);
    }
  


export const getAssignRevokeGroupRolesMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignRevokeGroupRoles>>, TError,{id: string;data: AssignRevokeGroupRolesRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof assignRevokeGroupRoles>>, TError,{id: string;data: AssignRevokeGroupRolesRequestModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof assignRevokeGroupRoles>>, {id: string;data: AssignRevokeGroupRolesRequestModel}> = (props) => {
          const {id,data} = props ?? {};

          return  assignRevokeGroupRoles(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AssignRevokeGroupRolesMutationResult = NonNullable<Awaited<ReturnType<typeof assignRevokeGroupRoles>>>
    export type AssignRevokeGroupRolesMutationBody = AssignRevokeGroupRolesRequestModel
    export type AssignRevokeGroupRolesMutationError = ErrorType<ProblemDetails>

    export const useAssignRevokeGroupRoles = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignRevokeGroupRoles>>, TError,{id: string;data: AssignRevokeGroupRolesRequestModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof assignRevokeGroupRoles>>,
        TError,
        {id: string;data: AssignRevokeGroupRolesRequestModel},
        TContext
      > => {

      const mutationOptions = getAssignRevokeGroupRolesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    