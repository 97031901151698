import { useTranslation } from 'react-i18next';

import { GetTenantDetailsByIdResponse } from 'api/v3/models';
import { usePermissions } from 'modules/shared/permissions';
import { Users } from 'modules/userManagement/users/Users';
import { DetailForm, DetailFormVariant } from 'shared/components/detailForm';
import { BasicInformation } from './forms/BasicInformation';
import { Configuration } from './forms/Configuration';
import { LoginProvider } from './forms/LoginProvider';
import { getTenantFormValues } from './mappings';
import { TenantFormProvider, tenantFormSchema, TenantFormType, useTenantForm } from './TenantFormProvider';

type Props = {
    tenant?: GetTenantDetailsByIdResponse;
    localLoginDomains?: string[];
    onSubmit: (tenantFormValues: TenantFormType) => Promise<void>;
    onCancel?: () => void;
    variant?: DetailFormVariant;
    title?: string;
};

export const TenantForm = ({
    tenant,
    localLoginDomains,
    variant = DetailFormVariant.CREATE,
    onSubmit,
    title
}: Props) => {
    const { t } = useTranslation();
    const canCreateOrUpdate = usePermissions(['Create', 'Update']);

    const defaultTenant = getTenantFormValues(tenant?.data, localLoginDomains);

    const form = useTenantForm({
        initialValues: defaultTenant
    });

    const handleConfirm = async () => {
        await onSubmit(form.values);
        form.resetDirty();
    };

    const handleReset = () => {
        form.setValues(defaultTenant);
    };

    return (
        <TenantFormProvider form={form}>
            <DetailForm
                form={form}
                title={title}
                validationSchema={tenantFormSchema}
                variant={variant}
                onConfirm={canCreateOrUpdate ? handleConfirm : undefined}
                onReset={handleReset}
            >
                <BasicInformation
                    label={t('basicInformation')}
                    name="basicInformation"
                    schemaPath="basicInformation"
                    variant={variant}
                />
                <LoginProvider
                    label={t('loginProvider')}
                    name="loginProvider"
                    schemaPath="loginProvider"
                    variant={variant}
                />
                <Configuration
                    label={t('configuration')}
                    name="configuration"
                    schemaPath="configuration"
                    variant={variant}
                />
                <Users
                    disabled={variant === DetailFormVariant.CREATE}
                    label={t('users')}
                    linkTarget="NewWindow"
                    name="users"
                    tenant={tenant}
                />
            </DetailForm>
        </TenantFormProvider>
    );
};
