import { Route, Routes } from 'react-router-dom';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

import { useGetUserById } from 'api/v3/user/user';
import { User } from './User';
import { Users } from './Users';

export const UsersBaseRoute = 'users';

export const UserBreadcrumb = ({ match }: BreadcrumbComponentProps<'userId'>) => {
    const { data: user } = useGetUserById(match.params.userId ?? '');
    const name = [user?.data.firstName, user?.data.lastName].join(' ');
    return <>{name ?? '...'}</>;
};

export const UserRoutes = () => (
    <Routes>
        <Route element={<Users />} path="/" />
        <Route element={<User />} path="/:userId" />
    </Routes>
);
