import { Stack, Title, Text, Group, Button } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { Icon } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { BasicApiResourceRoleModel, BasicApiResourceScopeModel } from 'api/v3/models';
import { RelatedClientsGrid } from 'modules/applications/client/components/RelatedClientsGrid';
import { usePermissions } from 'modules/shared/permissions';
import { RoleSelector } from 'modules/shared/RoleSelector';
import { ScopeSelector } from 'modules/shared/ScopeSelector';
import { DetailFormChildProps } from 'shared/components/detailForm';
import { ExtendedDataTable } from 'shared/components/extendedDataTable';
import { Switch } from 'shared/components/inputs/Switch';
import { Placeholder } from 'shared/components/placeholder';
import { ClientFormValues, useClientFormContext } from '../ClientFormProvider';

type Props = DetailFormChildProps;

export const Permissions = (_: Props) => {
    const { t } = useTranslation();
    const form = useClientFormContext();
    const canUpdateOrCreate = usePermissions(['Update', 'Create']);
    const canAddScope = usePermissions('AddScope');
    const canDeleteScope = usePermissions('RemoveScope');
    const canAssignRole = usePermissions('AssignRole');

    const handleScopeSelected = (form: UseFormReturnType<ClientFormValues>, scope: BasicApiResourceScopeModel, index: number) => {
        form.setFieldValue(`permissionSettings.clientScopes.${index}.isEditable`, false);
        form.setFieldValue(`permissionSettings.clientScopes.${index}.id`, scope.id);
        form.setFieldValue(`permissionSettings.clientScopes.${index}.name`, scope.name);
        form.setFieldValue(`permissionSettings.clientScopes.${index}.displayName`, scope.displayName);
        form.setFieldValue(`permissionSettings.clientScopes.${index}.description`, scope.description);
    };

    const handleRoleSelected = (form: UseFormReturnType<ClientFormValues>, role: BasicApiResourceRoleModel, index: number) => {
        form.setFieldValue(`permissionSettings.roleAssignments.${index}.isEditable`, false);
        form.setFieldValue(`permissionSettings.roleAssignments.${index}.id`, role.id);
        form.setFieldValue(`permissionSettings.roleAssignments.${index}.name`, role.name);
        form.setFieldValue(`permissionSettings.roleAssignments.${index}.displayName`, role.displayName);
    };

    const machine2MachineClient = !!form.values.basicInformation.allowedGrantTypes.clientCredentials;

    return (
        <Stack mx="xs">
            <Title order={4}>{t('identityInformation')}</Title>
            <Text>{t('identityInformationDescription')}</Text>
            <Group align="start" gap="xl" grow>
                <Stack>
                    <Switch
                        disabled={machine2MachineClient || !canUpdateOrCreate}
                        form={form}
                        label={t('email')}
                        labelPosition="left"
                        propertyPath="permissionSettings.identityPermissions.isEmailScopeRequestAllowed"
                    />
                    <Switch
                        disabled={machine2MachineClient || !canUpdateOrCreate}
                        form={form}
                        label={t('profile')}
                        labelPosition="left"
                        propertyPath="permissionSettings.identityPermissions.isProfileScopeRequestAllowed"
                    />
                    <Switch
                        disabled={machine2MachineClient || !canUpdateOrCreate}
                        form={form}
                        label={t('openId')}
                        labelPosition="left"
                        propertyPath="permissionSettings.identityPermissions.isOpenIdScopeRequestAllowed"
                    />
                </Stack>
                <Stack>
                    <Switch
                        disabled={machine2MachineClient || !canUpdateOrCreate}
                        form={form}
                        label={t('roles')}
                        labelPosition="left"
                        propertyPath="permissionSettings.identityPermissions.isRolesScopeRequestAllowed"
                    />
                    <Switch
                        disabled={machine2MachineClient || !canUpdateOrCreate}
                        form={form}
                        label={t('phoneNumber')}
                        labelPosition="left"
                        propertyPath="permissionSettings.identityPermissions.isPhoneScopeRequestAllowed"
                    />
                </Stack>
            </Group>
            <Title order={4}>{t('backgroundUserAccess')}</Title>
            <Text>{t('backgroundUserAccessDescription')}</Text>
            <RelatedClientsGrid />
            <Title order={4}>{t('scopes')}</Title>
            <Text>{t('clientScopesAssignmentDescription')}</Text>
            <ExtendedDataTable
                canCreate={canAddScope}
                canEdit={canAddScope}
                canRemove={canDeleteScope}
                data={form.values.permissionSettings.clientScopes}
                fieldDescriptions={[
                    { header: t('name'), data: 'name', lockUpdate: true },
                    {
                        header: t('displayName'),
                        data: 'displayName',
                        lockUpdate: true
                    },
                    {
                        header: t('description'),
                        data: 'description',
                        lockUpdate: true
                    }
                ]}
                form={form}
                generateNewItem={newAssignment}
                maxHeight={300}
                placeholder={<Placeholder action={canAddScope ? <AddScope /> : undefined} text={t('noScopesAssigned')} />}
                propertyPathBase="permissionSettings.clientScopes"
                renderEdit={(_item, index, onItemSaved) => {
                    return (
                        <ScopeSelector
                            index={index}
                            onScopeSelected={
                                canAddScope
                                    ? (scope: BasicApiResourceScopeModel) => {
                                          handleScopeSelected(form, scope, index);
                                          onItemSaved && onItemSaved();
                                      }
                                    : undefined
                            }
                        />
                    );
                }}
            />

            {form.values.basicInformation.allowedGrantTypes.clientCredentials && (
                <>
                    <Title order={4}>{t('roles')}</Title>
                    <Text>{t('clientRolesAssignmentDescription')}</Text>

                    <ExtendedDataTable
                        canCreate={canAssignRole}
                        canEdit={canAssignRole}
                        canRemove={canAssignRole}
                        data={form.values.permissionSettings.roleAssignments}
                        fieldDescriptions={[
                            { header: t('name'), data: 'name', lockUpdate: true },
                            {
                                header: t('displayName'),
                                data: 'displayName',
                                lockUpdate: true
                            }
                        ]}
                        form={form}
                        generateNewItem={newAssignment}
                        maxHeight={300}
                        placeholder={<Placeholder action={canAssignRole ? <AddRole /> : undefined} text={t('noRolesAssigned')} />}
                        propertyPathBase="permissionSettings.roleAssignments"
                        renderEdit={(_item, index, onItemSaved) => {
                            return (
                                <RoleSelector
                                    form={form}
                                    index={index}
                                    propertyBasePath="permissionSettings.roleAssignments"
                                    onRoleSelected={(role) => {
                                        handleRoleSelected(form, role, index);
                                        onItemSaved && onItemSaved();
                                    }}
                                />
                            );
                        }}
                    />
                </>
            )}
        </Stack>
    );
};

const AddScope = () => {
    const { t } = useTranslation();
    const form = useClientFormContext();

    return (
        <Button
            disabled={form.values.permissionSettings.clientScopes.some((item) => item.isEditable)}
            leftSection={<Icon icon="add" />}
            onClick={() => form.insertListItem('permissionSettings.clientScopes', newAssignment())}
        >
            {t('addItem', { itemType: t('scope') })}
        </Button>
    );
};

const AddRole = () => {
    const { t } = useTranslation();
    const form = useClientFormContext();

    return (
        <Button
            disabled={form.values.permissionSettings.roleAssignments.some((item) => item.isEditable)}
            leftSection={<Icon icon="add" />}
            onClick={() => form.insertListItem('permissionSettings.roleAssignments', newAssignment())}
        >
            {t('addItem', { itemType: t('role') })}
        </Button>
    );
};

const newAssignment = () => ({
    name: '',
    key: randomId(),
    isEditable: true,
    isRemoved: false
});
