import { useHover } from '@mantine/hooks';
import { clsx } from 'clsx';
import { CSSProperties, ReactNode } from 'react';
import { Link, To } from 'react-router-dom';

import { TranslationKeys } from 'i18n';
import { DeleteItemAction } from 'shared/components/DeleteItemAction';
import { CardBase, CardDimensions } from './CardBase';

export type ItemCardPropsBase<TItemType> = {
    item: TItemType;
    height?: number;
    width?: number;
    onItemDelete: () => void | Promise<void>;
} & CardDimensions;

type Props = {
    to?: To;
    itemType: TranslationKeys;
    onDelete?: () => void | Promise<void>;
    name?: string;
    deleteConfirmation?: string;
    style?: CSSProperties | undefined;
    actions?: ReactNode[];
    children: ReactNode;
} & CardDimensions;

export const ItemCard = ({ onDelete: handleDelete, name, itemType, deleteConfirmation, width, children, actions, ...others }: Props) => {
    const { hovered, ref } = useHover();

    return (
        <div ref={ref}>
            <CardBase width={width} {...others} component={Link}>
                <div className="flex flex-col justify-end items-stretch pb-8">
                    {children}
                    <div
                        className={clsx('flex flex-row absolute bottom-3 right-3', { ['block']: hovered, ['hidden']: !hovered })}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                    >
                        <>
                            {!!actions && actions.map((action, index) => <div key={index}>{action}</div>)}
                            {handleDelete && (
                                <DeleteItemAction confirmationText={deleteConfirmation} itemType={itemType} name={name} onDeleteItem={handleDelete} />
                            )}
                        </>
                    </div>
                </div>
            </CardBase>
        </div>
    );
};
