import { useTranslation } from 'react-i18next';

import { CreateItemModal } from 'shared/components';
import { CreateUser } from '../CreateUser';
import { CreateUserButton } from './CreateUserButton';

export const CreateUserModal = () => {
    const { t } = useTranslation();
    return (
        <CreateItemModal
            AddButton={CreateUserButton}
            AddDialogType={CreateUser}
            title={t('createItem', { itemType: t('user') })}
        />
    );
};
