import { randomId } from '@mantine/hooks';

import { BasicUserModel, BasicApiResourceRoleModelApiResponse, DetailUserModel, BasicGroupModelApiResponse } from 'api/v3/models';
import { BasicUserFormType, DetailUserFormType } from './UserFormProvider';

export function getBasicFormUser(
    user: BasicUserModel,
    roles?: BasicApiResourceRoleModelApiResponse[],
    groups?: BasicGroupModelApiResponse[]
): BasicUserFormType {
    return {
        basicInformation: {
            ...user
        },
        roles: roles
            ? roles.map((assignedRole) => ({
                  name: assignedRole.data.name,
                  apiResourceId: assignedRole.data.apiResourceId.toString(),
                  id: randomId(),
                  roleId: assignedRole.data.id,
                  key: randomId(),
                  isEditable: false,
                  isRemoved: false
              }))
            : [],
        groups: groups
            ? groups.map((group) => ({
                  name: group.data.name,
                  description: group.data.description,
                  groupId: group.data.id,
                  groupType: group.data.groupType,
                  id: randomId(),
                  key: randomId(),
                  isEditable: false,
                  isRemoved: false
              }))
            : []
    };
}

export function getDetailFormUser(user: DetailUserModel): DetailUserFormType {
    return {
        basicInformation: {
            ...user,
            salutation: user.salutation ?? 'Undefined'
        },
        roles: user.assignedRoles
            ? user.assignedRoles.map((assignedRole) => ({
                  name: assignedRole.name,
                  apiResourceId: assignedRole.apiResourceId,
                  description: assignedRole.description,
                  displayName: assignedRole.displayName,
                  id: randomId(),
                  roleId: assignedRole.id,
                  key: randomId(),
                  isEditable: false,
                  isRemoved: false
              }))
            : [],
        groups: user.groupMemberships
            ? user.groupMemberships.map((group) => ({
                  name: group.name,
                  description: group.description,
                  groupId: group.id,
                  groupType: group.groupType,
                  id: randomId(),
                  key: randomId(),
                  isEditable: false,
                  isRemoved: false
              }))
            : []
    };
}
