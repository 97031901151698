import { UseFormReturnType } from '@mantine/form';
import { ReactNode } from 'react';

import { ExtendedTextInput } from 'shared/components/inputs';
import { IFieldDescription } from './interfaces';

type Props<TRowType> = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturnType<any, any>;
    fieldDescription: IFieldDescription<TRowType>;
    propertyPath: string;
    onFocus: () => void;
    onBlur: () => void;
    index: number;
    prefix: ReactNode;
    autoFocus?: boolean;
};

export const EditFieldRenderer = <TRowType,>({
    fieldDescription,
    form,
    propertyPath,
    onFocus: handleOnFocus,
    onBlur: handleOnBlur,
    autoFocus
}: Props<TRowType>) => {
    return (
        <ExtendedTextInput
            autoFocus={autoFocus}
            form={form}
            placeholder={fieldDescription.placeholder}
            propertyPath={propertyPath}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
        />
    );
};
