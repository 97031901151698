import { Stack, Text, Title } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { LoadingAnimation } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { LoginProviderModel, TenantLoginProviderType } from 'api/v3/models';
import { getGetLoginProvidersByTenantIdQueryKey, useDeleteLoginProvider, useGetLoginProvidersByTenantId } from 'api/v3/tenant/tenant';
import { listQueryDefault } from 'app/queryDefaults';
import { usePermissions } from 'modules/shared/permissions';
import { useTenantFormContext } from 'modules/userManagement/tenants/TenantFormProvider';
import { DeleteItemAction } from 'shared/components/DeleteItemAction';
import { DetailFormChildProps } from 'shared/components/detailForm';
import { MultiTextInput } from 'shared/components/inputs/MultiTextInput';
import { OverviewGrid } from 'shared/components/OverviewGrid';

type Props = DetailFormChildProps;

export const LoginProvider = (_: Props) => {
    const { t } = useTranslation();
    const form = useTenantFormContext();
    const { tenantId = '' } = useParams();
    const { data: loginProviders, error, isLoading: isLoginProvidersLoading } = useGetLoginProvidersByTenantId(tenantId, {}, listQueryDefault);
    const canUpdateLoginDomains = usePermissions('UpdateLoginDomains');

    if (tenantId && isLoginProvidersLoading) {
        return <LoadingAnimation />;
    }

    return (
        <Stack>
            <OverviewGrid
                additionalRowActions={[DeleteLoginProviderAction]}
                data={
                    loginProviders
                        ? loginProviders.data
                              .filter((loginProvider) => loginProvider.type !== TenantLoginProviderType.Local)
                              .map((loginProvider) => loginProvider)
                        : []
                }
                error={error}
                fieldDescriptions={[
                    {
                        header: t('type'),
                        data: 'type',
                        lockUpdate: true
                    },
                    {
                        header: t('domains'),
                        data: 'loginDomains',
                        lockUpdate: true,
                        render: (item: LoginProviderModel) => (
                            <Stack gap={0}>
                                {item.loginDomains.map((loginDomain) => (
                                    <Text key={loginDomain}>{loginDomain}</Text>
                                ))}
                            </Stack>
                        )
                    }
                ]}
                placeholder={<Title order={4}>{t('noExternLoginProviders')}</Title>}
            />
            <MultiTextInput
                addButtonText={t('addItem', { itemType: t('localLoginDomain') })}
                form={form}
                label={t('localLoginDomains')}
                maxItems={2}
                postfix={<Text className="self-center ml-base">.grpid</Text>}
                propertyPath="localLoginDomains"
                readonly={!canUpdateLoginDomains}
            />
        </Stack>
    );
};

const DeleteLoginProviderAction = (loginProvider: LoginProviderModel) => {
    const { tenantId = '' } = useParams();
    const { mutateAsync: deleteLoginProvider } = useDeleteLoginProvider();
    const queryClient = useQueryClient();
    const canDeleteLoginProvider = usePermissions('DeleteLoginProvider');

    if (!canDeleteLoginProvider) {
        return null;
    }

    const handleDeleteLoginProvider = async () => {
        await deleteLoginProvider({ id: loginProvider.id });
        await queryClient.invalidateQueries({ queryKey: getGetLoginProvidersByTenantIdQueryKey(tenantId) });
    };

    return <DeleteItemAction itemType="loginProvider" onDeleteItem={handleDeleteLoginProvider} />;
};
