import { DefaultErrorBoundary } from '@uag/react-core';
import { Outlet, useParams } from 'react-router';

import { BaseLayout } from 'shared/components/baseLayout/BaseLayout';
import { applicationInsightsReactPlugin } from 'utils/applicationInsights';
import { ApplicationProvider } from './application/ApplicationProvider';

export const ApplicationFrame = () => {
    const { applicationId } = useParams();

    if (!applicationId) {
        throw new Error('applicationId not found in url, the component has to be used in the context of an application');
    }

    return (
        <ApplicationProvider applicationId={applicationId}>
            <BaseLayout>
                <div style={{ position: 'relative', height: '100%' }}>
                    <DefaultErrorBoundary reactPlugin={applicationInsightsReactPlugin}>
                        <Outlet />
                    </DefaultErrorBoundary>
                </div>
            </BaseLayout>
        </ApplicationProvider>
    );
};
