import { Route, Routes } from 'react-router-dom';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

import { useGetApiResourceDetailsById } from 'api/v3/api-resources/api-resources';
import { Api } from './Api';
import { Apis } from './Apis';

export const ApiBaseRoute = 'apis';

export const ApiBreadcrumb = ({ match }: BreadcrumbComponentProps<'apiId'>) => {
    const { data: response } = useGetApiResourceDetailsById(match.params.apiId ?? '');
    return <>{response?.data.displayName ?? '...'}</>;
};

export const ApiRoutes = () => {
    return (
        <Routes>
            <Route element={<Apis />} path="/" />
            <Route element={<Api />} path="/:apiId" />
        </Routes>
    );
};
