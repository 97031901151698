import { useOidcUser } from '@axa-fr/react-oidc';
import { Stack } from '@mantine/core';
import { LoadingAnimation } from '@uag/react-core';
import { t } from 'i18next';

import { useGetIdentityPermissionAssignments } from 'api/v3/role/role';
import { PermissionProvider, PermissionUtils } from 'modules/shared/permissions';
import { RoleAssignmentsGrid } from 'modules/shared/roleAssignments/RoleAssignmentsGrid';

export const RoleAssignments = () => {
    const { oidcUser } = useOidcUser();

    const { data } = useGetIdentityPermissionAssignments(
        {
            principalType: 'UserId',
            principalIdentifier: oidcUser?.sub,
            contextType: 'Global'
        },
        { query: { enabled: !!oidcUser } }
    );

    if (!data) {
        return <LoadingAnimation />;
    }

    const permissions = PermissionUtils.getPermittedItemFromPermissionResponse(data);

    return (
        <PermissionProvider context="IdentityRole" item={permissions}>
            <Stack className="card w-full p-xl">
                <div className="text-4xl">{t('globalRoleAssignments')}</div>
                <RoleAssignmentsGrid contextType="Global" includeGlobalAssignments={true} includeInherited={false} />
            </Stack>
        </PermissionProvider>
    );
};
