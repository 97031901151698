import { Stack, Group, Button } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AssignmentAction, PrincipalModel } from 'api/v3/models';
import { useAssignRevokeApiResourceRoles } from 'api/v3/role/role';
import { getItemLink } from 'modules/shared/functions';
import { SelectedPrincipal, PrincipalSelector } from 'modules/shared/PrincipalSelector';
import { ErrorNotification, checkBulkResponse } from 'shared/components/errorHandling';
import { ExtendedDataTable } from 'shared/components/extendedDataTable';
import { usePrincipalAssignmentForm, PrincipalAssignmentFormProvider } from './FormProvider';
import { getFormPrincipals, generateNewPrincipalAssignment } from './functions';
import { NoAssignedPrincipalsPlaceholder } from './NoAssignedPrincipalsPlaceholder';
import { PrincipalAssignmentContextType, PrincipalAssignmentFormType, PrincipalFormType } from './types';

type Props = {
    principals: PrincipalModel[];
    onClose: () => void;
    contextType: PrincipalAssignmentContextType;
    contextId: string;
};

const handlePrincipalSelected = (
    form: UseFormReturnType<
        PrincipalAssignmentFormType,
        (values: PrincipalAssignmentFormType) => PrincipalAssignmentFormType
    >,
    index: number,
    selectedPrincipal: SelectedPrincipal
) => {
    form.setFieldValue(`principalsAssignments.${index}.isEditable`, false);
    form.setFieldValue(`principalsAssignments.${index}.principalId`, selectedPrincipal.id);
    form.setFieldValue(`principalsAssignments.${index}.displayName`, selectedPrincipal.displayName);
    form.setFieldValue(`principalsAssignments.${index}.principalType`, selectedPrincipal.principalType);
    form.setFieldValue(`principalsAssignments.${index}.assignmentType`, selectedPrincipal.assignmentType);
};

export const ManageAssignedPrincipalsForm = ({ principals, onClose: handleClose, contextType, contextId }: Props) => {
    const form = usePrincipalAssignmentForm({
        initialValues: { principalsAssignments: getFormPrincipals(principals) }
    });

    const { mutateAsync, isPending } = useAssignRevokeApiResourceRoles();
    const { t } = useTranslation();
    const [error, setError] = useState<unknown>();

    const handleSave = async (assignedPrincipals: PrincipalFormType[]) => {
        const assignmentActions = assignedPrincipals
            .filter(
                (principalAssignment) =>
                    principalAssignment.isRemoved || (!principalAssignment.id && !principalAssignment.isEditable)
            )
            .map((principal) => ({
                assignee: {
                    identifier: principal.principalId,
                    type: principal.assignmentType
                },
                assignmentAction: principal.isRemoved ? AssignmentAction.Revoke : AssignmentAction.Assign
            }));

        switch (contextType) {
            case 'Role': {
                try {
                    const result = await mutateAsync({
                        roleId: contextId,
                        data: { roleAssigneeActions: assignmentActions }
                    });
                    checkBulkResponse(result);
                    handleClose();
                } catch (error: unknown) {
                    setError(error);
                }
            }
        }
    };

    return (
        <PrincipalAssignmentFormProvider form={form}>
            <Stack>
                <ExtendedDataTable
                    data={form.values.principalsAssignments}
                    fieldDescriptions={[
                        {
                            header: t('displayName'),
                            data: 'displayName',
                            lockUpdate: true,
                            link: (item) => getItemLink({ id: item.principalId, type: item.principalType })
                        },
                        {
                            header: t('type'),
                            data: 'principalType',
                            lockUpdate: true
                        }
                    ]}
                    form={form}
                    generateNewItem={generateNewPrincipalAssignment}
                    isReadOnly={false}
                    placeholder={<NoAssignedPrincipalsPlaceholder />}
                    propertyPathBase="principalsAssignments"
                    renderEdit={(_item, index, onItemSaved) => {
                        return (
                            <PrincipalSelector
                                form={form}
                                propertyPath={`principalsAssignments.${index}.principalId`}
                                onPrincipalSelected={(principal) => {
                                    handlePrincipalSelected(form, index, principal);
                                    onItemSaved && onItemSaved();
                                }}
                            />
                        );
                    }}
                />
                <ErrorNotification error={error} title={t('manageMember')} />
                <Group justify="flex-end" mt="xl">
                    <Button variant="default" onClick={handleClose}>
                        {t('cancel')}
                    </Button>
                    <Button
                        disabled={!form?.isDirty()}
                        loading={isPending}
                        onClick={() => handleSave(form.values.principalsAssignments)}
                    >
                        {t('save')}
                    </Button>
                </Group>
            </Stack>
        </PrincipalAssignmentFormProvider>
    );
};
