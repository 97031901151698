import { Code, HoverCard, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { LogEventModel } from 'api/v3/models';

type Props = {
    event: LogEventModel;
};

export const EventDataColumn = ({ event }: Props) => {
    const { t } = useTranslation();
    const jsonData = JSON.stringify(event.eventData, null, 2);

    return (
        <HoverCard arrowSize={10} position="bottom" shadow="lg" withArrow withinPortal>
            <HoverCard.Target>
                <Text td="underline">{t('showItem', { itemType: t('eventData') })}</Text>
            </HoverCard.Target>
            <HoverCard.Dropdown>
                <Code bg="#FFFFFF" className=" p-base font-code" block>
                    {jsonData}
                </Code>
            </HoverCard.Dropdown>
        </HoverCard>
    );
};
