import { Group, Stack, Switch } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { getPagingParams, ItemsPerPageType, Pagination } from '@uag/react-core';
import { clsx } from 'clsx';
import { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { GetTenantByIdResponse, GetTenantDetailsByIdResponse, BasicUserModelApiResponse, UserState } from 'api/v3/models';
import { useGetUsers, useSearchUsers } from 'api/v3/user/user';
import { listQueryDefault } from 'app/queryDefaults';
import { getItemLink } from 'modules/shared/functions';
import { PermissionProvider, usePermissions } from 'modules/shared/permissions';
import { Avatar } from 'shared/components/avatar';
import { OverviewGrid } from 'shared/components/OverviewGrid';
import { getTotalPages } from 'utils/paginations';
import { UserManagementBaseRoute } from '../Routes';
import { CreateUserModal } from './components/CreateUserModal';
import { DeactivateAction } from './components/DeactivateAction';
import { DeleteUserAction } from './components/DeleteUserAction';
import { ForceResetPasswordAction } from './components/ForceResetPasswordAction';
import { InviteUserModal } from './components/InviteUserModal';
import { ResendInviteAction } from './components/ResendInvitationAction';
import { ResetPasswordAction } from './components/ResetPasswordAction';
import { UndeleteUserAction } from './components/UndeleteUserAction';
import { UserStateIndicator } from './components/UserStateIndicator';
import { UsersBaseRoute } from './Routes';

type Props = {
    label?: string;
    name?: string;
    disabled?: boolean;
    tenant?: GetTenantDetailsByIdResponse | GetTenantByIdResponse;
    linkTarget?: 'NewWindow' | 'SameWindow';
};

export const Users = ({ tenant, linkTarget = 'SameWindow' }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [showSoftDeletedUsers, setShowSoftDeletedUsers] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState<ItemsPerPageType>(25);
    const [page, setPage] = useState(1);

    const [searchValue, setSearchValue] = useState<string>();
    const [debounceSearchValue] = useDebouncedValue(searchValue, 300);

    const {
        data: searchUsers,
        isLoading: areSearchUsersLoading,
        error: searchUsersError
    } = useSearchUsers(
        {
            tenantId: tenant?.data.id,
            searchTerm: debounceSearchValue,
            includePermissions: true,
            includeProfilePictureUri: true,
            includeSoftDeleted: showSoftDeletedUsers,
            ...getPagingParams(page, itemsPerPage)
        },
        { ...listQueryDefault, query: { enabled: !!debounceSearchValue } }
    );

    const {
        data: users,
        isLoading: areUsersLoading,
        error: usersError
    } = useGetUsers(
        {
            tenantId: tenant?.data.id,
            includeProfilePictureUri: true,
            includePermissions: true,
            ...getPagingParams(page, itemsPerPage)
        },
        {
            ...listQueryDefault,
            query: { enabled: !debounceSearchValue }
        }
    );

    const totalPages = getTotalPages(itemsPerPage, debounceSearchValue, searchUsers, users);

    const canInvite = usePermissions('Invite', 'User', tenant, searchUsers, users);
    const canInviteWithoutEmail = usePermissions('InviteWithoutEmail', 'User', tenant, searchUsers, users);

    const handleOnSearch = (text: string) => {
        setPage(1);
        setSearchValue(text);
    };

    const handleRowClicked = (_event: MouseEvent, item: BasicUserModelApiResponse) => {
        if (item.data.id && item.data.state !== UserState.Deleted) {
            if (linkTarget === 'SameWindow') {
                navigate(`/${UserManagementBaseRoute}/${UsersBaseRoute}/${item.data.id}`);
            } else {
                window.open(getItemLink({ id: item.data.id, type: 'User' }));
            }
        }
    };

    return (
        <PermissionProvider context="User" items={[tenant, searchUsers, users]}>
            <Stack className={clsx('w-full p-xl', { ['card']: !tenant })}>
                {!tenant && <div className="text-4xl">{t('users')}</div>}
                <Group mb="sm" />
                <OverviewGrid
                    additionalRowActions={[
                        ResendInviteAction,
                        DeactivateAction,
                        ResetPasswordAction,
                        ForceResetPasswordAction,
                        DeleteUserAction,
                        UndeleteUserAction
                    ]}
                    data={(debounceSearchValue ? searchUsers?.data : users?.data) ?? []}
                    error={searchUsersError ?? usersError}
                    fieldDescriptions={[
                        {
                            header: '',
                            data: '',
                            width: 50,
                            lockUpdate: true,
                            render: (item) => <Avatar name={item.data.displayName ?? undefined} src={item.data.profilePictureUri ?? undefined} />
                        },
                        {
                            header: t('username'),
                            data: 'data.userName',
                            lockUpdate: true
                        },
                        {
                            header: t('state'),
                            data: 'data.state',
                            lockUpdate: true,
                            render: (item) => <UserStateIndicator userState={item.data.state} />
                        },
                        {
                            header: t('tenant'),
                            data: 'data.tenant.name',
                            lockUpdate: true,
                            link: (item) => getItemLink({ id: item.data.tenant.id, type: 'Tenant' }, 'users')
                        }
                    ]}
                    headerActions={[
                        <Switch
                            key={2}
                            checked={showSoftDeletedUsers}
                            label={t('showItem', { itemType: t('softDeletedUsers') })}
                            onChange={(event) => {
                                setShowSoftDeletedUsers(event.currentTarget.checked);
                            }}
                        />,
                        canInvite && <InviteUserModal key={0} />,
                        canInviteWithoutEmail && <CreateUserModal key={1} />
                    ]}
                    isLoading={areSearchUsersLoading || areUsersLoading}
                    onRowClicked={handleRowClicked}
                    onSearch={(text) => handleOnSearch(text)}
                />
                <Pagination
                    disabled={areUsersLoading || areSearchUsersLoading}
                    itemsPerPage={itemsPerPage}
                    page={page}
                    totalPages={totalPages}
                    withSkip
                    onItemsPerPageChange={setItemsPerPage}
                    onPageChange={setPage}
                />
            </Stack>
        </PermissionProvider>
    );
};
