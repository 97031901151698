import { Center, Modal } from '@mantine/core';
import { Icon, LoadingAnimation } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetClientDetailById } from 'api/v3/clients/clients';
import { DetailClientModel } from 'api/v3/models';
import { CreateClient } from 'modules/applications/client/CreateClient';
import { usePermissions } from 'modules/shared/permissions';
import { ItemCardAction } from 'shared/components/ItemCardAction';

type Props = {
    clientId: string;
};

export const DuplicateClientAction = ({ clientId }: Props) => {
    const { t } = useTranslation();
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
    const canCreate = usePermissions('Create');

    if (!canCreate) {
        return null;
    }

    return (
        <>
            <ItemCardAction
                icon={<Icon icon="content_copy" />}
                title={t('duplicateItem', { itemType: t('client') })}
                onClick={() => {
                    setIsDuplicateModalOpen(true);
                }}
            />
            {isDuplicateModalOpen && <DuplicateClientModal clientId={clientId} onClose={() => setIsDuplicateModalOpen(false)} />}
        </>
    );
};

type DuplicateItemModelProps = {
    clientId: string;
    onClose: () => void;
};

const DuplicateClientModal = ({ clientId, onClose: handleClose }: DuplicateItemModelProps) => {
    const { t } = useTranslation();
    const { data: clientDetails } = useGetClientDetailById(clientId, undefined);
    const apiUrl = import.meta.env.VITE_APP_FUNCTIONS_URL;

    const duplicatedClientDetails: DetailClientModel | undefined = clientDetails && {
        ...clientDetails.data,
        basicInformation: {
            ...clientDetails.data.basicInformation,
            clientPictureUri: !clientDetails.data.basicInformation.clientPictureUri?.startsWith(apiUrl)
                ? clientDetails.data.basicInformation.clientPictureUri
                : undefined,
            id: '',
            name: `${clientDetails.data.basicInformation.name} - copy`
        }
    };

    return (
        <Modal
            closeOnClickOutside={false}
            opened={true}
            size={1000}
            title={t('duplicateItem', { itemType: t('client') })}
            centered
            onClick={(event) => {
                event.stopPropagation();
            }}
            onClose={handleClose}
        >
            {!duplicatedClientDetails ? (
                <Center>
                    <LoadingAnimation />
                </Center>
            ) : (
                <CreateClient initialValue={duplicatedClientDetails} onCancel={handleClose} />
            )}
        </Modal>
    );
};
