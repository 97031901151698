import { ReactNode, createContext } from 'react';

type SelectionContextProps = {
    selectionEnabled: boolean;
    selectedItemKeys: string[];
};

type SelectionProviderProps = {
    children?: ReactNode;
} & SelectionContextProps;

export const SelectionContext = createContext<SelectionContextProps>({ selectionEnabled: false, selectedItemKeys: [] });

export const SelectionContextProvider = (props: SelectionProviderProps) => {
    return <SelectionContext.Provider value={props}>{props.children}</SelectionContext.Provider>;
};
