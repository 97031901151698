import { createFormContext } from '@mantine/form';
import { z } from 'zod';

import { AssignmentPrincipalType, PrincipalType, RoleAssignmentContextType } from 'api/v3/models';
import { i18n } from 'i18n';
import { IRowData } from 'shared/components/extendedDataTable/interfaces';

export const roleAssignmentsFormSchema = z.object({
    roleAssignments: z
        .object({
            isNew: z.boolean(),
            principal: z.object({
                id: z.string().min(1, { message: i18n.t('fieldRequired') }),
                principalType: z.nativeEnum(PrincipalType),
                displayName: z.string().nullish(),
                email: z.string().nullish(),
                assignmentType: z.nativeEnum(AssignmentPrincipalType)
            }),
            role: z.object({
                roleId: z.string().min(1),
                roleIdentifier: z.string().min(1, { message: i18n.t('fieldRequired') }),
                roleDisplayName: z.string()
            }),
            context: z.object({
                contextAssignmentType: z.nativeEnum(RoleAssignmentContextType),
                contextId: z.string().nullish()
            })
        })
        .array()
});

export type RoleAssignmentsFormRowType = z.infer<typeof roleAssignmentsFormSchema>['roleAssignments'][0] & IRowData;
export type RoleAssignmentsFormType = {
    roleAssignments: RoleAssignmentsFormRowType[];
};
export const [RoleAssignmentsFormProvider, useRoleAssignmentsFormContext, useRoleAssignmentsForm] =
    createFormContext<RoleAssignmentsFormType>();
