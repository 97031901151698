/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */

export type AssignmentAction = typeof AssignmentAction[keyof typeof AssignmentAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignmentAction = {
  Assign: 'Assign',
  Revoke: 'Revoke',
} as const;
