import { QueryClient, UseQueryOptions } from '@tanstack/react-query';

export const defaultQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false
        },
        mutations: {}
    }
});

export const DefaultPageSize = 15;

export const listQueryDefault = {
    query: {
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchOnMount: true
    }
} satisfies { query: Omit<UseQueryOptions, 'queryKey'> };
