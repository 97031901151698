import { Stack } from '@mantine/core';
import { t } from 'i18next';

import { GlobalActivityLogGrid } from 'modules/shared/activityLog/GlobalActivityLogGrid';

export const ActivityLog = () => {
    return (
        <Stack className="card w-full p-xl">
            <div className="text-4xl">{t('activityLog')}</div>
            <GlobalActivityLogGrid />
        </Stack>
    );
};
