import { ActionIcon, Flex, Menu, Stack, Text, TextInput, Tooltip } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { Icon, toastNotifications } from '@uag/react-core';
import { MouseEvent, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { TenantLoginProviderType, UserState, BasicUserModelApiResponse } from 'api/v3/models';
import { useResetPassword, getSearchUsersQueryKey } from 'api/v3/user/user';
import { usePermissions } from 'modules/shared/permissions';
import { CopyToClipboard } from 'shared/components';
import { RowActionVariant } from 'shared/components/extendedDataTable';

export const ResetPasswordAction = (user: BasicUserModelApiResponse, index: number, rowActionVariant: RowActionVariant) => {
    const { t } = useTranslation();
    const { mutateAsync: resetPassword } = useResetPassword();
    const queryClient = useQueryClient();
    const id = useId();
    const canResetPassword = usePermissions('ResetPassword', 'User', user);

    if (user.data.state !== UserState.Activated || user.data.loginProviderType !== TenantLoginProviderType.Local) {
        return null;
    }

    const handleResetPassword = async (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        toastNotifications.loading({
            id,
            message: t('willBe', {
                type: t('user'),
                name: `${user.data.displayName} ${t('password').toLowerCase()}`,
                action: t('reset')
            })
        });
        try {
            const result = await resetPassword({ id: user.data.id });
            await queryClient.invalidateQueries({ queryKey: getSearchUsersQueryKey() });

            const responseUrl = 'data' in result && typeof result.data === 'string' && result.data ? result.data : undefined;

            toastNotifications.sucess({
                message: (
                    <Stack>
                        <Text>
                            {t('wasSuccessful', {
                                type: t('user'),
                                name: user.data.displayName,
                                action: t('resetPassword')
                            })}
                        </Text>
                        {responseUrl && (
                            <Flex gap={6} ta="center">
                                <TextInput value={responseUrl} w="100%" readOnly />
                                <CopyToClipboard textToCopy={responseUrl} />
                            </Flex>
                        )}
                    </Stack>
                ),
                autoClose: !responseUrl
            });
        } catch {
            toastNotifications.error({
                message: t('wasNotSuccessful', {
                    type: t('user'),
                    name: user.data.displayName,
                    action: t('resetPassword')
                })
            });
        } finally {
            toastNotifications.hide(id);
        }
    };

    return (
        canResetPassword &&
        (rowActionVariant === RowActionVariant.DEFAULT ? (
            <Tooltip label={t('resetPassword')}>
                <ActionIcon onClick={handleResetPassword}>
                    <Icon icon="password" size="xl" />
                </ActionIcon>
            </Tooltip>
        ) : (
            <Menu.Item leftSection={<Icon icon="password" size="xl" />} onClick={handleResetPassword}>
                {t('resetPassword')}
            </Menu.Item>
        ))
    );
};
