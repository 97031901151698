import { Text } from '@mantine/core';
import { forwardRef } from 'react';

import { IMantineFormControl, IWarningProps, IIconDescription, Label } from 'shared/components/inputs';
import { MultiPersonSelectInternal, Props as MultiPersonSelectInternalProps } from './MultiPersonSelectInternal';

type Props = Omit<MultiPersonSelectInternalProps, 'form'> & IMantineFormControl & IWarningProps & IIconDescription;

export const MultiPersonSelect = forwardRef<HTMLInputElement, Props>(
    ({ warnings, label, optional, iconDescription, disabled, form, propertyPath, ...others }: Props, ref) => {
        const warningText = !disabled && warnings && warnings[propertyPath];
        const displayWarning = warningText && !form.errors[propertyPath];

        return (
            <div className="flex flex-col gap-0">
                <MultiPersonSelectInternal
                    color="orange"
                    disabled={disabled}
                    styles={{
                        label: {
                            color: displayWarning ? 'orange' : ''
                        },
                        input: {
                            borderColor: displayWarning ? 'orange' : ''
                        },
                        pill: {
                            color: displayWarning ? 'orange' : ''
                        }
                    }}
                    {...form.getInputProps(propertyPath)}
                    ref={ref}
                    {...others}
                    label={label && <Label iconDescription={iconDescription} label={label} optional={optional} />}
                />
                {displayWarning && <Text style={{ color: 'orange' }}>{warningText}</Text>}
            </div>
        );
    }
);
MultiPersonSelect.displayName = 'MultiPersonSelect';
