/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */

export type RoleAssignmentRoleModelRoleType = typeof RoleAssignmentRoleModelRoleType[keyof typeof RoleAssignmentRoleModelRoleType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleAssignmentRoleModelRoleType = {
  GroupIdentityRole: 'GroupIdentityRole',
  ApiResourceRole: 'ApiResourceRole',
} as const;
