import { ComboboxItemGroup } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import { Select, SelectProps } from '@uag/react-core';
import _ from 'lodash';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AssignmentPrincipalType, PrincipalModel, PrincipalType } from 'api/v3/models';
import { useSearchPrincipals } from 'api/v3/principal/principal';
import { emailRegex } from 'shared/regex';
import { mergeData } from './multiPersonSelect/functions';

export type SelectedPrincipal = {
    principalType: PrincipalType;
    displayName?: string | null;
    email?: string | null;
    assignmentType: AssignmentPrincipalType;
    id: string;
};

type ListPrincipal = {
    disabled?: boolean;
} & PrincipalModel;

type Props = {
    onPrincipalSelected?: (principal: SelectedPrincipal) => void;
    additionalPrincipals?: PrincipalModel[];
    principalTypeFilter?: PrincipalType[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturnType<any, any>;
    propertyPath: string;
} & Omit<SelectProps, 'data' | 'value' | 'onChange' | 'form'>;

export const getAssignmentTypeForPrincipalType = (principalType: PrincipalType): AssignmentPrincipalType => {
    switch (principalType) {
        case 'Client':
            return AssignmentPrincipalType.ClientId;
        case 'Group':
            return AssignmentPrincipalType.GroupId;
        case 'User':
            return AssignmentPrincipalType.UserId;
        case 'Tenant':
            return AssignmentPrincipalType.TenantId;
        default:
            throw new Error(`Invalid principal type ${principalType}`);
    }
};

export function getSelectItemsFromUserList(principals?: ListPrincipal[]): ComboboxItemGroup[] {
    if (!principals || principals.length === 0) {
        return [];
    }

    return _.chain(principals)
        .orderBy((principal) => principal.principalType)
        .groupBy((principal) => principal.principalType)
        .map((value, key) => ({
            group: key,
            items: value.map((principal) => ({
                value: principal.id,
                label: `${principal.displayName} ${principal.email ? `(${principal.email})` : ''}`,
                disabled: principal.disabled
            }))
        }))
        .value();
}

const getPrincipalTypeFilter = (principalTypeFilter: PrincipalType[], searchTerm: string) => {
    const filterText = principalTypeFilter
        .map((principalType) => principalType.toLowerCase())
        .join(':, ')
        .replace(/, ([^,]*)$/, ' or $1:');
    for (const principalType of principalTypeFilter) {
        if (searchTerm.startsWith(`${principalType.toLowerCase()}:`)) {
            return {
                principalTypeFilter: [PrincipalType[principalType]],
                searchTerm: searchTerm.replace(`${principalType.toLowerCase()}:`, ''),
                filterText
            };
        }
    }

    return { principalTypeFilter, searchTerm, filterText };
};

export const PrincipalSelector = ({
    onPrincipalSelected,
    additionalPrincipals,
    principalTypeFilter: principalTypeFilterParam = ['Client', 'Group', 'User'],
    form,
    propertyPath,
    ...others
}: Props) => {
    const [searchValue, setSearchValue] = useState('');
    const [debounceSearchValue] = useDebouncedValue(searchValue, 200);
    const [selectedPrincipal, setSelectedPrincipal] = useState<PrincipalModel>();
    const { principalTypeFilter, searchTerm, filterText } = getPrincipalTypeFilter(principalTypeFilterParam, debounceSearchValue);

    const { data: users, isLoading } = useSearchPrincipals(
        {
            searchTerm,
            principalType: principalTypeFilter && principalTypeFilter.length === 1 ? principalTypeFilter[0] : undefined
        },
        { query: { enabled: !!searchTerm } }
    );

    const principals: PrincipalModel[] = [...(users?.data.map((user) => user.data) ?? [])];

    const { t } = useTranslation();

    const handleOnChange = (value: string | null) => {
        const selectedPrincipal = principals.find((item) => item.id === value);
        setSelectedPrincipal(selectedPrincipal);

        if (selectedPrincipal && onPrincipalSelected) {
            onPrincipalSelected({
                ...selectedPrincipal,
                assignmentType: getAssignmentTypeForPrincipalType(selectedPrincipal.principalType)
            });
        }
    };

    const allPrincipals = searchTerm
        ? mergeData(
              principals.map((principal) => ({ ...principal })),
              additionalPrincipals
                  ?.map((principal) => ({ ...principal, disabled: true }))
                  .filter(
                      (additionalPrincipal) =>
                          !searchTerm ||
                          Object.keys(additionalPrincipal).some((key) =>
                              additionalPrincipal[key as keyof PrincipalModel]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
                          )
                  )
          )
        : [];

    const filteredPrincipals =
        allPrincipals.filter(
            (item) => !principalTypeFilter || principalTypeFilter.some((principalTypeFilter) => principalTypeFilter === item.principalType)
        ) ?? [];

    const { error } = form.getInputProps(`${propertyPath}`);

    const mergedPrincipals = mergeData(filteredPrincipals, selectedPrincipal ? [selectedPrincipal] : []);

    return (
        <Select
            {...others}
            data={getSelectItemsFromUserList(mergedPrincipals)}
            error={error}
            getCreateLabel={(query) => t('inviteUserWithMail', { email: query })}
            max={20}
            nothingFoundMessage={
                searchTerm ? (
                    isLoading ? (
                        'Fetching users'
                    ) : (
                        'No user found matching the given search criteria'
                    )
                ) : (
                    <Trans i18nKey="filterHint" values={{ filterText }} />
                )
            }
            placeholder={t('selectPrincipal')}
            shouldCreate={(query, _data) => emailRegex.test(query) && (!principals || !principals.some((principal) => principal.email === query))}
            searchable
            onChange={handleOnChange}
            onCreate={(query) => {
                const selectedPrincipal = {
                    id: query,
                    displayName: query,
                    assignmentType: AssignmentPrincipalType.UserEmail,
                    principalType: PrincipalType.User
                };
                onPrincipalSelected && onPrincipalSelected(selectedPrincipal);
                return query;
            }}
            onSearchChange={(value) => {
                setSearchValue(value);
            }}
        />
    );
};
