import { Button } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { Icon } from '@uag/react-core';
import { ReactNode, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { IFieldDescription, IRowData } from 'shared/components/extendedDataTable/interfaces';
import { CreateItemModal } from './CreateItemModal';
import { RowActionVariant } from './extendedDataTable';
import { DataTable } from './extendedDataTable/DataTable';
import { AddDialogProps } from './FlexList';

type Props<TRowType> = {
    data: TRowType[];
    placeholder?: ReactNode;
    fieldDescriptions: IFieldDescription<TRowType>[];
    AddDialogType?: (props: AddDialogProps) => ReactNode;
    addDialogTitle?: string;
    additionalRowActions?: ((value: TRowType, index: number, variant: RowActionVariant) => ReactNode)[];
    onRowClicked?: (event: MouseEvent, item: TRowType) => void;
    renderEdit?: (value: TRowType, index: number) => ReactNode;
    headerActions?: ReactNode[];
    maxHeight?: number;
    onSearch?: (searchText: string) => void;
    isLoading?: boolean;
    error?: unknown;
    withSearch?: boolean;
    withSort?: boolean;
    getRowClass?: (item: TRowType) => string | undefined;
};

export const AddItem = ({ onClick }: { onClick?: () => void }) => {
    const { t } = useTranslation();

    return (
        <Button leftSection={<Icon icon="add" />} onClick={() => !!onClick && onClick()}>
            {t('add')}
        </Button>
    );
};

function getGridData<TRowType extends object>(data: TRowType[]): (TRowType & IRowData)[] {
    return data.map((dataItem) => ({
        key: randomId(),
        isEditable: false,
        isRemoved: false,
        id: 'id' in dataItem ? (dataItem.id as string) : randomId(),
        ...dataItem
    }));
}

export const OverviewGrid = <TRowType extends object>({
    data,
    placeholder,
    fieldDescriptions,
    AddDialogType,
    addDialogTitle,
    renderEdit,
    additionalRowActions,
    headerActions,
    onRowClicked: handleRowClicked,
    maxHeight,
    onSearch: handleSearch,
    isLoading,
    error,
    withSearch = true,
    withSort,
    getRowClass
}: Props<TRowType>) => {
    const gridData = getGridData<TRowType>(data);

    return (
        <DataTable
            data={gridData}
            error={error}
            fieldDescriptions={fieldDescriptions.map((fieldDescription) => ({ ...fieldDescription, lockUpdate: true }))}
            getRowClass={getRowClass}
            headerActions={[
                ...(headerActions ?? []),
                AddDialogType && <CreateItemModal key="AddItem" AddButton={AddItem} AddDialogType={AddDialogType} title={addDialogTitle} />
            ]}
            isLoading={isLoading}
            maxHeight={maxHeight}
            placeholder={placeholder}
            renderEdit={renderEdit}
            rowActions={additionalRowActions}
            withSearch={withSearch}
            withSort={withSort}
            withHeaders
            onRowClicked={handleRowClicked}
            onSearch={handleSearch}
        />
    );
};
