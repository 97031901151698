import {
    AssignmentAction,
    GetRoleAssignmentResponseItem,
    GroupRoleAssignmentAction,
    RoleAssignmentRoleModelRoleType
} from 'api/v3/models';
import { GroupFormRoleAssignmentsType } from './GroupFormProvider';

export function getRoleAssignmentActions(
    roles: GroupFormRoleAssignmentsType[],
    currentRoles: GetRoleAssignmentResponseItem[] = []
): GroupRoleAssignmentAction[] {
    return [
        ...getRoleAssignmentAssignActions(roles, currentRoles),
        ...getRoleAssignmentRevokeActions(roles, currentRoles)
    ];
}

function getRoleAssignmentAssignActions(
    roles: GroupFormRoleAssignmentsType[],
    currentRoles: GetRoleAssignmentResponseItem[] = []
) {
    return roles
        .filter((role) => !currentRoles?.some((currentRole) => currentRole.role.roleId === role.roleId))
        .map((role) => ({
            role: {
                id: role.roleId,
                roleType: RoleAssignmentRoleModelRoleType.ApiResourceRole
            },
            assignmentAction: AssignmentAction.Assign
        }));
}

function getRoleAssignmentRevokeActions(
    roles: GroupFormRoleAssignmentsType[],
    currentRoles: GetRoleAssignmentResponseItem[] = []
) {
    return currentRoles
        .filter((currentRole) => !roles?.some((role) => currentRole.role.roleId === role.roleId))
        .map((currentRole) => ({
            role: {
                id: currentRole.role.roleId,
                roleType: RoleAssignmentRoleModelRoleType.ApiResourceRole
            },
            assignmentAction: AssignmentAction.Revoke
        }));
}
