/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */

export type PrincipalType = typeof PrincipalType[keyof typeof PrincipalType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrincipalType = {
  User: 'User',
  Group: 'Group',
  Client: 'Client',
  Tenant: 'Tenant',
} as const;
