import { Button, Stack } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { getPagingParams, Icon, ItemsPerPageType, Pagination } from '@uag/react-core';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getGetGroupsQueryKey, useDeleteGroup, useGetGroups, useSearchGroups } from 'api/v3/groups/groups';
import { BasicGroupModelApiResponse } from 'api/v3/models';
import { listQueryDefault } from 'app/queryDefaults';
import { PermissionProvider, usePermissions } from 'modules/shared/permissions';
import { ShowRoleAssignmentsAction } from 'modules/shared/roleAssignments/ShowRoleAssignmentsAction';
import { CreateItemModal } from 'shared/components';
import { DeleteItemAction } from 'shared/components/DeleteItemAction';
import { RowActionVariant } from 'shared/components/extendedDataTable';
import { OverviewGrid } from 'shared/components/OverviewGrid';
import { Placeholder } from 'shared/components/placeholder';
import { getTotalPages } from 'utils/paginations';
import groupsEmpty from './assets/groups_empty.svg';
import { DuplicateGroupAction } from './components/DuplicateGroupAction';
import { CreateGroup } from './CreateGroup';

const NoGroupsPlaceholder = () => {
    const { t } = useTranslation();
    const canCreate = usePermissions('Create');

    return (
        <Placeholder
            action={
                canCreate ? (
                    <CreateItemModal AddButton={AddGroup} AddDialogType={CreateGroup} title={t('createItem', { itemType: t('group') })} />
                ) : undefined
            }
            picture={groupsEmpty}
            text={t('noGroups')}
        />
    );
};

const AddGroup = ({ onClick }: { onClick: () => void }) => {
    const canCreate = usePermissions('Create');

    if (!canCreate) {
        return null;
    }

    return (
        <Button leftSection={<Icon icon="add" />} onClick={() => onClick()}>
            Add Group
        </Button>
    );
};

const DeleteGroupAction = (group: BasicGroupModelApiResponse, _: number, variant: RowActionVariant) => {
    const { mutateAsync: deleteGroupMutationAsync } = useDeleteGroup();
    const queryClient = useQueryClient();
    const canDelete = usePermissions('Delete', 'Group', group);

    if (!canDelete) {
        return null;
    }

    const handleDeleteGroup = async () => {
        await deleteGroupMutationAsync({ id: group.data.id });
        await queryClient.invalidateQueries({ queryKey: getGetGroupsQueryKey() });
    };

    return <DeleteItemAction itemType="group" name={group.data.name} rowActionVariant={variant} onDeleteItem={handleDeleteGroup} />;
};

const ShowRoleAssignments = (group: BasicGroupModelApiResponse) => {
    return (
        <PermissionProvider context="Group" item={group}>
            <ShowRoleAssignmentsAction contextId={group.data.id} contextType="Group" itemName={group.data.name} />
        </PermissionProvider>
    );
};

export const Groups = () => {
    const navigate = useNavigate();
    const [itemsPerPage, setItemsPerPage] = useState<ItemsPerPageType>(25);
    const [page, setPage] = useState(1);

    const [searchValue, setSearchValue] = useState<string>();
    const [debounceSearchValue] = useDebouncedValue(searchValue, 300);

    const {
        data: groups,
        isLoading: areGroupsLoading,
        error: groupsError
    } = useGetGroups(
        {
            includePermissions: true,
            ...getPagingParams(page, itemsPerPage)
        },
        {
            ...listQueryDefault,
            query: { enabled: !debounceSearchValue }
        }
    );
    const {
        data: searchGroups,
        isLoading: areSearchGroupsLoading,
        error: searchGroupsError
    } = useSearchGroups(
        {
            searchTerm: debounceSearchValue,
            includePermissions: true,
            ...getPagingParams(page, itemsPerPage)
        },
        { ...listQueryDefault, query: { enabled: !!debounceSearchValue } }
    );
    const canCreate = usePermissions('Create', 'Group', searchGroups, groups);
    const { t } = useTranslation();

    const totalPages = getTotalPages(itemsPerPage, debounceSearchValue, searchGroups, groups);

    const handleRowClicked = (_event: MouseEvent, item: BasicGroupModelApiResponse) => {
        navigate(item.data.id);
    };

    const handleOnSearch = (text: string) => {
        setPage(1);
        setSearchValue(text);
    };

    return (
        <Stack className="card w-full p-xl">
            <div className="text-4xl">{t('groups')}</div>
            <PermissionProvider context="Group" items={[searchGroups, groups]}>
                <OverviewGrid
                    AddDialogType={canCreate ? CreateGroup : undefined}
                    addDialogTitle={t('createItem', { itemType: t('group') })}
                    additionalRowActions={[DuplicateGroupAction, DeleteGroupAction, ShowRoleAssignments]}
                    data={(debounceSearchValue ? searchGroups?.data : groups?.data) ?? []}
                    error={searchGroupsError ?? groupsError}
                    fieldDescriptions={[
                        {
                            header: t('name'),
                            data: 'data.name',
                            lockUpdate: true
                        },
                        { header: t('description'), data: 'data.description' }
                    ]}
                    isLoading={areSearchGroupsLoading || areGroupsLoading}
                    placeholder={!debounceSearchValue && <NoGroupsPlaceholder />}
                    onRowClicked={handleRowClicked}
                    onSearch={(text) => handleOnSearch(text)}
                />

                <Pagination
                    disabled={areSearchGroupsLoading || areGroupsLoading}
                    itemsPerPage={itemsPerPage}
                    page={page}
                    totalPages={totalPages}
                    withSkip
                    onItemsPerPageChange={setItemsPerPage}
                    onPageChange={setPage}
                />
            </PermissionProvider>
        </Stack>
    );
};
