import { Button } from '@mantine/core';
import { Icon } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { IRowData } from '../interfaces';

export const AddItem = ({ onClick, data }: { onClick?: () => void; data: IRowData[] }) => {
    const { t } = useTranslation();
    return (
        <Button disabled={data.some((item) => item.isEditable)} leftSection={<Icon icon="add" />} onClick={() => !!onClick && onClick()}>
            {t('add')}
        </Button>
    );
};
