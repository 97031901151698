export type Exception = { message: string };

export type ExceptionDetail = {
    message: string;
    details?: (
        | string
        | {
              property: string;
              message: string;
          }
    )[];
};

export class MultiResponseError extends Error {
    public Details: BulkResponse;

    constructor(msg: string, details: BulkResponse) {
        super(msg);
        this.Details = details;

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, MultiResponseError.prototype);
    }
}

export type BulkResponse = {
    responses?: BulkItemResponse[] | null;
};

export type BulkItemResponse = {
    identifier?: string | null;
    status: number;
    error?: string | null;
    traceId?: string | null;
};
