import { createFormContext } from '@mantine/form';
import { z } from 'zod';

import { AssignmentPrincipalType, PrincipalType } from 'api/v3/models';
import { i18n } from 'i18n';

const principalsType = z
    .object({
        id: z.string().min(1, { message: i18n.t('fieldRequired') }),
        displayName: z.string().nullish(),
        email: z.string().nullish(),
        principalType: z.nativeEnum(PrincipalType),
        assignmentType: z.nativeEnum(AssignmentPrincipalType).optional()
    })
    .array();

export const applicationFormSchema = z.object({
    application: z.object({
        id: z.string().optional(),
        displayName: z.string().min(1, { message: i18n.t('fieldRequired') }),
        description: z.string().nullish(),
        imageUri: z.string().nullish(),
        owners: principalsType,
        collaborators: principalsType
    })
});

export type ApplicationFormValues = z.infer<typeof applicationFormSchema>;

export const [ApplicationFormProvider, useApplicationFormContext, useApplicationForm] =
    createFormContext<ApplicationFormValues>();
