import { useQueryClient } from '@tanstack/react-query';
import { LoadingAnimation } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
    useCreateApiResourceRole,
    useCreateApiResourceScope,
    useDeleteApiResourceRole,
    useDeleteApiResourceScope,
    useGetApiResourceDetailsById,
    useGetApiResourceRoles,
    useGetApiResourceScopes,
    useUpdateApiResourceRole,
    useUpdateApiResourceScope,
    useUpdateApiResource,
    getGetApiResourceByIdQueryKey,
    getApiResourceScopeClients,
    useGetTokenExchangeClients,
    useAddTokenExchangeClient,
    useRemoveTokenExchangeClient
} from 'api/v3/api-resources/api-resources';
import { ApiResourceScopeModelWithClients, GetRoleAssignmentResponseItem } from 'api/v3/models';
import { getApiResourceRoleAssignments } from 'api/v3/role/role';
import { usePermissions } from 'modules/shared/permissions';
import { DetailFormVariant } from 'shared/components/detailForm';
import { ItemNotFound } from 'shared/components/ItemNotFound';
import { ApiForm } from './ApiForm';
import { ApiFormValues } from './ApiFormProvider';
import { RemovedScopesRolesModal } from './components/RemovedScopesRolesModal';

export const EditApi = () => {
    const { t } = useTranslation();
    const { apiId = '' } = useParams();
    const queryClient = useQueryClient();

    const [apiFormValues, setApiFormValues] = useState<ApiFormValues | undefined>(undefined);
    const [removedScopes, setRemovedScopes] = useState<ApiResourceScopeModelWithClients[]>([]);
    const [removedRoles, setRemovedRoles] = useState<GetRoleAssignmentResponseItem[][]>([]);

    const { data: api, queryKey: apiQueryKey, error } = useGetApiResourceDetailsById(apiId);
    const { data: scopes, queryKey: scopesQueryKey } = useGetApiResourceScopes(apiId);

    const { mutateAsync: updateApiAsync } = useUpdateApiResource();
    const { mutateAsync: deleteScopeAsync } = useDeleteApiResourceScope();
    const { mutateAsync: updateScopesAsync } = useUpdateApiResourceScope();
    const { mutateAsync: createScopeAsync } = useCreateApiResourceScope();

    const { data: roles, queryKey: rolesQueryKey } = useGetApiResourceRoles(apiId);
    const { mutateAsync: createRolesAsync } = useCreateApiResourceRole();
    const { mutateAsync: updateRolesAsync } = useUpdateApiResourceRole();
    const { mutateAsync: deleteRolesAsync } = useDeleteApiResourceRole();

    const { data: tokenExchangeClients, queryKey: tokenExchangeClientsQueryKey } = useGetTokenExchangeClients(apiId);

    const { mutateAsync: addTokenExchangeClient } = useAddTokenExchangeClient();
    const { mutateAsync: removeTokenExchangeClient } = useRemoveTokenExchangeClient();

    const canUpdate = usePermissions('Update');

    if (error) {
        return <ItemNotFound errorStatus={error.response?.status} goBackRoute="../" itemId={apiId} itemType={t('api')} />;
    }

    if (!api?.data || !scopes?.data || !roles?.data || !tokenExchangeClients?.data) {
        return <LoadingAnimation />;
    }

    const handleSubmit = async (apiForm: ApiFormValues) => {
        const rmvScopes = apiForm.scopes.filter((x) => x.isRemoved);
        const rmvRoles = apiForm.roles.filter((x) => x.isRemoved);

        if (rmvScopes.length <= 0 && rmvRoles.length <= 0) {
            await handleUpdate(apiForm);
            return;
        }

        const removedScopesDetails: ApiResourceScopeModelWithClients[] = [];
        const removedRolesDetails: GetRoleAssignmentResponseItem[][] = [];

        for (const rmvScope of rmvScopes) {
            if (rmvScope.id) {
                const removedScopeDetail = await getApiResourceScopeClients(rmvScope.id);
                if (removedScopeDetail.data.clients.length > 0) {
                    removedScopesDetails.push(removedScopeDetail.data);
                }
            }
        }

        for (const rmvRole of rmvRoles) {
            const removedRoleDetail = await getApiResourceRoleAssignments({
                roleIdentifiers: [rmvRole.name]
            });
            if (removedRoleDetail.data.length > 0) {
                removedRolesDetails.push(removedRoleDetail.data);
            }
        }

        if (removedScopesDetails.length <= 0 && removedRolesDetails.length <= 0) {
            await handleUpdate(apiForm);
            return;
        }

        setApiFormValues(apiForm);
        setRemovedScopes(removedScopesDetails);
        setRemovedRoles(removedRolesDetails);
    };

    const handleUpdate = async (apiForm?: ApiFormValues) => {
        const formValues = apiForm ?? apiFormValues;
        if (!formValues) {
            return;
        }

        await updateApiAsync({
            id: apiId,
            data: { ...formValues.api, applicationId: formValues.api.applicationId }
        });

        const rmvScopes = formValues.scopes.filter((x) => x.isRemoved);
        const updatedScopes = formValues.scopes.filter((x) => x.id && !x.isRemoved);
        const addedScopes = formValues.scopes.filter((x) => !x.id);

        // Wait for all deletes before adding new scopes
        await Promise.all([
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...rmvScopes.map((scope) => deleteScopeAsync({ id: scope.id! }))
        ]);
        await Promise.all([
            ...updatedScopes.map((scope) =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                updateScopesAsync({ id: scope.id!, data: scope })
            )
        ]);
        await Promise.all([...addedScopes.map((scope) => createScopeAsync({ id: apiId, data: scope }))]);

        const rmvRoles = formValues.roles.filter((x) => x.isRemoved);
        const updatedRoles = formValues.roles.filter((x) => x.id && !x.isRemoved);
        const addedRoles = formValues.roles.filter((x) => !x.id);

        // Wait for all deletes before adding new roles
        await Promise.all([
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...rmvRoles.map((role) => deleteRolesAsync({ id: role.id! }))
        ]);
        await Promise.all([
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...updatedRoles.map((role) => updateRolesAsync({ id: role.id!, data: role }))
        ]);
        await Promise.all([...addedRoles.map((role) => createRolesAsync({ id: apiId, data: role }))]);

        const rmvClients = formValues.tokenExchangeClients.filter((x) => x.isRemoved);
        const addedClients = formValues.tokenExchangeClients.filter((x) => x.isNew);

        // Wait for all deletes before adding new clients
        await Promise.all([
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...rmvClients.map((client) => removeTokenExchangeClient({ id: apiId, clientid: client.id! }))
        ]);

        await Promise.all([...addedClients.map((client) => addTokenExchangeClient({ id: apiId, clientid: client.id! }))]);

        await queryClient.invalidateQueries({ queryKey: apiQueryKey });
        await queryClient.invalidateQueries({ queryKey: scopesQueryKey });
        await queryClient.invalidateQueries({ queryKey: rolesQueryKey });
        await queryClient.invalidateQueries({ queryKey: tokenExchangeClientsQueryKey });
        await queryClient.invalidateQueries({ queryKey: getGetApiResourceByIdQueryKey(apiId) });

        handleCancel();
    };

    const handleCancel = () => {
        setApiFormValues(undefined);
        setRemovedScopes([]);
        setRemovedRoles([]);
    };

    return (
        <>
            <ApiForm
                api={api.data}
                roles={roles.data ?? []}
                scopes={scopes.data ?? []}
                title={t('editItem', { itemType: t('api') })}
                tokenExchangeClients={tokenExchangeClients.data ?? []}
                variant={DetailFormVariant.EDIT}
                onSubmit={canUpdate ? handleSubmit : undefined}
            />
            <RemovedScopesRolesModal removedRoles={removedRoles} removedScopes={removedScopes} onCancel={handleCancel} onSave={handleUpdate} />
        </>
    );
};
