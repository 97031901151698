import { createFormContext } from '@mantine/form';
import { z } from 'zod';

import { AssignmentPrincipalType, PrincipalType } from 'api/v3/models';
import { i18n } from 'i18n';
import { IRowData } from 'shared/components/extendedDataTable/interfaces';

export const groupFormSchema = z.object({
    basicInformation: z.object({
        name: z.string().min(1, { message: i18n.t('fieldRequired') }),
        description: z.string().nullish(),
        tenantId: z.string(),
        owners: z
            .object({
                id: z.string().min(1, { message: i18n.t('fieldRequired') }),
                principalType: z.nativeEnum(PrincipalType),
                displayName: z.string().nullish(),
                email: z.string().nullish()
            })
            .array()
    }),
    members: z
        .object({
            identifier: z.string().min(1, { message: i18n.t('fieldRequired') }),
            principalType: z.nativeEnum(PrincipalType),
            email: z.string().nullish(),
            displayName: z.string().optional(),
            assignmentType: z.nativeEnum(AssignmentPrincipalType)
        })
        .array(),
    roles: z
        .object({
            roleId: z.string().min(1),
            name: z.string().min(1, { message: i18n.t('fieldRequired') })
        })
        .array()
});

export type GroupFormType = z.infer<typeof groupFormSchema>;
export type GroupFormMembersType = GroupFormType['members'][0];
export type GroupFormRoleAssignmentsType = GroupFormType['roles'][0];

export type GroupDataGridMembersType = GroupFormMembersType & IRowData;
export type GroupDataGridRoleAssignmentsType = GroupFormRoleAssignmentsType & IRowData;

// You can give context variables any name
export const [GroupFormProvider, useGroupFormContext, useGroupForm] = createFormContext<GroupFormType>();
