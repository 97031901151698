import { t } from 'i18next';

import { DetailApplicationModel } from 'api/v3/models';
import { usePermissions } from 'modules/shared/permissions';
import { DetailForm, DetailFormVariant } from 'shared/components/detailForm';
import {
    ApplicationFormProvider,
    ApplicationFormValues,
    applicationFormSchema,
    useApplicationForm
} from './ApplicationFormProvider';
import { BasicInformation } from './forms/BasicInformation';
import { getFormApplication } from './mappings';

type Props = {
    application?: DetailApplicationModel;
    onSubmit: (application: ApplicationFormValues) => Promise<void>;
    onCancel?: () => void;

    isLoading?: boolean | undefined;
    title?: string | undefined;
    variant: DetailFormVariant;
};

export const ApplicationForm = ({ application, onSubmit, variant, title }: Props) => {
    const canUpdateOrCreate = usePermissions(['Update', 'Create']);

    const defaultApplication = getFormApplication(application);

    const form = useApplicationForm({
        initialValues: defaultApplication
    });

    const handleConfirm = async () => {
        await onSubmit(form.values);
        form.resetDirty();
    };

    const handleReset = () => {
        form.setValues(defaultApplication);
    };

    return (
        <ApplicationFormProvider form={form}>
            <DetailForm
                form={form}
                title={title}
                validationSchema={applicationFormSchema}
                variant={variant}
                onConfirm={canUpdateOrCreate ? handleConfirm : undefined}
                onReset={handleReset}
            >
                <BasicInformation
                    label={t('overview')}
                    name="basicInformation"
                    schemaPath="application"
                    variant={variant}
                />
            </DetailForm>
        </ApplicationFormProvider>
    );
};
