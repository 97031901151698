import { Table, ScrollArea, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { LoadingAnimation, variants, Icon } from '@uag/react-core';
import React, { useCallback, useEffect, useState, MouseEvent, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorNotification } from '../errorHandling';
import { Header } from './Header';
import { sortData } from './helpers';
import { IFieldDescription, IRowData } from './interfaces';
import { RowActionVariant, Row } from './Row';
import { SelectionContextProvider } from './SelectionContextProvider';

type Props<TRowType> = {
    data: TRowType[];
    fieldDescriptions: IFieldDescription<TRowType>[];
    withSearch?: boolean;
    withSort?: boolean;
    propertyPathBase?: string;
    onItemsSelected?: (items: TRowType[]) => void;
    withSelection?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form?: UseFormReturnType<any, any>;
    rowActions?: ((value: TRowType, index: number, variant: RowActionVariant) => ReactNode)[];
    headerActions?: ReactNode[];
    withHeaders?: boolean;
    placeholder?: ReactNode;
    renderEdit?: (value: TRowType, index: number, onRowSaved?: () => void) => ReactNode;
    onRowClicked?: (event: MouseEvent, item: TRowType) => void;
    maxHeight?: number | string;
    onSearch?: (searchText: string) => void;
    isLoading?: boolean;
    onRowSaved?: () => void;
    error?: unknown;
    getRowClass?: (item: TRowType) => string | undefined;
};

export const DataTable = <TRowType extends IRowData>({
    data,
    fieldDescriptions,
    withSearch = false,
    withSort = false,
    form,
    withHeaders = false,
    withSelection = false,
    propertyPathBase,
    rowActions,
    onItemsSelected,
    headerActions,
    placeholder,
    renderEdit,
    onRowClicked: handleRowClicked,
    maxHeight,
    onSearch,
    isLoading,
    onRowSaved: handleRowSaved,
    error,
    getRowClass
}: Props<TRowType>) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState<string | null>(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
    const [selection, setSelection] = useState<string[]>([]);
    const viewport = useRef<HTMLDivElement>(null);

    const handleToggleRow = useCallback(
        (id: string) => {
            setSelection((current) => (current.includes(id) ? current.filter((item) => item !== id) : [...current, id]));
        },
        [setSelection]
    );

    const handleTogleAll = () =>
        setSelection((current) => (current.length === data.length ? [] : data.filter((item) => !item.isEditable).map((item) => item.key)));

    useEffect(() => {
        setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search }));
    }, [data, search, onSearch, reverseSortDirection, sortBy]);

    useEffect(() => {
        onItemsSelected && onItemsSelected(data.filter((item) => selection.includes(item.key)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection]);

    useEffect(() => {
        setSelection([]);
    }, [data]);

    if (data.length === 0 && placeholder && !isLoading) {
        return <>{placeholder}</>;
    }

    const handleOnSort = (field: string) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        setSortedData(sortData(data, { sortBy: field, reversed, search }));
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        if (onSearch) {
            onSearch(value);
        } else {
            setSearch(value);
        }
    };

    return (
        <SelectionContextProvider selectedItemKeys={selection} selectionEnabled={withSelection}>
            <div className="flex gap-base justify-end items-center pb-base w-full">{headerActions}</div>
            {withSearch && (
                <TextInput
                    className="pb-base w-full"
                    leftSection={<Icon icon="search" />}
                    mb="md"
                    placeholder={t('search')}
                    variant={variants.textField.iconDecoration}
                    onChange={handleSearchChange}
                />
            )}

            <ScrollArea className="w-full" h={maxHeight} type="auto" viewportRef={viewport} offsetScrollbars>
                {isLoading && <LoadingAnimation />}
                {!isLoading && (
                    <Table
                        highlightOnHoverColor="var(--mantine-color-gray-2)"
                        horizontalSpacing="md"
                        style={{ tableLayout: 'fixed', minWidth: 700 }}
                        verticalSpacing="xs"
                        highlightOnHover
                    >
                        <colgroup>
                            {withSelection && <col width={40} />}
                            {fieldDescriptions.map((fieldDescriptions) => (
                                <col key={fieldDescriptions.data} style={{ minWidth: fieldDescriptions.minWidth }} width={fieldDescriptions.width} />
                            ))}
                            {!!rowActions && <col />}
                        </colgroup>
                        {withHeaders && (
                            <Header
                                data={data}
                                fieldDescriptions={fieldDescriptions}
                                reverseSortDirection={reverseSortDirection}
                                sortBy={sortBy}
                                withRowActionsColumn={!!rowActions}
                                withSort={withSort}
                                onSort={handleOnSort}
                                onToggleAll={handleTogleAll}
                            />
                        )}
                        <Table.Tbody>
                            {sortedData.map(
                                (item, index) =>
                                    !item.isRemoved && (
                                        <Row
                                            key={item.key}
                                            fieldDescriptions={fieldDescriptions}
                                            form={form}
                                            getRowClass={getRowClass}
                                            index={index}
                                            item={item}
                                            propertyPathBase={propertyPathBase}
                                            renderEdit={renderEdit}
                                            rowActions={rowActions}
                                            onRowClicked={handleRowClicked}
                                            onRowSaved={handleRowSaved}
                                            onToggleRow={handleToggleRow}
                                        />
                                    )
                            )}
                        </Table.Tbody>
                    </Table>
                )}
            </ScrollArea>
            <ErrorNotification error={error} title={t('loadingItems')} />
        </SelectionContextProvider>
    );
};
