import { ReactNode, createContext } from 'react';

export interface DetailFormContextProps {
    onValidate: () => boolean;
}

export const DetailFormContext = createContext<DetailFormContextProps | null>(null);

type DetailFormProviderProps = {
    children: ReactNode;
} & DetailFormContextProps;

export const DetailFormProvider = ({ onValidate, children }: DetailFormProviderProps) => {
    return <DetailFormContext.Provider value={{ onValidate }}>{children}</DetailFormContext.Provider>;
};
