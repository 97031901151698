import { Badge, Text } from '@mantine/core';

import { UserState } from 'api/v3/models';
import { i18n } from 'i18n';

export const getIndicatorPropsByUserState = (userState: UserState): { text: string; variant: string } => {
    switch (userState) {
        case UserState.InvitationPending:
            return { text: i18n.t('invitationPending'), variant: '' };
        case UserState.InvitationExpired:
            return { text: i18n.t('invitationExpired'), variant: 'warning' };
        case UserState.Activated:
            return { text: i18n.t('activated'), variant: 'success' };
        case UserState.Deactivated:
            return { text: i18n.t('deactivated'), variant: '' };
        case UserState.Locked:
            return { text: i18n.t('locked'), variant: 'warning' };
        case UserState.Deleted:
            return { text: i18n.t('deleted'), variant: 'alert' };
    }
};

type Props = {
    userState: UserState;
};

export const UserStateIndicator = ({ userState }: Props) => {
    const badgeProps = getIndicatorPropsByUserState(userState);

    return (
        <Badge ml={4} variant={badgeProps.variant}>
            <Text>{badgeProps.text}</Text>
        </Badge>
    );
};
