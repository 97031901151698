import { useTranslation } from 'react-i18next';

import { Placeholder } from 'shared/components/placeholder';
import { AddAssignedPrincipal } from './AddAssignedPrincipal';
import principalAssignmentsEmpty from './assets/principalAssignmentsEmpty.svg';

export const NoAssignedPrincipalsPlaceholder = () => {
    const { t } = useTranslation();

    return (
        <Placeholder
            action={<AddAssignedPrincipal />}
            description={t('addPrincipalAssignment')}
            picture={principalAssignmentsEmpty}
            text={t('noPrincipalsAssigned')}
        />
    );
};
