import { Flex, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { usePermissions } from 'modules/shared/permissions';
import { useTenantFormContext } from 'modules/userManagement/tenants/TenantFormProvider';
import { DetailFormChildProps } from 'shared/components/detailForm';
import { Switch } from 'shared/components/inputs';
import { NumberInput } from 'shared/components/inputs/NumberInput';

type Props = DetailFormChildProps;

export const Configuration = (_: Props) => {
    const { t } = useTranslation();
    const form = useTenantFormContext();
    const canUpdateConfiguration = usePermissions('UpdateTenantConfiguration');

    return (
        <Stack gap={0}>
            <Switch
                description={t('enableUserDataSynchronizationDescription')}
                disabled={!canUpdateConfiguration} // readOnly is not working on switches
                form={form}
                label={t('enableUserDataSynchronization')}
                labelPosition="left"
                propertyPath="configuration.enableUserDataSynchronization"
            />
            <Switch
                disabled={!canUpdateConfiguration} // readOnly is not working on switches
                form={form}
                label={t('allowUserBrowsing')}
                labelPosition="left"
                mt="lg"
                propertyPath="configuration.allowUserBrowsing"
            />
            <Switch
                disabled={!canUpdateConfiguration} // readOnly is not working on switches
                form={form}
                label={t('deleteDeactivatedUsers')}
                labelPosition="left"
                mt="lg"
                propertyPath="configuration.isDeleteDeactivatedUsersEnabled"
            />
            <Flex align="end" gap="md">
                <NumberInput
                    description={t('deleteDeactivatedUsersDescription')}
                    disabled={!form.values.configuration.isDeleteDeactivatedUsersEnabled}
                    form={form}
                    min={1}
                    propertyPath="configuration.deleteDeactivatedUsers"
                    readOnly={!canUpdateConfiguration}
                    w="100%"
                />
                <Text c={!form.values.configuration.isDeleteDeactivatedUsersEnabled ? 'gray.5' : undefined} mb={6}>
                    {t('days')}
                </Text>
            </Flex>
            <Switch
                disabled={!canUpdateConfiguration}
                form={form}
                label={t('deactivateInactiveUsers')}
                labelPosition="left"
                mt="lg"
                propertyPath="configuration.isDeactivateInactiveUsersEnabled"
            />
            <Flex align="end" gap="md">
                <NumberInput
                    description={t('deactivateInactiveUsersDescription')}
                    disabled={!form.values.configuration.isDeactivateInactiveUsersEnabled}
                    form={form}
                    min={1}
                    propertyPath="configuration.deactivateInactiveUsers"
                    readOnly={!canUpdateConfiguration}
                    w="100%"
                />
                <Text c={!form.values.configuration.isDeactivateInactiveUsersEnabled ? 'gray.5' : undefined} mb={6}>
                    {t('days')}
                </Text>
            </Flex>
            <Switch
                disabled={!canUpdateConfiguration}
                form={form}
                label={t('passwordExpiration')}
                labelPosition="left"
                mt="lg"
                propertyPath="configuration.isPasswordExpirationEnabled"
            />
            <Flex align="end" gap="md">
                <NumberInput
                    description={t('passwordExpirationDescription')}
                    disabled={!form.values.configuration.isPasswordExpirationEnabled}
                    form={form}
                    min={1}
                    propertyPath="configuration.passwordExpiration"
                    readOnly={!canUpdateConfiguration}
                    w="100%"
                />
                <Text c={!form.values.configuration.isPasswordExpirationEnabled ? 'gray.5' : undefined} mb={6}>
                    {t('days')}
                </Text>
            </Flex>
        </Stack>
    );
};
