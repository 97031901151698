import { Text, Stack, Collapse } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import { Props } from './ErrorNotification';
import { getExceptionDetails } from './functions';

export const ErrorNotificationContent = ({ title, error }: Props) => {
    const exception = getExceptionDetails(error);
    const [opened, { toggle }] = useDisclosure(false);

    const { t } = useTranslation();

    return (
        <Stack>
            <button className="flex flex-col " onClick={toggle}>
                <div className="text-lg-bold">{t('errorTitle', { action: title })}</div>
                <Text>{exception?.message}</Text>
            </button>
            <Collapse in={opened}>
                <Stack>
                    {exception?.details?.map((detail, index) => (
                        <Text key={index}>{typeof detail !== 'string' ? `${detail.property}: ${detail.message}` : detail}</Text>
                    ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};
