import { createFormContext } from '@mantine/form';
import { z } from 'zod';

import { PrincipalType } from 'api/v3/models';
import { i18n } from 'i18n';

export const tenantFormSchema = z.object({
    basicInformation: z.object({
        name: z.string().min(1, { message: i18n.t('fieldRequired') }),
        owners: z
            .object({
                id: z.string().min(1, { message: i18n.t('fieldRequired') }),
                principalType: z.nativeEnum(PrincipalType),
                displayName: z.string().nullish(),
                email: z.string().nullish()
            })
            .array(),
        collaborators: z
            .object({
                id: z.string().min(1, { message: i18n.t('fieldRequired') }),
                principalType: z.nativeEnum(PrincipalType),
                displayName: z.string().nullish(),
                email: z.string().nullish()
            })
            .array()
    }),
    localLoginDomains: z
        .string()
        .min(1, { message: i18n.t('fieldRequired') })
        .array(),
    configuration: z.object({
        enableUserDataSynchronization: z.boolean(),
        allowUserBrowsing: z.boolean(),
        isDeleteDeactivatedUsersEnabled: z.boolean(),
        deleteDeactivatedUsers: z.number(),
        isDeactivateInactiveUsersEnabled: z.boolean(),
        deactivateInactiveUsers: z.number(),
        isPasswordExpirationEnabled: z.boolean(),
        passwordExpiration: z.number(),
        isUmdaschGroupTenant: z.boolean()
    })
});

export type TenantFormType = z.infer<typeof tenantFormSchema>;

// You can give context variables any name
export const [TenantFormProvider, useTenantFormContext, useTenantForm] = createFormContext<TenantFormType>();
