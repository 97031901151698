import { createFormContext } from '@mantine/form';
import { z } from 'zod';

import { TenantLoginProviderType, Salutation, UserState, GroupType } from 'api/v3/models';
import { IRowData } from 'shared/components/extendedDataTable/interfaces';

export const basicUserInformation = z.object({
    id: z.string(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    userName: z.string().nullish(),
    email: z.string().nullish(),
    displayName: z.string().nullish(),
    profilePictureUri: z.string().nullish(),
    state: z.nativeEnum(UserState),
    loginProviderType: z.nativeEnum(TenantLoginProviderType).nullish()
});

export const detailUserInformation = basicUserInformation.extend({
    salutation: z.nativeEnum(Salutation),
    title: z.string().nullish(),
    mobileNumber: z.string().nullish(),
    mobileNumberCountryCode: z.string().nullish(),
    phoneNumber: z.string().nullish(),
    phoneNumberCountryCode: z.string().nullish(),
    language: z.string().nullish(),
    tenant: z.object({
        id: z.string(),
        name: z.string()
    })
});

export const userRoles = z
    .object({
        id: z.string().nullish(),
        roleId: z.string(),
        apiResourceId: z.string(),
        name: z.string(),
        displayName: z.string().nullish(),
        description: z.string().nullish()
    })
    .array();

export const userGroups = z
    .object({
        id: z.string().nullish(),
        groupId: z.string(),
        name: z.string(),
        groupType: z.nativeEnum(GroupType),
        description: z.string().nullish()
    })
    .array();

export const basicUserFormSchema = z.object({
    basicInformation: basicUserInformation,
    roles: userRoles,
    groups: userGroups
});

export const detailUserFormSchema = z.object({
    basicInformation: detailUserInformation,
    roles: userRoles,
    groups: userGroups
});

export type BasicUserFormValues = z.infer<typeof basicUserFormSchema>;
export type DetailUserFormValues = z.infer<typeof detailUserFormSchema>;

export type DetailUserFormType = Omit<DetailUserFormValues, 'roles' | 'groups'> & {
    roles: (DetailUserFormValues['roles'][0] & IRowData)[];
    groups: (DetailUserFormValues['groups'][0] & IRowData)[];
};

export type BasicUserFormType = Omit<BasicUserFormValues, 'roles' | 'groups'> & {
    roles: (DetailUserFormValues['roles'][0] & IRowData)[];
    groups: (DetailUserFormValues['groups'][0] & IRowData)[];
};

export type DetailUserFormRolesFormType = DetailUserFormType['roles'][0];
export type DetailUserDataGridRolesType = DetailUserFormRolesFormType & IRowData;

export type DetailUserFormGroupsFormType = DetailUserFormType['groups'][0];
export type DetailUserDataGridGroupsType = DetailUserFormGroupsFormType & IRowData;

export const isBasicUser = (obj: unknown): obj is BasicUserFormType => {
    return basicUserFormSchema.safeParse(obj).success;
};

export const isDetailUser = (obj: unknown): obj is DetailUserFormType => {
    return detailUserFormSchema.safeParse(obj).success;
};

// You can give context variables any name
export const [DetailUserFormProvider, useDetailUserFormContext, useDetailUserForm] = createFormContext<DetailUserFormType>();
export const [BasicUserFormProvider, useBasicUserFormContext, useBasicUserForm] = createFormContext<BasicUserFormType>();
