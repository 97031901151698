import { useOidcUser } from '@axa-fr/react-oidc';
import { createContext, ReactNode } from 'react';

import { applicationInsights, applicationInsightsReactPlugin } from './applicationInsights';

const AppInsightsContext = createContext(applicationInsightsReactPlugin);

const AppInsightsContextProvider = ({ children }: { children: ReactNode }) => {
    const { oidcUser } = useOidcUser();

    if (oidcUser?.sub) {
        applicationInsights.setAuthenticatedUserContext(oidcUser?.sub, oidcUser?.sub);
    }

    return <AppInsightsContext.Provider value={applicationInsightsReactPlugin}>{children}</AppInsightsContext.Provider>;
};
export { AppInsightsContext, AppInsightsContextProvider };
