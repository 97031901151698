import { PrincipalType } from 'api/v3/models';
import { ClientBaseRoute } from 'modules/applications/client/Routes';
import { ApplicationBaseRoute } from 'modules/applications/Routes';
import { GroupBaseRoute } from 'modules/userManagement/groups/Routes';
import { UserManagementBaseRoute } from 'modules/userManagement/Routes';
import { TenantBaseRoute } from 'modules/userManagement/tenants/Routes';
import { UsersBaseRoute } from 'modules/userManagement/users/Routes';

type ItemLinkType = {
    id: string;
    type: PrincipalType;
};

export function getItemLink(item: ItemLinkType, anchor?: string): string {
    const linkAnchor = anchor ? `#${anchor}` : '';

    switch (item.type) {
        case PrincipalType.Client:
            return `/${ApplicationBaseRoute}/${ClientBaseRoute}/${item.id}${linkAnchor}`;
        case PrincipalType.Group:
            return `/${UserManagementBaseRoute}/${GroupBaseRoute}/${item.id}${linkAnchor}`;
        case PrincipalType.Tenant:
            return `/${UserManagementBaseRoute}/${TenantBaseRoute}/${item.id}${linkAnchor}`;
        case PrincipalType.User:
            return `/${UserManagementBaseRoute}/${UsersBaseRoute}/${item.id}${linkAnchor}`;
        default:
            return '/';
    }
}
