import { useTranslation } from 'react-i18next';

import { BasicApiResourceRoleModelApiResponse, BasicGroupModelApiResponse, BasicUserModel } from 'api/v3/models';
import { usePermissions } from 'modules/shared/permissions';
import { DetailForm, DetailFormVariant } from 'shared/components/detailForm';
import { UserBasicInformationHeader } from './components/UserBasicInformationHeader';
import { UserBasicInformationRightSection } from './components/UserBasicInformationRightSection';
import { BasicInformation } from './forms/BasicInformation';
import { Groups } from './forms/Groups';
import { Roles } from './forms/Roles';
import { getBasicFormUser } from './mappings';
import { BasicUserFormProvider, basicUserFormSchema, BasicUserFormType, useBasicUserForm } from './UserFormProvider';

type Props = {
    user: BasicUserModel;
    roles?: BasicApiResourceRoleModelApiResponse[];
    groups?: BasicGroupModelApiResponse[];
    onSubmit?: (UserFormValues: BasicUserFormType) => Promise<void>;
    onCancel?: () => void;
    variant?: DetailFormVariant;
};

export const UserBasicForm = ({ user, roles, groups, variant = DetailFormVariant.CREATE, onSubmit }: Props) => {
    const { t } = useTranslation();
    const defaultUser = getBasicFormUser(user, roles, groups);

    const canUpdateOrCreate = usePermissions('Update');
    const canAssignRoles = usePermissions('AssignRole');

    const form = useBasicUserForm({
        initialValues: defaultUser
    });

    const handleConfirm = async () => {
        onSubmit && (await onSubmit(form.values));
        form.resetDirty();
    };

    const handleReset = () => {
        form.reset();
    };

    return (
        <BasicUserFormProvider form={form}>
            <DetailForm
                form={form}
                title={t('overview')}
                validationSchema={basicUserFormSchema}
                variant={variant}
                onConfirm={canUpdateOrCreate ? handleConfirm : undefined}
                onReset={handleReset}
            >
                <BasicInformation
                    form={form}
                    header={<UserBasicInformationHeader form={form} />}
                    label={t('basicInformation')}
                    labelRightSection={<UserBasicInformationRightSection form={form} />}
                    name="basicInformation"
                    schemaPath="basicInformation"
                    variant={variant}
                />
                <Roles disabled={!canAssignRoles} form={form} label={t('roles')} name="roles" schemaPath="roles" variant={variant} />
                <Groups disabled={!canUpdateOrCreate} form={form} label={t('groups')} name="groups" schemaPath="groups" variant={variant} />
            </DetailForm>
        </BasicUserFormProvider>
    );
};
