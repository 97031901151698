import { UseFormReturnType } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import { BasicApiResourceRoleModel } from 'api/v3/models';
import { usePermissions } from 'modules/shared/permissions';
import { RoleSelector } from 'modules/shared/RoleSelector';
import { DetailFormChildProps } from 'shared/components/detailForm';
import { ExtendedDataTable } from 'shared/components/extendedDataTable';
import { BasicUserFormType, DetailUserFormType } from '../UserFormProvider';

type Props = {
    form: UseFormReturnType<BasicUserFormType> | UseFormReturnType<DetailUserFormType>;
} & DetailFormChildProps;

const handleRoleSelected = (
    form: UseFormReturnType<BasicUserFormType> | UseFormReturnType<DetailUserFormType>,
    role: BasicApiResourceRoleModel,
    index: number
) => {
    form.setFieldValue(`roles.${index}.roleId`, role.id);
    form.setFieldValue(`roles.${index}.isEditable`, false);
    form.setFieldValue(`roles.${index}.apiResourceId`, role.apiResourceId);
    form.setFieldValue(`roles.${index}.name`, role.name);
    form.setFieldValue(`roles.${index}.displayName`, role.displayName);
};

const newAssignment = () => ({
    name: '',
    key: randomId(),
    isEditable: true,
    isRemoved: false
});

export const Roles = ({ form }: Props) => {
    const { t } = useTranslation();
    const canAssignRole = usePermissions('AssignRole');

    return (
        <ExtendedDataTable
            canCreate={canAssignRole}
            canEdit={canAssignRole}
            canRemove={canAssignRole}
            data={form.values.roles}
            fieldDescriptions={[
                { header: t('name'), data: 'name', lockUpdate: true },
                {
                    header: t('displayName'),
                    data: 'displayName',
                    lockUpdate: true
                }
            ]}
            form={form}
            generateNewItem={newAssignment}
            propertyPathBase="roles"
            renderEdit={(_item, index, onItemSaved) => {
                return (
                    <RoleSelector
                        form={form}
                        index={index}
                        propertyBasePath="roles"
                        onRoleSelected={(role) => {
                            handleRoleSelected(form, role, index);
                            onItemSaved && onItemSaved();
                        }}
                    />
                );
            }}
        />
    );
};
