import { Button, Group, Stack, Switch } from '@mantine/core';
import { UseFormReturnType, zodResolver } from '@mantine/form';
import { useQueryClient } from '@tanstack/react-query';
import { LoadingAnimation } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AssignmentAction, AssignmentPrincipalType, GetRoleAssignmentResponseItem, PrincipalType, RoleAssignmentContextType } from 'api/v3/models';
import { useAssignRevokeIdentityRoles, useGetIdentityRoleAssignments } from 'api/v3/role/role';
import { getItemLink } from 'modules/shared/functions';
import { checkBulkResponse, ErrorNotification } from 'shared/components/errorHandling';
import { ExtendedDataTable } from 'shared/components/extendedDataTable';
import { usePermissions } from '../permissions';
import { SelectedPrincipal } from '../PrincipalSelector';
import { getFormRoleAssignments, newAssignment } from './functions';
import { GroupNameColumn } from './GroupNameColumn';
import { NewRoleAssignment } from './NewRoleAssignment';
import { RoleAssignmentsFormType, roleAssignmentsFormSchema, useRoleAssignmentsForm } from './RoleAssignmentsFormProvider';
import { RoleColumn } from './RoleColumn';

type Props = {
    contextType?: RoleAssignmentContextType;
    contextId?: string;
    includeInherited?: boolean;
    includeGlobalAssignments?: boolean;
    principalIdentifier?: string;
    principalType?: AssignmentPrincipalType;
    onSaved?: () => void;
};

export const handlePrincipalSelected = (form: UseFormReturnType<RoleAssignmentsFormType>, index: number, selectedPrincipal: SelectedPrincipal) => {
    form.setFieldValue(`roleAssignments.${index}.principal.id`, selectedPrincipal.id);
    form.setFieldValue(`roleAssignments.${index}.principal.displayName`, selectedPrincipal.displayName);
    form.setFieldValue(`roleAssignments.${index}.principal.email`, selectedPrincipal.email);
    form.setFieldValue(`roleAssignments.${index}.principal.principalType`, selectedPrincipal.principalType);
    form.setFieldValue(`roleAssignments.${index}.principal.assignmentType`, selectedPrincipal.assignmentType);
};

export const RoleAssignmentsGrid = ({
    contextType,
    contextId,
    includeInherited = false,
    includeGlobalAssignments = false,
    principalIdentifier,
    principalType,
    onSaved
}: Props) => {
    const [includeInheritedState, setIncludeInheritedState] = useState(includeInherited);
    const [includeGlobalState, setIncludeGlobalState] = useState(includeGlobalAssignments);
    const { t } = useTranslation();

    const queryFilter = {
        contextType,
        filterByContextType: contextType,
        contextId,
        includeInheritedContextAssignments: includeInheritedState,
        includeInheritedPrincipalAssignments: includeInheritedState,
        includeGlobalAssignments: includeGlobalState,
        principalType,
        principalIdentifier
    };
    const queryClient = useQueryClient();
    const { data: roleAssignments, isLoading, error, queryKey } = useGetIdentityRoleAssignments(queryFilter, { query: { staleTime: 0 } });

    if (error) {
        <ErrorNotification error={error} title={t('roleAssignments')} />;
    }

    const handleOnSave = async () => {
        await queryClient.invalidateQueries({ queryKey });
        onSaved && onSaved();
    };

    return (
        <>
            {contextType !== 'Global' && (
                <Group mb="sm">
                    <Switch
                        checked={includeInheritedState}
                        label={t('showItem', { itemType: t('inheritedAssignments') })}
                        onChange={(event) => {
                            queryClient.removeQueries({ queryKey });
                            setIncludeInheritedState(event.currentTarget.checked);
                        }}
                    />
                    <Switch
                        checked={includeGlobalState}
                        label={t('showItem', { itemType: t('globalAssignments') })}
                        onChange={(event) => {
                            queryClient.removeQueries({ queryKey });
                            setIncludeGlobalState(event.currentTarget.checked);
                        }}
                    />
                </Group>
            )}
            {roleAssignments?.data && !isLoading ? (
                <RoleAssignmentsGridInternal
                    contextId={contextId}
                    contextType={contextType}
                    roleAssignments={roleAssignments.data}
                    onSaved={handleOnSave}
                />
            ) : (
                <LoadingAnimation />
            )}
        </>
    );
};

type RoleAssignmentsGridInternalProps = {
    roleAssignments: GetRoleAssignmentResponseItem[];
    contextType?: RoleAssignmentContextType;
    contextId?: string;
    onSaved: () => Promise<void>;
};

const RoleAssignmentsGridInternal = ({ roleAssignments, contextId, contextType = 'Global', onSaved }: RoleAssignmentsGridInternalProps) => {
    const formRoleAssignments = getFormRoleAssignments(roleAssignments, contextType, contextId);
    const { mutateAsync: assignRevokeRoles } = useAssignRevokeIdentityRoles();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const canAssignRoles = usePermissions(contextType === 'Global' ? 'AssignGlobal' : 'AssignRole');
    const [error, setError] = useState<unknown>();

    const form = useRoleAssignmentsForm({
        initialValues: formRoleAssignments,
        validate: zodResolver(roleAssignmentsFormSchema)
    });

    const handleReset = () => {
        form.setValues(formRoleAssignments);
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError(undefined);
        try {
            const roleAssignmentActions = form.values.roleAssignments
                .filter((roleAssignment) => roleAssignment.isNew || roleAssignment.isRemoved)
                .map((roleAssignment) => ({
                    roleAssigneeActions: [
                        {
                            assignee: {
                                identifier: roleAssignment.principal.id,
                                type: roleAssignment.principal.assignmentType
                            },
                            assignmentAction: roleAssignment.isRemoved ? AssignmentAction.Revoke : AssignmentAction.Assign
                        }
                    ],
                    contextType: roleAssignment.context.contextAssignmentType,
                    contextId: roleAssignment.context.contextId,
                    roleId: roleAssignment.role.roleId
                }));

            for (const roleAssignmentAction of roleAssignmentActions) {
                checkBulkResponse(await assignRevokeRoles({ roleId: roleAssignmentAction.roleId, data: roleAssignmentAction }));
            }

            await onSaved();
            form.resetDirty();
        } catch (error: unknown) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={form.onSubmit(() => handleSubmit())}>
            <Stack>
                <ExtendedDataTable
                    canCreate={canAssignRoles}
                    canEdit={canAssignRoles}
                    canRemove={canAssignRoles}
                    canRemoveItem={(item) => item.context.contextAssignmentType === contextType}
                    data={form.values.roleAssignments}
                    fieldDescriptions={[
                        {
                            header: t('name'),
                            data: 'principal.displayName',
                            lockUpdate: true,

                            link: (item) => getItemLink({ id: item.principal.id, type: item.principal.principalType }),
                            render: (item) =>
                                item.principal.principalType === PrincipalType.Group ? (
                                    <GroupNameColumn roleAssignment={item} />
                                ) : (
                                    (item.principal.displayName ?? item.principal.id)
                                )
                        },
                        {
                            header: t('email'),
                            data: 'principal.email',
                            lockUpdate: true
                        },
                        {
                            header: t('type'),
                            data: 'principal.principalType',
                            lockUpdate: true
                        },
                        {
                            header: t('role'),
                            data: 'role.roleDisplayName',
                            render: (item) => <RoleColumn roleAssignment={item} />,
                            lockUpdate: true
                        },
                        {
                            header: t('contextType'),
                            data: 'context.contextAssignmentType',
                            lockUpdate: true
                        }
                    ]}
                    form={form}
                    generateNewItem={() => newAssignment(contextId, contextType)}
                    propertyPathBase="roleAssignments"
                    renderEdit={(_item, index) => {
                        return <NewRoleAssignment form={form} index={index} />;
                    }}
                />
                <ErrorNotification error={error} title={t('manageMember')} />
                <Group justify="flex-end">
                    <Button disabled={!form?.isDirty()} variant="default" onClick={handleReset}>
                        {t('reset')}
                    </Button>
                    <Button disabled={!form?.isDirty()} loading={loading} type="submit">
                        {t('save')}
                    </Button>
                </Group>
            </Stack>
        </form>
    );
};
