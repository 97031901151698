/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */

export type Salutation = typeof Salutation[keyof typeof Salutation];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Salutation = {
  Undefined: 'Undefined',
  Mr: 'Mr',
  Mrs: 'Mrs',
} as const;
