import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Placeholder } from 'shared/components/placeholder';

type Props = {
    addButton: ReactNode;
};

export const NoAssignedMembersPlaceholder = ({ addButton }: Props) => {
    const { t } = useTranslation();

    return (
        <Placeholder action={addButton} description={t('noMembersAssignedDescription')} text={t('noMembersAssigned')} />
    );
};
