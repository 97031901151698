import { createFormContext } from '@mantine/form';

import { BasicClientModel, CreateOrUpdateApiResourceModel, CreateOrUpdateApiResourceRoleModel } from 'api/v3/models';
import { IRowData } from 'shared/components/extendedDataTable/interfaces';

// TODO: Remove when form schema is switched to z.infer
export interface CreateOrUpdateApiResourceScopeRequest {
    name: string;
    displayName?: string | null;
    description?: string | null;
    enabled: boolean;
}

export type ScopesFormType = IRowData & CreateOrUpdateApiResourceScopeRequest;
export type RolesFormType = IRowData & CreateOrUpdateApiResourceRoleModel;

export type TokenExchangeClientsFormType = IRowData & BasicClientModel & { isNew: boolean };

export interface ApiFormValues {
    api: CreateOrUpdateApiResourceModel;
    scopes: ScopesFormType[];
    roles: RolesFormType[];
    tokenExchangeClients: TokenExchangeClientsFormType[];
}

// You can give context variables any name
export const [ApiFormProvider, useApiFormContext, useApiForm] = createFormContext<ApiFormValues>();
