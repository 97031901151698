import { Button } from '@mantine/core';
import { Icon } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

export const CreateUserButton = ({ onClick: handleClick }: { onClick: () => void }) => {
    const { t } = useTranslation();
    return (
        <Button leftSection={<Icon icon="add" />} onClick={handleClick}>
            {t('createItem', { itemType: t('user') })}
        </Button>
    );
};
