import { Group, Select } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetApiResourceRoles, useGetApiResources } from 'api/v3/api-resources/api-resources';
import { BasicApiResourceRoleModel } from 'api/v3/models';

type Props = {
    index: number;
    propertyBasePath: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturnType<any, any>;
    onRoleSelected?: (role: BasicApiResourceRoleModel) => void;
};

export const RoleSelector = ({ index, form, propertyBasePath, onRoleSelected }: Props) => {
    const { t } = useTranslation();

    const { data: apis, isLoading: areApisLoading } = useGetApiResources();
    const [selectedApiId, setSelectedApiId] = useState<string>();

    const { data: roles, isLoading: areRolesLoading } = useGetApiResourceRoles(
        selectedApiId ?? '',
        {},
        {
            query: { enabled: !!selectedApiId }
        }
    );

    const handleSelectApi = async (value: string | null) => {
        setSelectedApiId(value ?? undefined);
    };
    const handleSelectRole = (value: string | null) => {
        if (value) {
            const foundRole = roles?.data.find((role) => role.data.id === value);
            if (foundRole) {
                onRoleSelected && onRoleSelected(foundRole.data);
            }
        }
    };

    const apiOptions = apis
        ? apis.data.map((api) => ({
              value: api.data.id,
              label: [api.data.displayName, `(${api.data.name})`].join(' ')
          }))
        : [];

    const roleOptions = roles
        ? roles.data.map((role) => {
              const roleName = role.data.name.split('/').pop();
              const label = [role.data.displayName, `(${roleName})`].join(' ');
              return {
                  value: role.data.id,
                  label
              };
          })
        : [];

    return (
        <Group grow>
            <Select
                data={apiOptions}
                disabled={areApisLoading}
                nothingFoundMessage={t('noOptions')}
                placeholder={t('selectApi')}
                value={selectedApiId ?? null}
                autoFocus
                clearable
                searchable
                onChange={handleSelectApi}
            />
            <Select
                data={roleOptions}
                disabled={!roles || areRolesLoading}
                error={form.errors[`${propertyBasePath}.${index}.name`]}
                nothingFoundMessage={t('noOptions')}
                placeholder={t('selectItem', { itemType: t('role') })}
                clearable
                searchable
                onChange={handleSelectRole}
            />
        </Group>
    );
};
