import { Stack, Title, TextInput, Switch } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { BasicApiResourceModel } from 'api/v3/models';

type Props = {
    api: BasicApiResourceModel;
};

export const BasicApi = ({ api }: Props) => {
    const { t } = useTranslation();

    return (
        <Stack>
            <Title order={4}>{t('basicInformation')}</Title>
            <TextInput
                label={t('displayName')}
                placeholder={t('namePlaceholder')}
                value={api.displayName ?? ''}
                readOnly
            />
            <TextInput label={t('name')} placeholder={t('apiNamePlaceholder')} value={api.name} readOnly />
            <TextInput
                description={t('apiIdentifierDescription')}
                label={t('identifier')}
                placeholder={t('apiIdentifierPlaceholder')}
                value={api.id}
                readOnly
            />
            <Switch
                checked={api.includeGroupsInToken}
                description={t('includeGroupsInAccessTokenDescription')}
                label={t('includeGroupsInAccessToken')}
                labelPosition="left"
            />
        </Stack>
    );
};
