import { Anchor, AnchorProps, Group } from '@mantine/core';
import { Icon } from '@uag/react-core';
import { ReactNode } from 'react';

type Props = {
    withIcon?: boolean;
    href?: string;
    target?: string;
    rel?: string;
    children?: ReactNode;
} & AnchorProps;

export const Link = ({ withIcon, href, target, rel, children, ...rest }: Props) => (
    <Anchor href={href} rel={rel} target={target} onClick={(event) => event.stopPropagation()} {...rest}>
        <Group gap="xs">
            {children}
            {withIcon && <Icon icon="open_in_new" />}
        </Group>
    </Anchor>
);
// d
