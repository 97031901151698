import { toastNotifications } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCreateClient } from 'api/v3/application/application';
import { useUploadClientBackgroundImage, useUploadClientPicture } from 'api/v3/clients/clients';
import { DetailClientModel } from 'api/v3/models';
import { useApplication } from 'modules/applications/application/ApplicationProvider';
import { ClientFormValues } from 'modules/applications/client/ClientFormProvider';
import { ClientForm } from './ClientForm';
import { getCreateClientModel } from './mappings';

type Props = {
    initialValue?: DetailClientModel;
    onCancel: () => void;
};

export const CreateClient = ({ initialValue, onCancel: handleCancel }: Props) => {
    const { mutateAsync: createClientAsync } = useCreateClient();
    const { mutateAsync: uploadClientPicture } = useUploadClientPicture();
    const { mutateAsync: uploadClientBackgroundImage } = useUploadClientBackgroundImage();
    const { applicationId } = useApplication();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleSubmit = async (clientFormValues: ClientFormValues) => {
        const clientToCreate = getCreateClientModel(clientFormValues);

        const newClient = await createClientAsync({ id: applicationId, data: clientToCreate });
        try {
            if (clientFormValues.basicInformation.clientPictureUri && newClient) {
                const file = await fetch(clientFormValues.basicInformation.clientPictureUri).then((response) => response.blob());
                await uploadClientPicture({ id: newClient.data.basicInformation.id, data: { File: file } });
            }
            if (clientFormValues.basicInformation.clientBackgroundUri && newClient) {
                const file = await fetch(clientFormValues.basicInformation.clientBackgroundUri).then((response) => response.blob());
                await uploadClientBackgroundImage({ id: newClient.data.basicInformation.id, data: { File: file } });
            }
            navigate(`${newClient.data.basicInformation.id}`);
        } catch {
            toastNotifications.error({ message: t('clientPictureError') });
        }
    };

    return <ClientForm client={initialValue} onCancel={handleCancel} onSubmit={handleSubmit} />;
};
