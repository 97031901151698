import { Group, Title } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useTranslation } from 'react-i18next';

import { BasicUserFormType, DetailUserFormType } from '../UserFormProvider';
import { UserStateIndicator } from './UserStateIndicator';

type Props = {
    form: UseFormReturnType<BasicUserFormType> | UseFormReturnType<DetailUserFormType>;
};

export const UserBasicInformationHeader = ({ form }: Props) => {
    const { t } = useTranslation();
    return (
        <Group>
            <Title order={4}>{t('basicInformation')}</Title>
            <UserStateIndicator userState={form.values.basicInformation.state} />
        </Group>
    );
};
