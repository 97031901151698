import { ItemsPerPageType } from '@uag/react-core';

import { ApiResponseMetadata } from 'api/v3/models';

type PagedItem = {
    metadata?: ApiResponseMetadata | null;
};

export const getTotalPages = (itemsPerPage: ItemsPerPageType, searchValue?: string, searchUsers?: PagedItem, users?: PagedItem) => {
    const totalCount = searchValue ? searchUsers?.metadata?.paging?.totalCount : users?.metadata?.paging?.totalCount;
    return Math.ceil((totalCount ?? 1) / itemsPerPage);
};
