/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ProblemDetails,
  ResolvePrincipalsParams,
  ResolvePrincipalsResponse,
  SearchPrincipalsParams,
  SearchPrincipalsResponse
} from '.././models'
import { customMutator } from '../../customMutator-v3';
import type { ErrorType } from '../../customMutator-v3';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Resolves principal ids to its corresponding models<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.read<br>login.umdaschgroup.com/clients.read<br>login.umdaschgroup.com/groups.read<br>login.umdaschgroup.com/tenants.read
 */
export const resolvePrincipals = (
    params: ResolvePrincipalsParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<ResolvePrincipalsResponse>(
      {url: `/principals/resolve`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getResolvePrincipalsQueryKey = (params: ResolvePrincipalsParams,) => {
    return [`/principals/resolve`, ...(params ? [params]: [])] as const;
    }

    
export const getResolvePrincipalsQueryOptions = <TData = Awaited<ReturnType<typeof resolvePrincipals>>, TError = ErrorType<ProblemDetails>>(params: ResolvePrincipalsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof resolvePrincipals>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getResolvePrincipalsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof resolvePrincipals>>> = ({ signal }) => resolvePrincipals(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof resolvePrincipals>>, TError, TData> & { queryKey: QueryKey }
}

export type ResolvePrincipalsQueryResult = NonNullable<Awaited<ReturnType<typeof resolvePrincipals>>>
export type ResolvePrincipalsQueryError = ErrorType<ProblemDetails>


export function useResolvePrincipals<TData = Awaited<ReturnType<typeof resolvePrincipals>>, TError = ErrorType<ProblemDetails>>(
 params: ResolvePrincipalsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof resolvePrincipals>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof resolvePrincipals>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useResolvePrincipals<TData = Awaited<ReturnType<typeof resolvePrincipals>>, TError = ErrorType<ProblemDetails>>(
 params: ResolvePrincipalsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof resolvePrincipals>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof resolvePrincipals>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useResolvePrincipals<TData = Awaited<ReturnType<typeof resolvePrincipals>>, TError = ErrorType<ProblemDetails>>(
 params: ResolvePrincipalsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof resolvePrincipals>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useResolvePrincipals<TData = Awaited<ReturnType<typeof resolvePrincipals>>, TError = ErrorType<ProblemDetails>>(
 params: ResolvePrincipalsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof resolvePrincipals>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getResolvePrincipalsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Searches principals<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/users.read<br>login.umdaschgroup.com/clients.read<br>login.umdaschgroup.com/groups.read<br>login.umdaschgroup.com/tenants.read
 */
export const searchPrincipals = (
    params: SearchPrincipalsParams,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<SearchPrincipalsResponse>(
      {url: `/principals/search`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getSearchPrincipalsQueryKey = (params: SearchPrincipalsParams,) => {
    return [`/principals/search`, ...(params ? [params]: [])] as const;
    }

    
export const getSearchPrincipalsQueryOptions = <TData = Awaited<ReturnType<typeof searchPrincipals>>, TError = ErrorType<ProblemDetails>>(params: SearchPrincipalsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchPrincipals>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchPrincipalsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchPrincipals>>> = ({ signal }) => searchPrincipals(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchPrincipals>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchPrincipalsQueryResult = NonNullable<Awaited<ReturnType<typeof searchPrincipals>>>
export type SearchPrincipalsQueryError = ErrorType<ProblemDetails>


export function useSearchPrincipals<TData = Awaited<ReturnType<typeof searchPrincipals>>, TError = ErrorType<ProblemDetails>>(
 params: SearchPrincipalsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchPrincipals>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchPrincipals>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSearchPrincipals<TData = Awaited<ReturnType<typeof searchPrincipals>>, TError = ErrorType<ProblemDetails>>(
 params: SearchPrincipalsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchPrincipals>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchPrincipals>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useSearchPrincipals<TData = Awaited<ReturnType<typeof searchPrincipals>>, TError = ErrorType<ProblemDetails>>(
 params: SearchPrincipalsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchPrincipals>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useSearchPrincipals<TData = Awaited<ReturnType<typeof searchPrincipals>>, TError = ErrorType<ProblemDetails>>(
 params: SearchPrincipalsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchPrincipals>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getSearchPrincipalsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



