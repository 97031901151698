import { ReactNode } from 'react';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { BreadcrumbContext } from './BreadcrumbContext';

type Props = {
    breadcrumbs: BreadcrumbsRoute[];
    children: ReactNode;
};

export const BreadcrumbProvider = ({ children, breadcrumbs }: Props) => {
    return <BreadcrumbContext.Provider value={breadcrumbs}>{children}</BreadcrumbContext.Provider>;
};
