import { Text, Group } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { LoadingAnimation } from '@uag/react-core';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import { ErrorType } from 'api/customMutator-v3';
import { LogEventModel, ProblemDetails } from 'api/v3/models';
import { FieldDataType } from 'shared/components/extendedDataTable';
import { IFieldDescription } from 'shared/components/extendedDataTable/interfaces';
import { OverviewGrid } from 'shared/components/OverviewGrid';
import { getItemLink } from '../functions';
import { EventDataColumn } from './EventDataColumn';
import { addDays } from './helpers';

type Props = {
    isLoading: boolean;
    activities?: LogEventModel[];
    startDate: Date;
    endDate: Date;
    additionalFilters?: React.ReactNode;
    onStartDateChange: (startDate: Date) => void;
    onEndDateChange: (endDate: Date) => void;
    showUsernameColumn?: boolean;
    showClientColumn?: boolean;
    showTenantColumn?: boolean;
    error: ErrorType<ProblemDetails> | null;
};

const getEventDisplayName = (event: LogEventModel) => {
    let displayName = event.eventType
        .split('.')
        .pop() // get last part of the event type
        ?.split(/(?=[A-Z])/) // split by capital letters
        .join(' ') // join with space
        .toLowerCase()
        .replace(/^\w/, (c) => c.toUpperCase()) // capitalize first letter
        .replace(' event', '');

    switch (event.eventType.split('.').slice(4).join('.')) {
        case 'Users.UserLoginEvent':
            if (event.eventData && event.eventData.success !== undefined) {
                displayName += event.eventData.success ? ` ${t('successful')}` : ` ${t('failed')}`;
            }
    }

    return displayName;
};

export const isDateDisallowed = (date: Date): boolean => date > new Date() || date < addDays(new Date(), -30);

export const ActivityLogGrid = ({
    isLoading,
    activities,
    additionalFilters,
    onEndDateChange,
    onStartDateChange,
    startDate,
    endDate,
    showUsernameColumn = true,
    showClientColumn = true,
    showTenantColumn = true,
    error
}: Props) => {
    const { t } = useTranslation();

    function updateStartDate(date: Date): void {
        onStartDateChange(date);
        if (date >= endDate) {
            updateEndDate(addDays(date, 1));
        }
    }

    function updateEndDate(date: Date): void {
        onEndDateChange(date);
        if (date <= startDate) {
            onStartDateChange(addDays(date, -1));
        }
    }

    return (
        <Group>
            <Group align="apart" mb="sm">
                {additionalFilters}
            </Group>
            <Group align="apart" mb="sm">
                <DateTimePicker
                    excludeDate={isDateDisallowed}
                    label={t('startDate')}
                    value={startDate}
                    w={180}
                    onChange={(date) => date && updateStartDate(date)}
                />
                <DateTimePicker
                    excludeDate={(date) => isDateDisallowed(date) || date <= addDays(new Date(), -29)}
                    label={t('endDate')}
                    value={endDate}
                    w={180}
                    onChange={(date) => date && updateEndDate(date)}
                />
            </Group>
            {!isLoading ? (
                <ActivityLogGridInternal
                    activities={activities || []}
                    error={error}
                    showClientColumn={showClientColumn}
                    showTenantColumn={showTenantColumn}
                    showUsernameColumn={showUsernameColumn}
                />
            ) : (
                <LoadingAnimation />
            )}
        </Group>
    );
};

type ActivityLogGridInternalProps = {
    activities: LogEventModel[];
    showUsernameColumn?: boolean;
    showClientColumn?: boolean;
    showTenantColumn?: boolean;
    error: ErrorType<ProblemDetails> | null;
};

const ActivityLogGridInternal = ({ showUsernameColumn, showClientColumn, showTenantColumn, activities, error }: ActivityLogGridInternalProps) => {
    const { t } = useTranslation();

    const fieldDescriptions: IFieldDescription<LogEventModel>[] = [
        { header: t('date'), data: 'timeGenerated', width: 140, dataType: FieldDataType.DateTime },
        {
            header: t('eventType'),
            width: 165,
            data: 'eventType',
            render: (item) => <Text>{getEventDisplayName(item)}</Text>
        },
        { header: t('eventData'), width: 140, data: 'eventData', render: (item) => <EventDataColumn event={item} /> }
    ];

    if (showClientColumn) {
        fieldDescriptions.unshift({
            header: t('client'),
            data: 'clientName',
            link: (item) => (item.clientId ? getItemLink({ id: item.clientId, type: 'Client' }) : null)
        });
    }

    if (showTenantColumn) {
        fieldDescriptions.unshift({
            header: t('tenant'),
            data: 'identityTenantName',
            width: 200,
            link: (item) => (item.identityTenantId ? getItemLink({ id: item.identityTenantId, type: 'Tenant' }) : null)
        });
    }

    if (showUsernameColumn) {
        fieldDescriptions.unshift({
            header: t('username'),
            data: 'userName',
            minWidth: 250,
            link: (item) => (item.userId ? getItemLink({ id: item.userId, type: 'User' }) : null)
        });
    }
    return <OverviewGrid data={activities} error={error} fieldDescriptions={fieldDescriptions} withSearch={false} withSort />;
};
