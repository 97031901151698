import { useNavigate } from 'react-router-dom';

import { TenantLoginProviderType } from 'api/v3/models';
import { useCreateTenant, useUpdateLoginProvider, useUpdateTenantConfiguration } from 'api/v3/tenant/tenant';
import { getTenantConfigurationModel, getTenantModel } from './mappings';
import { TenantForm } from './TenantForm';
import { TenantFormType } from './TenantFormProvider';

type Props = {
    onCancel: () => void;
};

export const CreateTenant = ({ onCancel: handleCancel }: Props) => {
    const { mutateAsync: createTenantAsync } = useCreateTenant();
    const { mutateAsync: updateTenantConfiguration } = useUpdateTenantConfiguration();
    const { mutateAsync: updateLoginDomains } = useUpdateLoginProvider();

    const navigate = useNavigate();

    const handleSubmit = async (tenantFormValues: TenantFormType) => {
        const tenantToCreate = getTenantModel(tenantFormValues);
        const localLoginDomainsToUpdate = tenantFormValues.localLoginDomains.map(
            (localLoginDomain) => `${localLoginDomain}.grpid`
        );
        const tenantConfigurationToUpdate = getTenantConfigurationModel(tenantFormValues);

        const newTenant = await createTenantAsync({ data: tenantToCreate });
        await updateLoginDomains({
            id: newTenant.data.id,
            data: { loginProviderType: TenantLoginProviderType.Local, loginDomains: localLoginDomainsToUpdate }
        });
        await updateTenantConfiguration({ id: newTenant.data.id, data: tenantConfigurationToUpdate });

        navigate(`${newTenant.data.id}`);
    };

    return <TenantForm onCancel={handleCancel} onSubmit={handleSubmit} />;
};
