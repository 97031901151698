import { Route, Routes } from 'react-router-dom';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

import { useGetTenantById } from 'api/v3/tenant/tenant';
import { Tenant } from './Tenant';
import { Tenants } from './Tenants';

export const TenantBaseRoute = 'tenants';

export const TenantBreadcrumb = ({ match }: BreadcrumbComponentProps<'tenantId'>) => {
    const { data: tenant } = useGetTenantById(match.params.tenantId ?? '');
    return <>{tenant?.data.name ?? '...'}</>;
};

export const TenantRoutes = () => (
    <Routes>
        <Route element={<Tenants />} path="/" />
        <Route element={<Tenant />} path="/:tenantId" />
    </Routes>
);
