import { LoadingAnimation } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router';

import { useGetClientById } from 'api/v3/clients/clients';
import { ItemNotFound } from 'shared/components/ItemNotFound';

export const ClientRedirect = () => {
    const { t } = useTranslation();
    const { clientId = '' } = useParams();

    const { data: client, error, isLoading: isClientLoading } = useGetClientById(clientId);

    if (error) {
        return <ItemNotFound errorStatus={error.response?.status} goBackRoute="/" itemId={clientId} itemType={t('client')} />;
    }

    if (!client || isClientLoading) {
        return <LoadingAnimation />;
    }

    return <Navigate to={`/applications/${client.data.applicationId}/clients/${clientId}`} />;
};
