/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */

export type GroupType = typeof GroupType[keyof typeof GroupType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupType = {
  Default: 'Default',
  DynamicTenantGroup: 'DynamicTenantGroup',
} as const;
