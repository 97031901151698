import { randomId } from '@mantine/hooks';

import {
    AssignmentPrincipalType,
    GetRoleAssignmentResponseItem,
    PrincipalType,
    RoleAssignmentContextType
} from 'api/v3/models';
import { getAssignmentTypeForPrincipalType } from '../PrincipalSelector';
import { RoleAssignmentsFormType } from './RoleAssignmentsFormProvider';

export const getFormRoleAssignments = (
    roleAssignments: GetRoleAssignmentResponseItem[],
    contextAssignmentType: RoleAssignmentContextType,
    contextId?: string
): RoleAssignmentsFormType => {
    return {
        roleAssignments: roleAssignments.map((roleAssignment) => ({
            id: randomId(),
            ...roleAssignment,
            isNew: false,
            principal: {
                ...roleAssignment.principal,
                assignmentType: getAssignmentTypeForPrincipalType(roleAssignment.principal.principalType)
            },
            context: {
                contextAssignmentType,
                contextId
            },
            key: randomId(),
            isEditable: false,
            isRemoved: false
        }))
    };
};

export const newAssignment = (contextId?: string, contextType?: RoleAssignmentContextType) => ({
    isNew: true,
    principal: {
        id: '',
        principalType: PrincipalType.User,
        assignmentType: AssignmentPrincipalType.UserId,
        displayName: '',
        email: ''
    },
    role: {
        roleId: '',
        roleIdentifier: '',
        roleDisplayName: ''
    },
    context: {
        contextAssignmentType: contextType ?? RoleAssignmentContextType.User,
        contextId: contextId ?? null
    },
    key: randomId(),
    isEditable: true,
    isRemoved: false
});
