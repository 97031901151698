import { ActionIcon, Button, Menu, Tooltip } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { Icon } from '@uag/react-core';
import { MouseEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ClientsUsingScopeModal } from 'modules/applications/api/components/ClientsUsingScopeModal';
import { usePermissions } from 'modules/shared/permissions';
import { DetailFormChildProps, DetailFormVariant } from 'shared/components/detailForm';
import { ExtendedDataTable, RowActionVariant } from 'shared/components/extendedDataTable';
import { Placeholder } from 'shared/components/placeholder';
import { useApiFormContext, ScopesFormType } from '../ApiFormProvider';
import scopesEmpty from '../assets/scopes_empty.svg';

const generateNewScope = () =>
    ({
        key: randomId(),
        displayName: '',
        name: '',
        description: '',
        enabled: true,
        isEditable: true
    }) as ScopesFormType;

const AddScope = () => {
    const form = useApiFormContext();

    return (
        <Button
            disabled={form.values.scopes.some((item) => item.isEditable)}
            leftSection={<Icon icon="add" />}
            onClick={() => form.insertListItem('scopes', generateNewScope())}
        >
            Add Scope
        </Button>
    );
};

const NoScopesPlaceholder = () => {
    const { t } = useTranslation();

    return <Placeholder action={<AddScope />} description={t('noScopesDescription')} picture={scopesEmpty} text={t('noScopes')} />;
};

type Props = DetailFormChildProps;

export const Scopes = ({ variant }: Props) => {
    const { t } = useTranslation();
    const form = useApiFormContext();
    const [showClientsUsingScopeId, setShowClientsUsingScopeId] = useState<string>();
    const canCreate = usePermissions('Create', 'ApiResourceScope');
    const canEdit = usePermissions(['Update', 'MaintainScope'], 'ApiResourceScope');
    const canDelete = usePermissions('Delete', 'ApiResourceScope');

    const ShowClientsUsingScopeAction = (scope: ScopesFormType, index: number, rowActionVariant: RowActionVariant) => {
        if (scope.isEditable || !scope.id || scope.isRemoved || variant === DetailFormVariant.CREATE) {
            return null;
        }

        const handleShowClientsUsingScope = async (event: MouseEvent<HTMLButtonElement>) => {
            setShowClientsUsingScopeId(scope.id);
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        };

        return rowActionVariant === RowActionVariant.DEFAULT ? (
            <Tooltip label={t('showClientsUsingScope')}>
                <ActionIcon onClick={handleShowClientsUsingScope}>
                    <Icon icon="network_node" />
                </ActionIcon>
            </Tooltip>
        ) : (
            <Menu.Item leftSection={<Icon icon="network_node" />} onClick={handleShowClientsUsingScope}>
                {t('showClientsUsingScope')}
            </Menu.Item>
        );
    };

    return (
        <>
            <ExtendedDataTable
                additionalRowActions={[ShowClientsUsingScopeAction]}
                canCreate={canCreate}
                canEdit={canEdit}
                canRemove={canDelete}
                data={form.values.scopes}
                fieldDescriptions={[
                    {
                        header: t('name'),
                        prefix: (item) => (!item.id ? `${form.values.api.name}/` : null),
                        description: <Trans i18nKey="scopeRoleNameDescription" />,
                        placeholder: 'users.read',
                        data: 'name',
                        lockUpdate: true,
                        width: 400
                    },
                    { header: t('displayName'), data: 'displayName' },
                    { header: t('description'), data: 'description' }
                ]}
                form={form}
                generateNewItem={generateNewScope}
                placeholder={<NoScopesPlaceholder />}
                propertyPathBase="scopes"
            />
            {showClientsUsingScopeId && (
                <ClientsUsingScopeModal scopeId={showClientsUsingScopeId} onClose={() => setShowClientsUsingScopeId(undefined)} />
            )}
        </>
    );
};
