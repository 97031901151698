import { withOidcSecure } from '@axa-fr/react-oidc';
import { Route, Routes } from 'react-router-dom';

import { ApplicationRoutes, ApplicationBaseRoute } from 'modules/applications/Routes';
import { UserManagementBaseRoute, UserManagementRoutes } from 'modules/userManagement/Routes';
import { Home } from './Home';
import { NotFound } from './NotFound';
import { Unauthorized } from './Unauthorized';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route Component={withOidcSecure(Home)} path="/" />
            <Route Component={withOidcSecure(ApplicationRoutes)} path={`/${ApplicationBaseRoute}/*`} />
            <Route Component={withOidcSecure(UserManagementRoutes)} path={`/${UserManagementBaseRoute}/*`} />
            <Route Component={Unauthorized} path="/unauthorized" />
            <Route Component={NotFound} path="*" />
        </Routes>
    );
};
