import { Title, ActionIcon, Tooltip } from '@mantine/core';
import { Icon, LoadingAnimation } from '@uag/react-core';
import React, { ReactNode, createContext, Children, useContext, PropsWithChildren, isValidElement, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { CardBase, CardDimensions } from './CardBase';
import { CreateItemModal } from './CreateItemModal';
import { ErrorNotification } from './errorHandling';
import { DefaultPlaceholder } from './placeholder';

export type AddDialogProps = {
    onCancel: () => void;
    Title?: ReactNode;
};

const CardDimensionContext = createContext<CardDimensions | null>(null);

const CardDimensionContextProvider = ({ width, children }: CardDimensions & PropsWithChildren) => {
    return <CardDimensionContext.Provider value={{ width }}>{children}</CardDimensionContext.Provider>;
};

const AddItemButton = ({ onClick }: { onClick: () => void }) => {
    const cardDimensionContext = useContext(CardDimensionContext);

    const styles: CSSProperties = {
        height: 'inherit'
    };

    return (
        <CardBase className="h-full flex place-content-center" style={styles} width={cardDimensionContext?.width} onClick={() => onClick()}>
            <Icon className="m-auto scale-150" color="lightgray" icon="add" size="5xl" />
        </CardBase>
    );
};

type Props = {
    isLoading: boolean;
    header?: string | undefined;
    cardHeight?: number | undefined;
    cardWidth?: number | undefined;
    AddDialogType?: (props: AddDialogProps) => ReactNode;
    addDialogTitle?: ReactNode;
    children: ReactNode;
    onClearFilters?: () => Promise<void> | void;
    areFiltersSet?: boolean;
    placeholder?: React.ReactElement;
    error: unknown;
};

type FilterProps = {
    children: ReactNode;
};

export const Filter = ({ children }: FilterProps) => {
    return children;
};

Filter.DisplayName = 'FlexListFilter';

export const FlexList = ({
    children,
    header,
    cardWidth,
    AddDialogType,
    addDialogTitle,
    isLoading = false,
    onClearFilters: handleClearFilters,
    areFiltersSet = false,
    placeholder: noData = <DefaultPlaceholder />,
    error
}: Props) => {
    const { t } = useTranslation();

    const addDialogTitleNode = typeof addDialogTitle === 'string' ? <>{addDialogTitle}</> : addDialogTitle;
    const childArray = Children.toArray(children);

    const filters = childArray.filter(
        (child) =>
            isValidElement(child) && typeof child.type === 'function' && 'DisplayName' in child.type && child.type.DisplayName === Filter.DisplayName
    );
    const otherItems = childArray.filter((child) => !filters?.some((filter) => filter === child));

    if (filters.length !== 0 && !handleClearFilters) {
        throw new Error('onClearFilters has to be implemented when filters are set.');
    }

    return (
        <>
            <Title order={3}>{header}</Title>
            {filters && filters.length !== 0 && (
                <div className="flex flex-row items-center my-lg gap-base">
                    <Icon icon="filter_alt" size="3xl" />
                    {filters}
                    <Tooltip label={t('clearFilter')}>
                        <ActionIcon disabled={!areFiltersSet} onClick={handleClearFilters}>
                            <Icon icon="close" size="3xl" />
                        </ActionIcon>
                    </Tooltip>
                </div>
            )}
            <ErrorNotification error={error} mb="sm" mt="sm" title={t('loadingItems')} />
            {isLoading && <LoadingAnimation />}
            {!isLoading && (
                <div>
                    {otherItems.length <= 0 ? (
                        noData
                    ) : (
                        <div className="flex flex-row flex-wrap gap-lg">
                            {otherItems}
                            {AddDialogType && (
                                <>
                                    <CardDimensionContextProvider width={cardWidth}>
                                        <CreateItemModal AddButton={AddItemButton} AddDialogType={AddDialogType} title={addDialogTitleNode} />
                                    </CardDimensionContextProvider>
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

FlexList.Filter = Filter;
