import { LoadingAnimation } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetApplications } from 'api/v3/application/application';
import { BasicClientModel } from 'api/v3/models';
import { PermissionUtils, usePermissions } from 'modules/shared/permissions';
import { MoveModal } from 'shared/components';

type Props = {
    client: BasicClientModel;
    opened: boolean;
    onConfirm: (applicationId: string) => Promise<void>;
    onCancel: () => void;
};

export const MoveClientModal = ({ opened, client, onCancel: handleCancel, onConfirm }: Props) => {
    const { t } = useTranslation();
    const { data } = useGetApplications({ includePermissions: true });
    const navigate = useNavigate();
    const canCreate = usePermissions('Create');
    if (!data) {
        return <LoadingAnimation />;
    }

    const handleOnConfirm = async (selectedApplicationId: string) => {
        await onConfirm(selectedApplicationId);
        navigate(`/applications/${selectedApplicationId}/clients`);
    };

    const applications =
        data.data
            ?.filter(
                (application) =>
                    application.data.id !== client.applicationId &&
                    PermissionUtils.hasPermission('Client', 'Create', {
                        metadata: application.metadata
                    })
            )
            .map((application) => ({
                value: application.data.id,
                label: application.data.displayName ?? application.data.id
            })) ?? [];

    return (
        canCreate && (
            <MoveModal
                data={applications}
                opened={opened}
                text={t('targetApplication')}
                title={t('moveClient')}
                onCancel={handleCancel}
                onConfirm={handleOnConfirm}
            />
        )
    );
};
