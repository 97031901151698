import { Button } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { Icon } from '@uag/react-core';
import { Trans, useTranslation } from 'react-i18next';

import { BasicClientModel } from 'api/v3/models';
import { ClientSelector } from 'modules/shared/ClientSelector';
import { usePermissions } from 'modules/shared/permissions';
import { DetailFormChildProps } from 'shared/components/detailForm';
import { ExtendedDataTable } from 'shared/components/extendedDataTable';
import { Placeholder } from 'shared/components/placeholder';
import { useApiFormContext, TokenExchangeClientsFormType } from '../ApiFormProvider';
import scopesEmpty from '../assets/scopes_empty.svg';

const generateTokenExchangeClient = () =>
    ({
        key: randomId(),
        enabled: true,
        isEditable: true,
        isRemoved: false,
        isNew: true
    }) as TokenExchangeClientsFormType;

const AddTokenExchangeClient = () => {
    const form = useApiFormContext();
    const { t } = useTranslation();

    return (
        <Button
            disabled={form.values.tokenExchangeClients.some((item) => item.isEditable)}
            leftSection={<Icon icon="add" />}
            onClick={() => form.insertListItem('tokenExchangeClients', generateTokenExchangeClient())}
        >
            {t('addTokenExchangeClient')}
        </Button>
    );
};

const NoTokenExchangeClients = () => {
    const { t } = useTranslation();

    return (
        <Placeholder
            action={<AddTokenExchangeClient />}
            description={<Trans i18nKey="noTokenExchangeClientsDescription" />}
            picture={scopesEmpty}
            text={t('noTokenExchangeClients')}
        />
    );
};

type Props = DetailFormChildProps;

export const TokenExchangeClients = (_: Props) => {
    const { t } = useTranslation();
    const form = useApiFormContext();
    const canCreateOrDelete = usePermissions('Update');

    const handleClientSelected = (selectedPrincipal: BasicClientModel, index: number) => {
        form.setFieldValue(`tokenExchangeClients.${index}.isEditable`, false);
        form.setFieldValue(`tokenExchangeClients.${index}.id`, selectedPrincipal.id);
        form.setFieldValue(`tokenExchangeClients.${index}.name`, selectedPrincipal.name);
    };

    return (
        <>
            <ExtendedDataTable
                canCreate={canCreateOrDelete}
                canEdit={false}
                canRemove={canCreateOrDelete}
                data={form.values.tokenExchangeClients}
                fieldDescriptions={[
                    {
                        header: t('name'),
                        data: 'name',
                        lockUpdate: true
                    }
                ]}
                form={form}
                generateNewItem={generateTokenExchangeClient}
                placeholder={<NoTokenExchangeClients />}
                propertyPathBase="tokenExchangeClients"
                renderEdit={(_item, index) => {
                    return (
                        <ClientSelector
                            form={form}
                            index={index}
                            propertyBasePath={'tokenExchangeClients'}
                            tokenExchangeClientsOnly
                            onClientSelected={canCreateOrDelete ? (client) => handleClientSelected(client, index) : undefined}
                        />
                    );
                }}
            />
        </>
    );
};
