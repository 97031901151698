import { ReactNode } from 'react';

export interface IRowData {
    key: string;
    id?: string;
    isEditable: boolean;
    isRemoved: boolean;
}

export enum FieldDataType {
    Text = 'Text',
    Date = 'Date',
    DateTime = 'DateTime',
    Number = 'Number'
}

export type IFieldDescription<TRowType> = {
    header: ReactNode;
    description?: ReactNode;
    prefix?: ReactNode | ((item: TRowType) => ReactNode);
    dataType?: FieldDataType;
    placeholder?: string;
    data: string;
    lockUpdate?: boolean;
    render?: (item: TRowType) => ReactNode;
    link?: (item: TRowType) => string | null;
    width?: string | number;
    minWidth?: string | number;
};
