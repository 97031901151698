import { UseFormReturnType } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import { BasicGroupModel, PrincipalType } from 'api/v3/models';
import { getItemLink } from 'modules/shared/functions';
import { GroupSelector } from 'modules/shared/GroupSelector';
import { usePermissions } from 'modules/shared/permissions';
import { DetailFormChildProps } from 'shared/components/detailForm';
import { ExtendedDataTable } from 'shared/components/extendedDataTable';
import { BasicUserFormType, DetailUserFormType } from '../UserFormProvider';

type Props = {
    form: UseFormReturnType<BasicUserFormType> | UseFormReturnType<DetailUserFormType>;
} & DetailFormChildProps;

const handleGroupSelected = (
    form: UseFormReturnType<BasicUserFormType> | UseFormReturnType<DetailUserFormType>,
    role: BasicGroupModel,
    index: number
) => {
    form.setFieldValue(`groups.${index}.groupId`, role.id);
    form.setFieldValue(`groups.${index}.isEditable`, false);
    form.setFieldValue(`groups.${index}.name`, role.name);
    form.setFieldValue(`groups.${index}.groupType`, role.groupType);
    form.setFieldValue(`groups.${index}.description`, role.description);
};

const newAssignment = <DetailUserDataGridGroupsType,>() =>
    ({
        name: '',
        key: randomId(),
        isEditable: true,
        isRemoved: false
    }) as DetailUserDataGridGroupsType;

export const Groups = ({ form }: Props) => {
    const { t } = useTranslation();
    const canAssignGroup = usePermissions('Update');

    return (
        <ExtendedDataTable
            canCreate={canAssignGroup}
            canEdit={canAssignGroup}
            canRemove={canAssignGroup}
            canRemoveItem={(item) => item.groupType !== 'DynamicTenantGroup'}
            data={form.values.groups}
            fieldDescriptions={[
                { header: t('name'), data: 'name', lockUpdate: true, link: (item) => getItemLink({ id: item.groupId, type: PrincipalType.Group }) },
                {
                    header: t('description'),
                    data: 'description',
                    lockUpdate: true
                }
            ]}
            form={form}
            generateNewItem={newAssignment}
            propertyPathBase="groups"
            renderEdit={(_item, index, onItemSaved) => {
                return (
                    <GroupSelector
                        form={form}
                        index={index}
                        propertyBasePath="groups"
                        onGroupSelected={(role) => {
                            handleGroupSelected(form, role, index);
                            onItemSaved && onItemSaved();
                        }}
                    />
                );
            }}
        />
    );
};
