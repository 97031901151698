import { randomId } from '@mantine/hooks';

import { PrincipalModel, PrincipalType, AssignmentPrincipalType } from 'api/v3/models';
import { getAssignmentTypeForPrincipalType } from '../PrincipalSelector';
import { PrincipalFormType } from './types';

export const generateNewPrincipalAssignment = () => ({
    key: randomId(),
    name: '',
    isEditable: true,
    isRemoved: false,
    principalType: PrincipalType.User,
    assignmentType: AssignmentPrincipalType.UserId,
    displayName: '',
    principalId: ''
});

export function getFormPrincipals(principals: PrincipalModel[]): PrincipalFormType[] {
    return principals
        ? principals.map((principal) => ({
              key: randomId(),
              isEditable: false,
              isRemoved: false,
              principalId: principal.id,
              id: principal.id,
              principalType: principal.principalType,
              assignmentType: getAssignmentTypeForPrincipalType(principal.principalType),
              email: principal.email ?? undefined,
              displayName:
                  principal.displayName || principal.email
                      ? `${principal.displayName}${principal.email ? ` (${principal.email})` : ''}`
                      : principal.id
          }))
        : [];
}
