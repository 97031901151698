import { Flex, Text } from '@mantine/core';
import _ from 'lodash';
import { ReactNode } from 'react';
import { FormattedDate } from 'react-intl';

import { Link } from 'shared/Link';
import { FieldDataType, IFieldDescription } from './interfaces';

type Props<TRowType> = {
    item: TRowType;
    fieldDescription: IFieldDescription<TRowType>;
    prefix: ReactNode;
};

export const DisplayFieldRenderer = <TRowType,>({ fieldDescription, prefix, item }: Props<TRowType>) => {
    const fieldValue = _.get(item, fieldDescription.data);

    switch (fieldDescription.dataType) {
        case FieldDataType.DateTime:
            return (
                <FormattedDate
                    day="numeric"
                    hour="numeric"
                    minute="numeric"
                    month="numeric"
                    value={new Date(`${fieldValue}`)}
                    year="numeric"
                />
            );
    }

    const renderedValue = fieldDescription.render ? fieldDescription.render(item) : <Text>{fieldValue}</Text>;
    const linkValue = fieldDescription.link && fieldDescription.link(item);

    return linkValue ? (
        <Link href={linkValue} target="_blank" withIcon>
            {prefix}
            {renderedValue}
        </Link>
    ) : (
        <Flex>
            {prefix}
            {renderedValue}
        </Flex>
    );
};
