import { Notification, Title } from '@mantine/core';
import { variants } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { DetailGroupModel } from 'api/v3/models';
import { TranslationKeys } from 'i18n';

type Props = {
    name: TranslationKeys;
    data?: DetailGroupModel;
};

export const GroupFormChildHeader = ({ name, data }: Props) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-row justify-between text-nowrap gap-xl">
            <Title order={5}>{t(name)}</Title>
            {data?.groupType === 'DynamicTenantGroup' && (
                <Notification variant={variants.notification.inlineNotificationWarning} withCloseButton={false}>
                    {t('dynamicTenantGroupDescription')}
                </Notification>
            )}
        </div>
    );
};
