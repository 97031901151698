import { Flex, Text, Button, Group, Modal, Notification, Code } from '@mantine/core';
import { Icon, Select } from '@uag/react-core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type MoveModalDataType = {
    value: string;
    label: string;
};

type Props = {
    opened: boolean;
    title: string;
    text: string;
    onConfirm: (applicationId: string) => Promise<void>;
    onCancel: () => void;
    data: MoveModalDataType[];
};

export const MoveModal = ({ opened, title, text, onCancel, onConfirm, data }: Props) => {
    const { t } = useTranslation();
    const [selectedTargetId, setSelectedTargetId] = useState<string | null>(null);
    const [isBusy, setIsBusy] = useState(false);
    const [error, setError] = useState<string | object | undefined>();

    const handleOnConfirm = async () => {
        setError(undefined);
        if (!selectedTargetId) {
            return;
        }
        setIsBusy(true);
        try {
            await onConfirm(selectedTargetId);
        } catch (err: unknown | AxiosError) {
            if (axios.isAxiosError(err)) {
                setError(err.response?.data);
            }
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <Modal opened={opened} size="md" title={title} centered onClick={(event) => event.stopPropagation()} onClose={() => onCancel()}>
            <Flex className="gap-base items-center flex flex-row">
                <Text>{text}</Text>
                <Select
                    data={data}
                    placeholder=""
                    style={{ flexGrow: 1 }}
                    value={selectedTargetId}
                    onChange={(value) => setSelectedTargetId(value)}
                />
            </Flex>

            <Group className="mt-base" justify="flex-end">
                <Button variant="outline" onClick={() => onCancel()}>
                    {t('cancel')}
                </Button>
                <Button disabled={!selectedTargetId} loading={isBusy} onClick={handleOnConfirm}>
                    {t('ok')}
                </Button>
            </Group>
            {!!error && (
                <Notification color="red" icon={<Icon icon="close" />} mt="sm" styles={{ root: { boxShadow: 'none' } }} withCloseButton={false}>
                    {typeof error === 'string' && error}
                    {typeof error === 'object' && (
                        <Code mt="xl" block>
                            {JSON.stringify(error, null, 2)}
                        </Code>
                    )}
                </Notification>
            )}
        </Modal>
    );
};
