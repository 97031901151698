import { Route, Routes } from 'react-router-dom';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

import { useGetClientById } from 'api/v3/clients/clients';
import { Clients } from './Clients';
import { EditClient } from './EditClient';

export const ClientBaseRoute = 'clients';

export const ClientBreadcrumb = ({ match }: BreadcrumbComponentProps<'clientId'>) => {
    const { data: client } = useGetClientById(match.params.clientId ?? '');
    return <>{client?.data.name ?? '...'}</>;
};

export const ClientRoutes = () => (
    <Routes>
        <Route element={<Clients />} path="/" />
        <Route element={<EditClient />} path="/:clientId" />
    </Routes>
);
