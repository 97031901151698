import { ActionIcon, Menu, Table } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { Icon } from '@uag/react-core';
import { clsx } from 'clsx';
import { useContext, MouseEvent, ReactNode, useEffect, Fragment, useRef } from 'react';

import { Cell } from './Cell';
import { IRowData, IFieldDescription } from './interfaces';
import classes from './Row.module.css';
import { SelectionContext } from './SelectionContextProvider';

export enum RowActionVariant {
    DEFAULT = 0,
    COMPACT = 1
}

function handleRowSelected<TRowType extends IRowData>(item: TRowType, onToggleRow: (rowKey: string) => void, selectionEnabled: boolean) {
    if (selectionEnabled && !item.isEditable) {
        onToggleRow(item.key);
    }
}

function handleRowClicked<TRowType extends IRowData>(
    item: TRowType,
    onToggleRow: (rowKey: string) => void,
    selectionEnabled: boolean,
    event: MouseEvent,
    onRowClicked?: (event: MouseEvent, item: TRowType) => void
) {
    onRowClicked && onRowClicked(event, item);

    handleRowSelected(item, onToggleRow, selectionEnabled);
}

type Props<TRowType extends IRowData> = {
    item: TRowType;
    index: number;
    onToggleRow: (rowKey: string) => void;
    rowActions?: ((value: TRowType, index: number, variant: RowActionVariant) => ReactNode)[];
    fieldDescriptions: IFieldDescription<TRowType>[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form?: UseFormReturnType<any, any>;
    propertyPathBase?: string;
    renderEdit?: (value: TRowType, index: number, onRowSaved?: () => void) => ReactNode;
    onRowClicked?: (event: MouseEvent, item: TRowType) => void;
    getRowClass?: (item: TRowType) => string | undefined;
    onRowSaved?: () => void;
};

export const Row = <TRowType extends IRowData>({
    item,
    index,
    onToggleRow,
    fieldDescriptions,
    form,
    propertyPathBase,
    rowActions,
    renderEdit,
    onRowClicked,
    onRowSaved,
    getRowClass
}: Props<TRowType>) => {
    const { selectedItemKeys, selectionEnabled } = useContext(SelectionContext);
    const rowRef = useRef<HTMLTableRowElement>(null);

    let renderedRowActions = rowActions?.map((rowAction) => rowAction(item, index, RowActionVariant.DEFAULT)).filter(Boolean);

    if (!!renderedRowActions && renderedRowActions?.length > 4) {
        /* Unfortunately we only know if we need the compact variant if we render all actions and see if there are more than 4 */
        renderedRowActions = rowActions?.map((rowAction) => rowAction(item, index, RowActionVariant.COMPACT)).filter(Boolean);
    }

    useEffect(() => {
        const current = rowRef.current;

        // Scroll to bottom on newly created items
        if (current && !item.id && item.isEditable) {
            setTimeout(() => {
                current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 100);
        }
        // Effect should only appear once the row is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Table.Tr
            key={index}
            ref={rowRef}
            className={clsx(getRowClass && getRowClass(item), classes.row, {
                [classes.rowPointer]: selectionEnabled || !!onRowClicked,
                ['data-table-row-selectable']: selectionEnabled
            })}
            data-selected={selectedItemKeys.includes(item.key)}
            onClick={(event) => handleRowClicked(item, onToggleRow, selectionEnabled, event, onRowClicked)}
        >
            {selectionEnabled && (
                <Table.Td>
                    <input
                        checked={selectedItemKeys.includes(item.key)}
                        className="data-table-select-checkbox"
                        readOnly={true}
                        type="checkbox"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleRowSelected(item, onToggleRow, selectionEnabled);
                        }}
                    />
                </Table.Td>
            )}

            {renderEdit && item.isEditable && <Table.Td colSpan={fieldDescriptions.length}>{renderEdit(item, index, onRowSaved)}</Table.Td>}

            {(!renderEdit || !item.isEditable) &&
                fieldDescriptions.map((fieldDescription, fieldDescriptionIndex) => (
                    <Cell
                        key={fieldDescription.header + item.key}
                        autoFocus={fieldDescriptionIndex === 0}
                        fieldDescription={fieldDescription}
                        form={form}
                        index={index}
                        item={item}
                        propertyPathBase={propertyPathBase}
                    />
                ))}
            {!!renderedRowActions && (
                <Table.Td className={classes.rowActionsColumn}>
                    <div
                        className="data-table-cell-content gap-base"
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                    >
                        {renderedRowActions.length > 4 && (
                            <Menu position="bottom-end" transitionProps={{ transition: 'pop-bottom-right' }} keepMounted withinPortal>
                                <Menu.Target>
                                    {'rowAction'}
                                    <ActionIcon
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            event.nativeEvent.stopPropagation();
                                        }}
                                    >
                                        <Icon icon="more_vert" />
                                    </ActionIcon>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    {renderedRowActions?.map((rowAction, index) => <Fragment key={index}>{rowAction}</Fragment>)}
                                </Menu.Dropdown>
                            </Menu>
                        )}
                        {renderedRowActions.length <= 4 &&
                            renderedRowActions.map((rowAction, rowActionIndex) => <div key={rowActionIndex}>{rowAction}</div>)}
                    </div>
                </Table.Td>
            )}
        </Table.Tr>
    );
};
