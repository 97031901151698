import { Group, Select } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSearchGroups } from 'api/v3/groups/groups';
import { BasicGroupModel, RoleAssignmentContextType } from 'api/v3/models';
import { PermissionUtils } from './permissions';

type Props = {
    index: number;
    propertyBasePath: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturnType<any, any>;
    requiredPermission?: string;
    onGroupSelected?: (group: BasicGroupModel) => void;
};

export const GroupSelector = ({ index, form, propertyBasePath, onGroupSelected, requiredPermission = 'AddRemoveMembers' }: Props) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState<string>();

    const { data: groups } = useSearchGroups(
        { searchTerm: search, includePermissions: true },
        {
            query: { enabled: !!search }
        }
    );

    const handleGroupSelected = (value: string | null) => {
        if (value) {
            const foundGroup = groups?.data.find((group) => group.data.id === value);
            if (foundGroup) {
                onGroupSelected && onGroupSelected(foundGroup.data);
            }
        }
    };

    const groupOptions = groups
        ? groups.data
              .filter(
                  (group) =>
                      group.data.groupType !== 'DynamicTenantGroup' &&
                      (!requiredPermission || PermissionUtils.hasPermission(RoleAssignmentContextType.Group, requiredPermission, group))
              )
              .map((group) => {
                  return {
                      value: group.data.id,
                      label: group.data.name
                  };
              })
        : [];

    return (
        <Group grow>
            <Select
                data={groupOptions}
                error={form.errors[`${propertyBasePath}.${index}.name`]}
                nothingFoundMessage={t('noOptions')}
                placeholder={t('selectItem', { itemType: t('group') })}
                clearable
                searchable
                onChange={handleGroupSelected}
                onSearchChange={setSearch}
            />
        </Group>
    );
};
