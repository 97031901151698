import { Card, CardProps } from '@mantine/core';
import { clsx } from 'clsx';
import { MouseEventHandler, ReactNode } from 'react';
import { To } from 'react-router-dom';

export type CardDimensions = {
    width?: number | undefined;
};

type CardBaseProps = {
    children?: ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component?: any;
    to?: To;
    className?: string;
    onClick?: MouseEventHandler<typeof CardBase> | undefined;
} & CardDimensions &
    CardProps;

export const CardBase = (props: CardBaseProps) => {
    return (
        <Card {...props} className={clsx('h-full min-h-44 w-88  p-xl relative hover:card-clickable')}>
            <div className={clsx('w-full h-full', props.className)}>{props.children}</div>
        </Card>
    );
};
