import { Group, Text } from '@mantine/core';
import { ReactNode } from 'react';

import { IconDescription } from '../IconDescription';

export type InputLabelProps = {
    label?: ReactNode;
    optional?: string;
    iconDescription?: string;
};

export const InputLabel = ({ label, optional, iconDescription }: InputLabelProps) => {
    return (
        <Group gap={5}>
            {label && (
                <Text c="gray.9" fw={500} size="sm">
                    {label}
                </Text>
            )}
            {optional && (
                <Text c="gray.3" w={400}>
                    ({optional})
                </Text>
            )}
            {iconDescription && <IconDescription description={iconDescription} />}
        </Group>
    );
};
