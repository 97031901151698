import { Image, Text } from '@mantine/core';

import { useDeleteApplication } from 'api/v3/application/application';
import { BasicApplicationModel, BasicApplicationModelApiResponse } from 'api/v3/models';
import { usePermissions } from 'modules/shared/permissions';
import { ShowRoleAssignmentsAction } from 'modules/shared/roleAssignments/ShowRoleAssignmentsAction';
import { ItemCard } from 'shared/components';
import { ItemCardPropsBase } from 'shared/components/ItemCard';

type Props = ItemCardPropsBase<BasicApplicationModelApiResponse>;

type ApplicationCardContentProps = {
    application: BasicApplicationModel;
};

const ApplicationCardContent = ({ application }: ApplicationCardContentProps) => {
    return (
        <>
            <div className="self-center" style={{ marginBottom: '15px' }}>
                <Image fit="cover" height={100} src={application.imageUri} width={100} />
            </div>

            <Text fw={500} lineClamp={1} size="lg">
                {application.displayName}
            </Text>

            <div className="text-sm text-text-ultra-light line-clamp-3">{application.description}</div>
        </>
    );
};
export const ApplicationCard = ({ item: applicationApiResponse, onItemDelete: onItemDeleted }: Props) => {
    const { mutateAsync: deleteApplicationMutationAsync } = useDeleteApplication();

    const canDelete = usePermissions('Delete');

    const handleDeleteApplication = async () => {
        await deleteApplicationMutationAsync({ id: applicationApiResponse.data.id });
        onItemDeleted();
    };

    return (
        <ItemCard
            actions={[
                <ShowRoleAssignmentsAction
                    key="showRoleAssignments"
                    contextId={applicationApiResponse.data.id}
                    contextType="Application"
                    itemName={applicationApiResponse.data.displayName}
                />
            ]}
            deleteConfirmation={applicationApiResponse.data.displayName}
            itemType="application"
            name={applicationApiResponse.data.displayName}
            to={`${applicationApiResponse.data.id}`}
            onDelete={canDelete ? handleDeleteApplication : undefined}
        >
            <ApplicationCardContent application={applicationApiResponse.data} />
        </ItemCard>
    );
};
