import { Tooltip } from '@mantine/core';
import { Icon } from '@uag/react-core';

type Props = {
    description: string;
};

export const IconDescription = ({ description }: Props) => {
    return (
        <Tooltip label={description}>
            <Icon color="var(--mantine-color-gray-5" icon="info" size="2xl" style={{ cursor: 'pointer' }} />
        </Tooltip>
    );
};
