import { Grid } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { useUploadApplicationImage, useCreateApplication } from 'api/v3/application/application';
import { DetailFormVariant } from 'shared/components/detailForm';
import { AddDialogProps } from 'shared/components/FlexList';
import { ApplicationForm } from './ApplicationForm';
import { ApplicationFormValues } from './ApplicationFormProvider';
import { getApplicationModel } from './mappings';

export const ApplicationCreate = ({ onCancel: handleOnCancel }: AddDialogProps) => {
    const navigate = useNavigate();

    const { mutateAsync: createApplicationAsync } = useCreateApplication();
    const { mutateAsync: uploadImageAsync } = useUploadApplicationImage();

    const handleCreate = async (savedApplication: ApplicationFormValues) => {
        const applicationToCreate = await getApplicationModel(savedApplication);

        const newApplication = await createApplicationAsync({ data: applicationToCreate });

        if (savedApplication.application.imageUri) {
            const file = await fetch(savedApplication.application.imageUri).then((response) => response.blob());
            await uploadImageAsync({ id: newApplication.data.id, data: { File: file } });
        }

        navigate(`/applications/${newApplication.data.id}`);
    };

    return (
        <Grid>
            <Grid.Col span={12}>
                <ApplicationForm variant={DetailFormVariant.CREATE} onCancel={handleOnCancel} onSubmit={handleCreate} />
            </Grid.Col>
        </Grid>
    );
};
