import { ComboboxItem } from '@mantine/core';

import { PrincipalModel, PrincipalType } from 'api/v3/models';

export const getEmailItem = (email: string) => ({
    id: email,
    value: email,
    email,
    displayName: email,
    label: email,
    principalType: PrincipalType.User
});

export const handleOnChange = (
    valuePool: PrincipalModel[],
    values: string[],
    onChange?: (principals: PrincipalModel[]) => void
) => {
    const selectedPrincipals = valuePool.filter((principal) => values.some((value) => value === principal.id));
    const missingItems = values
        .filter((value) => !valuePool.some((poolValue) => poolValue.id === value))
        .map(getEmailItem);

    onChange && onChange([...selectedPrincipals, ...missingItems]);
};

export const mergeData = <TItemType extends { id: string }>(
    filteredData: TItemType[],
    value: TItemType[] | undefined
) => {
    if (!value) {
        return filteredData;
    }

    return [
        ...value.map((item) => ({ ...item })),
        ...filteredData.filter((data) => !value.some((val) => val.id === data.id))
    ];
};

export function getSelectItemsFromUserList(users?: PrincipalModel[]): ComboboxItem[] {
    if (!users) {
        return [];
    }

    return users.map((user) => ({
        value: user.id,
        label: `${user.displayName} (${user.email})`
    }));
}
