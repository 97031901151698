import { Button, Stack, Group, Image, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import errorPicture from './assets/error.svg';

import type { FallbackProps } from 'react-error-boundary';

export const ErrorBoundaryFallback = ({ error }: Omit<FallbackProps, 'resetErrorBoundary'>) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const mailBody = `Error: ${error.message}%0D%0ALocation: ${location.pathname}`;
    const mailLink = `mailto:support@umdaschgroup.com?Subject=${t('errorMailSubject')}&Body=${mailBody}`;

    return (
        <Stack align="center">
            <Image src={errorPicture} width="300px" />
            <Title order={1}>{t('unexpectedError')}</Title>
            {error && <Title order={3}> {error.message}</Title>}
            <Group>
                <Button variant="outline" onClick={() => navigate('/', { state: { preventRedirect: true } })}>
                    {t('returnToOverview')}
                </Button>
                <Button component="a" href={mailLink} variant="filled">
                    {t('contactSupportTeam')}
                </Button> 
            </Group> 
        </Stack>
    );
};
