import { ActionIcon, ActionIconProps, Tooltip } from '@mantine/core';
import { forwardRef, useState, MouseEvent, SVGProps } from 'react';
import { JSX } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';

export type CopyToClipboardProps = ActionIconProps & {
    textToCopy: string;
    copiedTime?: number;
    onClick?: (event: MouseEvent) => void;
};

export const CopyToClipboard = forwardRef<HTMLButtonElement, CopyToClipboardProps>(
    ({ textToCopy, copiedTime = 1000, onClick: handleClick, ...rest }: CopyToClipboardProps, ref) => {
        const [isCopied, setIsCopied] = useState(false);
        const { t } = useTranslation();

        const handleCopyToClipboard = (event: MouseEvent) => {
            navigator.clipboard.writeText(textToCopy);
            if (!isCopied) {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, copiedTime);
            }
            handleClick && handleClick(event);
        };

        return (
            <Tooltip label={t('copyToClipboard')}>
                <ActionIcon ref={ref} onClick={handleCopyToClipboard} {...rest}>
                    <div
                        style={{
                            position: 'relative',
                            height: 20,
                            width: 20
                        }}
                    >
                        <Clippy
                            style={{
                                color: 'gray',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                strokeDasharray: 50,
                                strokeDashoffset: isCopied ? -50 : 0,
                                transition: 'all 300ms ease-in-out'
                            }}
                        />
                        <Check
                            style={{
                                color: 'green',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                strokeDasharray: 50,
                                strokeDashoffset: isCopied ? 0 : -50,
                                transition: 'all 300ms ease-in-out'
                            }}
                            visibility={!isCopied ? 'hidden' : 'visible'}
                        />
                    </div>
                </ActionIcon>
            </Tooltip>
        );
    }
);

const Clippy = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
    return (
        <svg
            fill="none"
            height="20px"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            viewBox="0 0 16 16"
            width="20px"
            {...props}
        >
            <path d="M5.75 4.75H10.25V1.75H5.75V4.75Z" />
            <path d="M3.25 2.88379C2.9511 3.05669 2.75 3.37987 2.75 3.75001V13.25C2.75 13.8023 3.19772 14.25 3.75 14.25H12.25C12.8023 14.25 13.25 13.8023 13.25 13.25V3.75001C13.25 3.37987 13.0489 3.05669 12.75 2.88379" />
        </svg>
    );
};

const Check = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
    return (
        <svg
            fill="none"
            height="20px"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            viewBox="0 0 16 16"
            width="20px"
            {...props}
        >
            <path d="M13.25 4.75L6 12L2.75 8.75" />
        </svg>
    );
};

CopyToClipboard.displayName = 'CopyToClipboard';
