import { Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useClientFormContext } from 'modules/applications/client/ClientFormProvider';
import { usePermissions } from 'modules/shared/permissions';
import { DetailFormChildProps } from 'shared/components/detailForm';
import { ExtendedTextInput } from 'shared/components/inputs';
import { MultiTextInput } from 'shared/components/inputs/MultiTextInput';

type Props = DetailFormChildProps;

export const Uris = (_: Props) => {
    const { t } = useTranslation();
    const form = useClientFormContext();
    const canUpdateOrCreate = usePermissions(['Update', 'Create']);

    return (
        <Stack>
            <ExtendedTextInput
                form={form}
                label={t('baseUri')}
                placeholder={t('baseUriPlaceholder')}
                propertyPath="uriSettings.baseUri"
                readOnly={!canUpdateOrCreate}
            />
            <MultiTextInput
                addButtonText={t('addItem', { itemType: t('uri') })}
                defaultValue={form.values.uriSettings.baseUri ?? ''}
                description={t('redirectUrisDescription')}
                form={form}
                label={t('redirectUris')}
                propertyPath="uriSettings.redirectUris"
                readonly={!canUpdateOrCreate}
            />
            <MultiTextInput
                addButtonText={t('addItem', { itemType: t('uri') })}
                defaultValue={form.values.uriSettings.baseUri ?? ''}
                description={t('postLogoutRedirectUrisDescription')}
                form={form}
                label={t('postLogoutRedirectUris')}
                propertyPath="uriSettings.postLogoutRedirectUris"
                readonly={!canUpdateOrCreate}
            />
            <MultiTextInput
                addButtonText={t('addItem', { itemType: t('uri') })}
                defaultValue={form.values.uriSettings.baseUri ?? ''}
                description={t('allowedCorsOriginsDescription')}
                form={form}
                label={t('allowedCorsOrigins')}
                propertyPath="uriSettings.allowedCorsOrigins"
                readonly={!canUpdateOrCreate}
            />
            <ExtendedTextInput
                form={form}
                label={t('frontchannelLogoutUri')}
                propertyPath="uriSettings.frontChannelLogoutUri"
                readOnly={!canUpdateOrCreate}
            />
            <ExtendedTextInput
                form={form}
                label={t('backchannelLogoutUri')}
                propertyPath="uris.backChannelLogoutUri"
                readOnly={!canUpdateOrCreate}
            />
        </Stack>
    );
};
