import { Button } from '@mantine/core';
import { Icon } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

export const RemoveItem = <TRowType,>({
    onClick,
    selectedItems,
    canRemoveItem
}: {
    onClick: () => void;
    selectedItems: TRowType[];
    canRemoveItem?: (item: TRowType) => boolean;
}) => {
    const { t } = useTranslation();
    return (
        <Button
            disabled={
                !selectedItems ||
                selectedItems.length <= 0 ||
                selectedItems.some((selectedItem) => (canRemoveItem ? !canRemoveItem(selectedItem) : false))
            }
            leftSection={<Icon icon="delete" />}
            variant="outline"
            onClick={() => onClick()}
        >
            {t('remove')}
        </Button>
    );
};
