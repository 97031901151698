import { randomId } from '@mantine/hooks';

import {
    ClientAccessibility,
    CreateOrUpdateClientModel,
    DetailClientModel,
    RoleAssignmentRoleModelRoleType,
    TokenUsage
} from 'api/v3/models';
import { ClientFormValues } from 'modules/applications/client/ClientFormProvider';
import { getAssignmentTypeForPrincipalType } from 'modules/shared/PrincipalSelector';

export const getUpdateClientModel = (clientFormValue: ClientFormValues): CreateOrUpdateClientModel =>
    getClientModel(clientFormValue);

export const getCreateClientModel = (clientFormValue: ClientFormValues): CreateOrUpdateClientModel =>
    getClientModel(clientFormValue);

const getClientModel = (clientFormValue: ClientFormValues): CreateOrUpdateClientModel => ({
    ...clientFormValue,
    uriSettings: {
        ...clientFormValue.uriSettings,
        baseUri: clientFormValue.uriSettings.baseUri === '' ? null : clientFormValue.uriSettings.baseUri
    },
    clientPictureUris: [],
    accessibilitySettings: {
        accessibility: clientFormValue.accessibilitySettings.accessibility,
        principalsWithAccess:
            clientFormValue.accessibilitySettings.accessibility === ClientAccessibility.Dedicated
                ? clientFormValue.accessibilitySettings.principalsWithAccess
                      .filter((principal) => !principal.isRemoved)
                      .map((principal) => ({
                          identifier: principal.id,
                          type: principal.assignmentType ?? getAssignmentTypeForPrincipalType(principal.type)
                      }))
                : null,
        allowUserSignup: true,
        visibleToUsers: clientFormValue.accessibilitySettings.visibleToUsers
    },
    permissionSettings: {
        ...clientFormValue.permissionSettings,
        clientScopes: {
            scopes: clientFormValue.permissionSettings.clientScopes
                .filter((scope) => !scope.isRemoved)
                .map((scope) => ({
                    scopeId: scope.id,
                    scopeIdentifier: scope.name
                }))
        },
        roleAssignments: {
            roleAssignments: clientFormValue.permissionSettings.roleAssignments
                .filter((role) => !role.isRemoved)
                .map((role) => ({
                    roleId: role.id,
                    identifier: role.name,
                    roleType: RoleAssignmentRoleModelRoleType.ApiResourceRole
                }))
        },
        relatedClients: {
            relatedClientIds: clientFormValue.permissionSettings.relatedClients
                .filter((relatedClient) => !relatedClient.isRemoved)
                .map((relatedClient) => relatedClient.id)
        }
    },
    securitySettings: {
        authorizationCodeGrantSettings: {
            requirePKCE: clientFormValue.securitySettings.enablePKCE,
            authorizationCodeLifetime: clientFormValue.securitySettings.authorizationCodeLifetime
        },
        identityTokenSettings: {
            tokenLifetime: clientFormValue.securitySettings.identityTokenLifetime,
            includeUserClaims: clientFormValue.securitySettings.includeUserClaims
        },
        accessTokenSettings: {
            tokenLifetime: clientFormValue.securitySettings.accessTokenLifetime
        },
        clientSecretSettings: {
            requireClientSecret: clientFormValue.securitySettings.requireClientSecret
        },
        refreshTokenSettings: {
            enabled: clientFormValue.securitySettings.refreshTokenEnabled,
            usage: clientFormValue.securitySettings.refreshTokenRotation ? 'OneTimeOnly' : 'ReUse',
            expiration: clientFormValue.securitySettings.refreshTokenRotation ? 'Sliding' : 'Absolute',
            slidingTokenLifetime: clientFormValue.securitySettings.refreshTokenSlidingLifetime,
            absoluteTokenLifetime: clientFormValue.securitySettings.refreshTokenLifetime
        }
    }
});

export function getFormClient(client?: DetailClientModel): ClientFormValues {
    return client ? getFormClientFromExisting(client) : getNewFormClient();
}

export function getFormClientFromExisting(client: DetailClientModel): ClientFormValues {
    return {
        ...client,
        accessibilitySettings: {
            ...client.accessibilitySettings,
            principalsWithAccess: client.accessibilitySettings.principalsWithAccess
                ? client.accessibilitySettings.principalsWithAccess.map((principal) => ({
                      ...principal,
                      type: principal.principalType,
                      key: randomId(),
                      isEditable: false,
                      isRemoved: false
                  }))
                : []
        },
        permissionSettings: {
            ...client.permissionSettings,
            clientScopes: client.permissionSettings.clientScopes.scopes.map((scope) => ({
                id: scope.scopeId,
                name: scope.scopeIdentifier,
                displayName: scope.scopeDisplayName,
                description: scope.scopeDescription,
                key: randomId(),
                isEditable: false,
                isRemoved: false
            })),
            roleAssignments: client.permissionSettings.roleAssignments.roleAssignments.map((role) => ({
                id: role.roleId,
                name: role.roleIdentifier,
                displayName: role.roleDisplayName,
                key: randomId(),
                isEditable: false,
                isRemoved: false
            })),
            relatedClients: client.permissionSettings.relatedClients.relatedClients.map((relatedClient) => ({
                id: relatedClient.id,
                displayName: relatedClient.name,
                key: randomId(),
                isEditable: false,
                isRemoved: false
            }))
        },
        securitySettings: {
            enablePKCE: !!client.securitySettings.authorizationCodeGrantSettings.requirePKCE,
            identityTokenLifetime: client.securitySettings.identityTokenSettings.tokenLifetime ?? '',
            accessTokenLifetime: client.securitySettings.accessTokenSettings.tokenLifetime ?? '',
            includeUserClaims: !!client.securitySettings.identityTokenSettings.includeUserClaims,
            refreshTokenEnabled: !!client.securitySettings.refreshTokenSettings.enabled,
            refreshTokenLifetime: client.securitySettings.refreshTokenSettings.absoluteTokenLifetime ?? '',
            refreshTokenRotation: client.securitySettings.refreshTokenSettings.usage === TokenUsage.OneTimeOnly,
            refreshTokenSlidingLifetime: client.securitySettings.refreshTokenSettings.slidingTokenLifetime ?? '',
            requireClientSecret: !!client.securitySettings.clientSecretSettings.requireClientSecret,
            authorizationCodeLifetime:
                client.securitySettings.authorizationCodeGrantSettings.authorizationCodeLifetime ?? ''
        }
    };
}

export function getNewFormClient(): ClientFormValues {
    return {
        basicInformation: {
            name: '',
            id: '',
            clientPictureUri: '',
            description: '',
            allowedGrantTypes: {
                authorizationCode: false,
                clientCredentials: false,
                tokenExchange: false
            },
            enabled: true,
            clientBackgroundUri: ''
        },
        uriSettings: {
            redirectUris: [],
            postLogoutRedirectUris: [],
            allowedCorsOrigins: []
        },
        accessibilitySettings: {
            accessibility: ClientAccessibility.Public,
            principalsWithAccess: [],
            visibleToUsers: false
        },
        permissionSettings: {
            clientScopes: [],
            roleAssignments: [],
            relatedClients: [],
            identityPermissions: {
                isOpenIdScopeRequestAllowed: true,
                isProfileScopeRequestAllowed: false,
                isEmailScopeRequestAllowed: false,
                isPhoneScopeRequestAllowed: false,
                isRolesScopeRequestAllowed: false
            }
        },
        securitySettings: {
            enablePKCE: true,
            identityTokenLifetime: '01:00:00',
            accessTokenLifetime: '01:00:00',
            includeUserClaims: false,
            refreshTokenEnabled: true,
            refreshTokenLifetime: '01:00:00',
            refreshTokenRotation: true,
            refreshTokenSlidingLifetime: '01:00:00',
            requireClientSecret: false,
            authorizationCodeLifetime: '00:05:00'
        }
    };
}
