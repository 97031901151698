import { ActionIcon, Tooltip } from '@mantine/core';
import { ReactNode } from 'react';

export type ItemCardActionProps = {
    onClick: () => void;
    title: string;
    icon: ReactNode;
};

export const ItemCardAction = ({ onClick, title, icon }: ItemCardActionProps) => {
    return (
        <Tooltip label={title}>
            <ActionIcon
                size="lg"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClick();
                }}
            >
                {icon}
            </ActionIcon>
        </Tooltip>
    );
};
