import {
    TextInput,
    TextInputProps,
    Textarea,
    TextareaProps,
    Text,
    Tooltip,
    UnstyledButton,
    MultiSelect,
    MultiSelectProps,
    Stack
} from '@mantine/core';
import { FormErrors, UseFormReturnType } from '@mantine/form';
import { Icon, variants } from '@uag/react-core';
import React, { forwardRef, RefAttributes } from 'react';

export interface IIconDescription {
    label?: React.ReactNode;
    iconDescription?: string | undefined;
    optional?: string;
}

export const Label = ({ label, iconDescription, optional }: IIconDescription) => {
    return (
        <div className="flex flex-row gap-base">
            <Text fw={500}>{label}</Text>
            {!!optional && (
                <Text c="gray.6" fw="lighter">
                    ({optional})
                </Text>
            )}
            {iconDescription && (
                <Tooltip label={iconDescription} position="right-end" variant={variants.tooltip.right}>
                    <UnstyledButton>
                        <Icon color="gray" icon="info" size="lg" style={{ marginBottom: '1px', verticalAlign: 'middle' }} />
                    </UnstyledButton>
                </Tooltip>
            )}
        </div>
    );
};

export interface IMantineFormControl {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: UseFormReturnType<any, any>;
    propertyPath: string;
}

export interface IWarningProps {
    warnings?: FormErrors;
}

export interface ExtendedTextInputProps
    extends IMantineFormControl,
        IWarningProps,
        IIconDescription,
        Omit<TextInputProps, 'form'>,
        RefAttributes<HTMLInputElement> {}

export const ExtendedTextInput = forwardRef<HTMLInputElement, ExtendedTextInputProps>(
    ({ form, propertyPath: propertyName, label, iconDescription, style, disabled, ...others }: ExtendedTextInputProps, ref) => {
        // TODO: Change to better solution after moving to react-hook-form
        const props = form.getInputProps(propertyName);

        if (props.value === null) {
            props.value = '';
        }

        return (
            <Stack className="flex-grow" gap={0} style={style}>
                <TextInput
                    disabled={disabled}
                    {...props}
                    ref={ref}
                    {...others}
                    label={label && <Label iconDescription={iconDescription} label={label} />}
                />
            </Stack>
        );
    }
);

ExtendedTextInput.displayName = 'ExtendedTextInput';

export interface ExtendedTextAreaProps
    extends IMantineFormControl,
        IWarningProps,
        IIconDescription,
        Omit<TextareaProps, 'form'>,
        RefAttributes<HTMLTextAreaElement> {}

export const ExtendedTextarea = forwardRef<HTMLTextAreaElement, ExtendedTextAreaProps>(
    ({ form, propertyPath: propertyName, label, iconDescription, optional, disabled, ...others }: ExtendedTextAreaProps, ref) => {
        // TODO: Change to better solution after moving to react-hook-form
        const props = form.getInputProps(propertyName);

        if (props.value === null) {
            props.value = '';
        }

        return (
            <Stack gap={0}>
                <Textarea
                    disabled={disabled}
                    {...props}
                    ref={ref}
                    {...others}
                    label={label && <Label iconDescription={iconDescription} label={label} optional={optional} />}
                />
            </Stack>
        );
    }
);

ExtendedTextarea.displayName = 'ExtendedTextarea';

export interface ExtendedMultiSelectProps
    extends IMantineFormControl,
        IWarningProps,
        IIconDescription,
        Omit<MultiSelectProps, 'form'>,
        RefAttributes<HTMLInputElement> {}

export const ExtendedMultiSelect = forwardRef<HTMLInputElement, ExtendedMultiSelectProps>(
    ({ form, propertyPath: propertyName, label, iconDescription, optional, disabled, ...others }: ExtendedMultiSelectProps, ref) => {
        // TODO: Change to better solution after moving to react-hook-form
        const props = form.getInputProps(propertyName);

        if (props.value === null) {
            props.value = '';
        }

        return (
            <Stack gap={0}>
                <MultiSelect
                    disabled={disabled}
                    {...props}
                    ref={ref}
                    {...others}
                    label={<Label iconDescription={iconDescription} label={label} optional={optional} />}
                />
            </Stack>
        );
    }
);

ExtendedMultiSelect.displayName = 'ExtendedMultiSelect';
