import { AssignmentAction, CreateOrUpdateGroupModel, AssigneeAction, PrincipalModel } from 'api/v3/models';
import { getAssignmentTypeForPrincipalType } from 'modules/shared/PrincipalSelector';
import { GroupFormMembersType, GroupFormType } from './GroupFormProvider';

export const getGroupModel = (groupFormValue: GroupFormType): CreateOrUpdateGroupModel => ({
    name: groupFormValue.basicInformation.name,
    description: groupFormValue.basicInformation.description,
    owners: groupFormValue.basicInformation.owners.map((owner) => ({
        identifier: owner.id ?? owner.email ?? '',
        type: owner.id === owner.email ? 'UserEmail' : 'UserId'
    }))
});

export function getGroupAssigneeActions(
    members: GroupFormMembersType[],
    currentMembers: PrincipalModel[] = []
): AssigneeAction[] {
    return [
        ...getGroupAssigneeAssignActions(members, currentMembers),
        ...getGroupAssigneeRevokeActions(members, currentMembers)
    ];
}

function getGroupAssigneeAssignActions(members: GroupFormMembersType[], currentMembers: PrincipalModel[] = []) {
    return members
        .filter((member) => !currentMembers?.some((currentMember) => currentMember.id === member.identifier))
        .map((member) => ({
            assignee: {
                identifier: member.identifier,
                type: member.assignmentType
            },
            assignmentAction: AssignmentAction.Assign
        }));
}

function getGroupAssigneeRevokeActions(members: GroupFormMembersType[], currentMembers: PrincipalModel[] = []) {
    return currentMembers
        .filter((currentMember) => !members?.some((member) => currentMember.id === member.identifier))
        .map((currentMember) => ({
            assignee: {
                identifier: currentMember.id,
                type: getAssignmentTypeForPrincipalType(currentMember.principalType)
            },
            assignmentAction: AssignmentAction.Revoke
        }));
}
