import { useTranslation } from 'react-i18next';

import { CreateItemModal } from 'shared/components';
import { InviteUser } from '../InviteUser';
import { InviteUserButton } from './InviteUserButton';

export const InviteUserModal = () => {
    const { t } = useTranslation();
    return <CreateItemModal AddButton={InviteUserButton} AddDialogType={InviteUser} title={t('inviteUser')} />;
};
