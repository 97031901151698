import { Stack, Title } from '@mantine/core';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiResourceScopeModelWithClients, GetRoleAssignmentResponseItem } from 'api/v3/models';
import { ConfirmationModal } from 'shared/components/confirmationModal/ConfirmationModal';
import { OverviewGrid } from 'shared/components/OverviewGrid';

type Props = {
    removedScopes: ApiResourceScopeModelWithClients[];
    removedRoles: GetRoleAssignmentResponseItem[][];
    onSave: () => void | Promise<void>;
    onCancel: () => void;
};

export const RemovedScopesRolesModal = ({
    removedScopes,
    removedRoles,
    onSave: handleSave,
    onCancel: handleCancel
}: Props) => {
    const { t } = useTranslation();
    return (
        <ConfirmationModal
            confirmText={t('save')}
            isOpen={removedScopes.length > 0 || removedRoles.length > 0}
            size="xl"
            title={t('saveItem', { itemType: t('api') })}
            onCancel={handleCancel}
            onConfirm={handleSave}
        >
            {removedScopes.length > 0 && (
                <Stack mb="lg">
                    <Title order={4}>{t('deleteScopesMessage')}</Title>
                    {removedScopes.map((removedScope) => (
                        <Fragment key={removedScope.id}>
                            <Title order={5}>
                                {t('scope')}: {removedScope.displayName}
                            </Title>
                            <OverviewGrid
                                data={removedScope.clients}
                                fieldDescriptions={[
                                    {
                                        header: t('itemName', { itemType: t('client') }),
                                        data: 'name'
                                    },
                                    {
                                        header: t('displayName'),
                                        data: 'displayName'
                                    },
                                    {
                                        header: t('description'),
                                        data: 'description'
                                    }
                                ]}
                                withSearch={false}
                            />
                        </Fragment>
                    ))}
                </Stack>
            )}
            {removedRoles.length > 0 && (
                <Stack mb="lg">
                    <Title order={4}>{t('deleteRolesMessage')}</Title>
                    {removedRoles.map((removedRole) => (
                        <Fragment key={removedRole[0].role.roleId}>
                            <Title order={5}>
                                {t('role')}: {removedRole[0].role.roleIdentifier}
                            </Title>
                            <OverviewGrid
                                data={removedRole}
                                fieldDescriptions={[
                                    {
                                        header: t('type'),
                                        data: 'principal.principalType'
                                    },
                                    {
                                        header: t('name'),
                                        data: 'principal.displayName'
                                    },
                                    {
                                        header: t('description'),
                                        data: 'principal.description'
                                    }
                                ]}
                                withSearch={false}
                            />
                        </Fragment>
                    ))}
                </Stack>
            )}
        </ConfirmationModal>
    );
};
