import { Button } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { Icon } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { getItemLink } from 'modules/shared/functions';
import { usePermissions } from 'modules/shared/permissions';
import { PrincipalSelector, SelectedPrincipal } from 'modules/shared/PrincipalSelector';
import { DetailFormChildProps } from 'shared/components/detailForm';
import { ExtendedDataTable } from 'shared/components/extendedDataTable';
import { Placeholder } from 'shared/components/placeholder';
import { GroupDataGridMembersType, GroupFormMembersType, GroupFormType, useGroupFormContext } from '../GroupFormProvider';
import { NoAssignedMembersPlaceholder } from '../NoAssignedMembersPlaceholder';

type Props = DetailFormChildProps;

const generateNewMember = (): GroupDataGridMembersType => ({
    identifier: '',
    assignmentType: 'UserId',
    principalType: 'User',
    displayName: '',
    key: randomId(),
    isEditable: true,
    isRemoved: false
});

type AddGroupMemberProps = {
    setContentReadOnly?: boolean;
};

const AddGroupMember = ({ setContentReadOnly = false }: AddGroupMemberProps) => {
    const form = useGroupFormContext();
    const { t } = useTranslation();

    return (
        <Button disabled={setContentReadOnly} leftSection={<Icon icon="add" />} onClick={() => form.insertListItem('members', generateNewMember())}>
            {t('addItem', { itemType: t('member') })}
        </Button>
    );
};

function getGridMembers(data: GroupFormMembersType[]): GroupDataGridMembersType[] {
    return data.map((s) => ({
        key: randomId(),
        isEditable: false,
        isRemoved: false,
        ...s
    }));
}

const handlePrincipalSelected = (form: UseFormReturnType<GroupFormType>, selectedPrincipal: SelectedPrincipal, index: number) => {
    form.setFieldValue(`members.${index}.isEditable`, false);
    form.setFieldValue(`members.${index}.identifier`, selectedPrincipal.id);
    form.setFieldValue(`members.${index}.email`, selectedPrincipal.email);
    form.setFieldValue(`members.${index}.displayName`, selectedPrincipal.displayName);
    form.setFieldValue(`members.${index}.principalType`, selectedPrincipal.principalType);
    form.setFieldValue(`members.${index}.assignmentType`, selectedPrincipal.assignmentType);
};

export const Members = (_: Props) => {
    const { t } = useTranslation();
    const form = useGroupFormContext();
    const canAddRemoveMembers = usePermissions('AddRemoveMembers');

    return (
        <ExtendedDataTable
            canCreate={canAddRemoveMembers}
            canEdit={canAddRemoveMembers}
            canRemove={canAddRemoveMembers}
            data={getGridMembers(form.values.members)}
            fieldDescriptions={[
                {
                    header: t('displayName'),
                    data: 'displayName',
                    lockUpdate: true,
                    link: (item) => getItemLink({ id: item.identifier, type: item.principalType })
                },
                {
                    header: t('email'),
                    data: 'email',
                    lockUpdate: true
                },
                {
                    header: t('type'),
                    data: 'principalType',
                    lockUpdate: true
                }
            ]}
            form={form}
            generateNewItem={canAddRemoveMembers ? generateNewMember : undefined}
            placeholder={
                canAddRemoveMembers ? <NoAssignedMembersPlaceholder addButton={<AddGroupMember />} /> : <Placeholder text={t('noAssignedMembers')} />
            }
            propertyPathBase="members"
            renderEdit={(_item, index, onItemSaved) => {
                return (
                    <PrincipalSelector
                        additionalPrincipals={form.values.members.map((member) => ({
                            id: member.identifier,
                            displayName: member.displayName,
                            email: member.email,
                            principalType: member.principalType
                        }))}
                        form={form}
                        propertyPath={`members.${index}.identifier`}
                        onPrincipalSelected={(principal) => {
                            handlePrincipalSelected(form, principal, index);
                            onItemSaved && onItemSaved();
                        }}
                    />
                );
            }}
        />
    );
};
