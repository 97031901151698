import { IRowData } from './interfaces';

function compare<T>(a: T, b: T): number {
    if (a === b) {
        return 0;
    }
    if (a == null) {
        return -1;
    }
    if (b == null) {
        return 1;
    }

    // To appease the type system, check both
    // I think it might be a bug that this is required
    if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b);
    }

    return b > a ? -1 : 1;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isObject(value: any): boolean {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
}

// Helper function for recursive search
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function containsQueryInObject(obj: any, query: string): boolean {
    for (const key in obj) {
        const value = obj[key];
        if (isObject(value)) {
            if (containsQueryInObject(value, query)) {
                return true;
            }
        } else if (String(value).toLowerCase().includes(query)) {
            return true;
        }
    }
    return false;
}

// Updated filterData function using recursion
function filterData<TRowType extends IRowData>(data: TRowType[], search: string) {
    const query = search.toLowerCase().trim();
    return data.filter((item) => containsQueryInObject(item, query));
}

export function sortData<TRowType extends IRowData>(
    data: TRowType[],
    payload: { sortBy: string | null; reversed: boolean; search: string }
) {
    const { sortBy } = payload;

    if (!sortBy) {
        return filterData(data, payload.search);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return compare(b[sortBy as keyof TRowType], a[sortBy as keyof TRowType]);
            }
            return compare(a[sortBy as keyof TRowType], b[sortBy as keyof TRowType]);
        }),
        payload.search
    );
}
