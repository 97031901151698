import { LoadingAnimation } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useGetTenantById } from 'api/v3/tenant/tenant';
import { PermissionProvider, usePermissions } from 'modules/shared/permissions';
import { ItemNotFound } from 'shared/components/ItemNotFound';
import { EditTenant } from './EditTenant';
import { ViewTenant } from './ViewTenant';

export const Tenant = () => {
    const { tenantId = '' } = useParams();
    const { t } = useTranslation();
    const { data: tenant, error } = useGetTenantById(tenantId, { includePermissions: true });
    const canReadFull = usePermissions('ReadFull', 'Tenant', tenant);

    if (error) {
        return <ItemNotFound errorStatus={error.response?.status} goBackRoute="../" itemId={tenantId} itemType={t('tenant')} />;
    }

    if (!tenant) {
        return <LoadingAnimation />;
    }

    return (
        <PermissionProvider context="ApiResource" item={tenant}>
            {canReadFull ? <EditTenant /> : <ViewTenant />}
        </PermissionProvider>
    );
};
