import { useOidcUser } from '@axa-fr/react-oidc';
import { LoadingAnimation } from '@uag/react-core';
import { useNavigate } from 'react-router-dom';

import { useAddRemoveGroupMembers, useCreateGroup, useAssignRevokeGroupRoles } from 'api/v3/groups/groups';
import { AddDialogProps } from 'shared/components/FlexList';
import { GroupForm } from './GroupForm';
import { GroupFormType } from './GroupFormProvider';
import { getGroupAssigneeActions, getGroupModel, getRoleAssignmentActions } from './mappings';

import type { DetailGroupModel, PrincipalModel, GetRoleAssignmentResponseItem } from 'api/v3/models';

type Props = {
    initialValues?: {
        group: DetailGroupModel;
        roles?: GetRoleAssignmentResponseItem[];
        members?: PrincipalModel[];
    };
} & AddDialogProps;

export const CreateGroup = ({ initialValues, onCancel: handleCancel }: Props) => {
    const { mutateAsync: createGroupAsync } = useCreateGroup();
    const { mutateAsync: assignRemoveGroupMemberyAsync } = useAddRemoveGroupMembers();
    const { mutateAsync: assignRevokeGroupRolesAsync } = useAssignRevokeGroupRoles();
    const { oidcUser } = useOidcUser();

    const navigate = useNavigate();

    const handleSubmit = async (groupFormValues: GroupFormType) => {
        const groupToCreate = getGroupModel(groupFormValues);

        const newGroup = await createGroupAsync({
            data: groupToCreate
        });

        await assignRemoveGroupMemberyAsync({
            id: newGroup.data.id,
            data: {
                memberAssigneeActions: getGroupAssigneeActions(groupFormValues.members)
            }
        });
        await assignRevokeGroupRolesAsync({
            id: newGroup.data.id,
            data: {
                roleAssignmentActions: getRoleAssignmentActions(groupFormValues.roles)
            }
        });

        navigate(`${newGroup.data.id}`);
    };

    if (!oidcUser) {
        return <LoadingAnimation />;
    }

    return (
        <GroupForm
            currentUser={oidcUser}
            group={initialValues?.group}
            members={initialValues?.members}
            roles={initialValues?.roles}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
        />
    );
};
