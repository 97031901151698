import { Image, FileButton, ActionIcon } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { Icon, LoadingAnimation } from '@uag/react-core';

type ImageFileButtonProps = {
    width: string | number;
    height: string | number;
    imageSrc?: string | null;
    isLoading?: boolean;
    readOnly?: boolean;
    onImageSelected?: (file: File) => void;
    onDeleteImage?: () => void;
};

export const ImageFileButton = ({
    width,
    height,
    imageSrc,
    isLoading = false,
    readOnly = false,
    onImageSelected,
    onDeleteImage
}: ImageFileButtonProps) => {
    const { hovered, ref } = useHover();

    return (
        <div
            ref={ref}
            className="border-border-default border relative overflow-hidden"
            style={{
                height,
                minHeight: height,
                minWidth: width,
                width
            }}
        >
            {!readOnly && onImageSelected && (
                <FileButton accept="image/png,image/jpeg" onChange={(file) => file && onImageSelected(file)}>
                    {(props) =>
                        hovered && (
                            <ActionIcon
                                {...props}
                                bg="#55555577"
                                className="rounded-none"
                                h={height}
                                loading={isLoading}
                                pos="absolute"
                                style={{
                                    zIndex: 2
                                }}
                                w={width}
                            >
                                <Icon color="white" icon="edit" size="5xl" />
                            </ActionIcon>
                        )
                    }
                </FileButton>
            )}
            {hovered && !readOnly && onDeleteImage && imageSrc && (
                <ActionIcon
                    color="white"
                    pos="absolute"
                    right={0}
                    style={{
                        zIndex: 3
                    }}
                    onClick={onDeleteImage}
                >
                    <Icon icon="close" />
                </ActionIcon>
            )}
            {isLoading && <LoadingAnimation />}

            {!isLoading && (
                <Image
                    alt="image"
                    fit="cover"
                    h={height}
                    pos="absolute"
                    src={imageSrc ? imageSrc : `https://placehold.co/${width}x${height}?text=1920+x+1080`}
                    w={width}
                />
            )}
        </div>
    );
};
