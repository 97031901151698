import { Tooltip } from '@mantine/core';
import { Icon } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { ClientSecretExpirationState as ExpirationState } from 'api/v3/models';

type Props = {
    expirationState?: ExpirationState;
};

export const ClientSecretExpirationState = ({ expirationState }: Props) => {
    const { t } = useTranslation();

    if (expirationState === 'Active') {
        return null;
    }

    let color,
        expirationText = '';

    switch (expirationState) {
        case 'Expired':
            color = 'red';
            expirationText = t('clientSecretExpired');
            break;
        case 'NearExpired':
            color = 'orange';
            expirationText = t('clientSecretAlmostExpired');
            break;
    }

    return (
        <Tooltip label={expirationText}>
            <div>
                <Icon color={color} icon="warning" size="3xl" />
            </div>
        </Tooltip>
    );
};
