import { Stack, Title, Space } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { LoadingAnimation } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { Salutation } from 'api/v3/models';
import { useGetSupportedCultureCodes } from 'api/v3/user/user';
import { usePermissions } from 'modules/shared/permissions';
import { ImageFileButton } from 'shared/components/buttons';
import { DetailFormChildProps } from 'shared/components/detailForm';
import { ExtendedTextInput, Select } from 'shared/components/inputs';
import { BasicUserFormType, DetailUserFormType, isDetailUser } from '../UserFormProvider';

type Props = {
    form: UseFormReturnType<BasicUserFormType> | UseFormReturnType<DetailUserFormType>;
} & DetailFormChildProps;

export const BasicInformation = ({ form }: Props) => {
    const { t } = useTranslation();
    const canUpdate = usePermissions(['Update']);
    const { data: languages, isLoading } = useGetSupportedCultureCodes();

    const isSynced = form.values.basicInformation.loginProviderType === 'AzureAD';

    if (isLoading) {
        return <LoadingAnimation />;
    }

    return (
        <Stack>
            <div className="flex gap-base items-center">
                <ImageFileButton height={100} imageSrc={form.values.basicInformation.profilePictureUri} readOnly={!canUpdate} width={100} />
                <div className="grid grid-cols-3 gap-base" style={{ flex: 1 }}>
                    <ExtendedTextInput
                        form={form}
                        label={t('firstName')}
                        propertyPath="basicInformation.firstName"
                        readOnly={!canUpdate || isSynced}
                    />
                    <ExtendedTextInput form={form} label={t('lastName')} propertyPath="basicInformation.lastName" readOnly={!canUpdate || isSynced} />
                    <ExtendedTextInput
                        form={form}
                        label={t('displayName')}
                        propertyPath="basicInformation.displayName"
                        readOnly={!canUpdate || isSynced}
                    />
                    <ExtendedTextInput form={form} label={t('username')} propertyPath="basicInformation.userName" readOnly={!canUpdate || isSynced} />
                    <ExtendedTextInput form={form} label={t('email')} propertyPath="basicInformation.email" readOnly={!canUpdate || isSynced} />
                </div>
            </div>

            {isDetailUser(form.values) && (
                <>
                    <Title order={4}>{t('extendedInformation')}</Title>
                    <div className="grid grid-cols-2 gap-base" style={{ flex: 1 }}>
                        <ExtendedTextInput form={form} label={t('title')} propertyPath="basicInformation.title" readOnly={!canUpdate} />
                        <Select
                            data={Object.keys(Salutation)}
                            form={form}
                            label={t('salutation')}
                            propertyPath="basicInformation.salutation"
                            readOnly={!canUpdate}
                        />
                        <Select
                            data={languages?.data}
                            form={form}
                            label={t('language')}
                            propertyPath="basicInformation.language"
                            readOnly={!canUpdate}
                        />
                        <Space />
                        <ExtendedTextInput
                            form={form}
                            label={t('mobilePhoneNumber')}
                            propertyPath="basicInformation.mobileNumber"
                            readOnly={!canUpdate || isSynced}
                        />
                        <ExtendedTextInput
                            form={form}
                            label={t('phoneNumber')}
                            propertyPath="basicInformation.phoneNumber"
                            readOnly={!canUpdate || isSynced}
                        />
                    </div>
                </>
            )}
        </Stack>
    );
};
