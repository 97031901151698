import { SidebarNavigationLinkConfig, SidebarNavigationProvider } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { ApiRedirect } from 'modules/applications/api/ApiRedirect';
import { ApiBaseRoute, ApiBreadcrumb, ApiRoutes } from 'modules/applications/api/Routes';
import { ClientRedirect } from 'modules/applications/client/ClientRedirect';
import { ClientBaseRoute, ClientRoutes, ClientBreadcrumb } from 'modules/applications/client/Routes';
import { BreadcrumbProvider } from 'shared/components/baseLayout';
import { Application } from './application/Application';
import { useApplication } from './application/ApplicationProvider';
import { Applications } from './application/Applications';
import { ApplicationFrame } from './ApplicationFrame';

export const ApplicationBaseRoute = 'applications';

const ApplicationBreadcrumb = () => {
    const { application } = useApplication();

    return <>{application?.displayName ?? '...'}</>;
};

export const ApplicationRoutes = () => {
    const { t } = useTranslation();
    const breadcrumbs: BreadcrumbsRoute[] = [
        {
            path: `${ApplicationBaseRoute}/:applicationId`,
            breadcrumb: ApplicationBreadcrumb
        },
        {
            path: `${ApplicationBaseRoute}/:applicationId/${ApiBaseRoute}/:apiId`,
            breadcrumb: ApiBreadcrumb
        },
        {
            path: `${ApplicationBaseRoute}/:applicationId/${ClientBaseRoute}/:clientId`,
            breadcrumb: ClientBreadcrumb
        }
    ];

    const navLinks: SidebarNavigationLinkConfig[] = [
        { link: '', label: t('overview'), icon: 'home' },
        { link: ClientBaseRoute, label: t('clients'), icon: 'devices' },
        { link: ApiBaseRoute, label: t('apis'), icon: 'network_node' }
    ];

    return (
        <BreadcrumbProvider breadcrumbs={breadcrumbs}>
            <SidebarNavigationProvider navLinks={navLinks}>
                <Routes>
                    <Route element={<Applications />} path="/" />
                    <Route element={<ApplicationFrame />} path="/:applicationId">
                        <Route element={<Application />} path={''} />
                        <Route element={<ClientRoutes />} path={`${ClientBaseRoute}/*`} />
                        <Route element={<ApiRoutes />} path={`${ApiBaseRoute}/*`} />
                    </Route>
                    <Route element={<ClientRedirect />} path={`${ClientBaseRoute}/:clientId`} />
                    <Route element={<ApiRedirect />} path={`${ApiBaseRoute}/:apiId`} />
                </Routes>
            </SidebarNavigationProvider>
        </BreadcrumbProvider>
    );
};
