import { Stack, Text, Tooltip, ActionIcon, Menu } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { Icon, toastNotifications } from '@uag/react-core';
import { MouseEvent, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { UserState, BasicUserModelApiResponse } from 'api/v3/models';
import { getSearchUsersQueryKey, useUndeleteUser } from 'api/v3/user/user';
import { usePermissions } from 'modules/shared/permissions';
import { RowActionVariant } from 'shared/components/extendedDataTable';

export const UndeleteUserAction = (user: BasicUserModelApiResponse, index: number, rowActionVariant: RowActionVariant) => {
    const { t } = useTranslation();
    const { mutateAsync: undeleteUser } = useUndeleteUser();
    const queryClient = useQueryClient();
    const id = useId();
    const canUndelete = usePermissions('SoftDeleteUser', 'User', user);

    if (user.data.state !== UserState.Deleted) {
        return null;
    }

    const handleResetPassword = async (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        toastNotifications.loading({
            id,
            message: t('willBe', {
                type: t('user'),
                name: `${user.data.displayName}`,
                action: t('undeleted')
            })
        });
        try {
            await undeleteUser({ id: user.data.id });
            await queryClient.invalidateQueries({ queryKey: getSearchUsersQueryKey() });

            toastNotifications.sucess({
                message: (
                    <Stack>
                        <Text>
                            {t('wasSuccessful', {
                                type: t('user'),
                                name: user.data.displayName,
                                action: t('undelete')
                            })}
                        </Text>
                    </Stack>
                ),
                autoClose: true
            });
        } catch {
            toastNotifications.error({
                message: t('wasNotSuccessful', {
                    type: t('user'),
                    name: user.data.displayName,
                    action: t('undelete')
                })
            });
        } finally {
            toastNotifications.hide(id);
        }
    };

    return (
        canUndelete &&
        (rowActionVariant === RowActionVariant.DEFAULT ? (
            <Tooltip label={t('undelete')}>
                <ActionIcon onClick={handleResetPassword}>
                    <Icon icon="restore_from_trash" size="xl" />
                </ActionIcon>
            </Tooltip>
        ) : (
            <Menu.Item leftSection={<Icon icon="restore_from_trash" size="xl" />} onClick={handleResetPassword}>
                {t('undelete')}
            </Menu.Item>
        ))
    );
};
