import { UserActivityLogGrid } from 'modules/shared/activityLog/UserActivityLogGrid';
import { DetailFormChildProps } from 'shared/components/detailForm';

type Props = {
    userId: string;
} & DetailFormChildProps;

export const ActivityLog = ({ userId }: Props) => {
    return <UserActivityLogGrid userId={userId} />;
};
