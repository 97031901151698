import { ActionIcon, Modal, Tooltip } from '@mantine/core';
import { Icon } from '@uag/react-core';
import { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { RoleAssignmentContextType } from 'api/v3/models';
import { usePermissions } from '../permissions';
import { RoleAssignmentsGrid } from './RoleAssignmentsGrid';

type Props = {
    contextType: RoleAssignmentContextType;
    contextId: string;
    itemName?: string;
};

export const ShowRoleAssignmentsAction = ({ contextType, contextId, itemName }: Props) => {
    const [isShowRoleAssignmentsOpened, setIsShowRoleAssignmentsOpened] = useState(false);
    const hasPermission = usePermissions('AssignRole');
    const { t } = useTranslation();

    const handleShowRoleAssignments = () => {
        setIsShowRoleAssignmentsOpened(true);
    };

    const handleHideRoleAssignments = () => {
        setIsShowRoleAssignmentsOpened(false);
    };

    if (!hasPermission) {
        return null;
    }

    return (
        <>
            {isShowRoleAssignmentsOpened && (
                <Modal
                    opened={isShowRoleAssignmentsOpened}
                    size={1300}
                    title={t('roleAssignmentsHeadline', { contextType, itemName })}
                    centered
                    onClick={(event: MouseEvent<HTMLDivElement>) => {
                        event.stopPropagation();
                    }}
                    onClose={handleHideRoleAssignments}
                >
                    <>
                        <RoleAssignmentsGrid
                            contextId={contextId}
                            contextType={contextType}
                            includeGlobalAssignments={false}
                            onSaved={() => setIsShowRoleAssignmentsOpened(false)}
                        />
                    </>
                </Modal>
            )}
            <Tooltip label={t('showItem', { itemType: t('roleAssignments') })}>
                <ActionIcon onClick={handleShowRoleAssignments}>
                    <Icon icon="key" size="xl" />
                </ActionIcon>
            </Tooltip>
        </>
    );
};
