import { ActionIcon, Tooltip, Menu } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { Icon, toastNotifications } from '@uag/react-core';
import { MouseEvent, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { UserState, BasicUserModelApiResponse } from 'api/v3/models';
import { useActivateUser, useDeactivateUser, getSearchUsersQueryKey } from 'api/v3/user/user';
import { usePermissions } from 'modules/shared/permissions';
import { RowActionVariant } from 'shared/components/extendedDataTable';

export const DeactivateAction = (user: BasicUserModelApiResponse, index: number, rowActionVariant: RowActionVariant) => {
    const { t } = useTranslation();
    const { mutateAsync: deactivate } = useDeactivateUser();
    const { mutateAsync: activate } = useActivateUser();
    const queryClient = useQueryClient();
    const id = useId();
    const canDeactivateUser = usePermissions('DeactivateUser', 'User', user);

    if (user.data.state === UserState.Locked || user.data.state === UserState.Deleted) {
        return null;
    }

    const isActive = user.data.state !== UserState.Deactivated;
    const labelText = isActive ? t('deactivate') : t('activate');

    const handleDeactivate = async (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        const action = isActive ? t('deactivate') : t('activate');
        toastNotifications.loading({
            id,
            message: t('willBe', {
                type: t('user'),
                name: user.data.displayName,
                action: isActive ? t('deactivated') : t('activated')
            })
        });
        try {
            isActive ? await deactivate({ id: user.data.id }) : await activate({ id: user.data.id });
            await queryClient.invalidateQueries({ queryKey: getSearchUsersQueryKey() });

            toastNotifications.sucess({
                message: t('wasSuccessful', {
                    type: t('user'),
                    name: user.data.displayName,
                    action
                })
            });
        } catch {
            toastNotifications.error({
                message: t('wasNotSuccessful', {
                    type: t('user'),
                    name: user.data.displayName,
                    action
                })
            });
        } finally {
            toastNotifications.hide(id);
        }
    };

    return (
        canDeactivateUser &&
        (rowActionVariant === RowActionVariant.DEFAULT ? (
            <Tooltip label={labelText}>
                <ActionIcon onClick={handleDeactivate}>
                    {isActive ? <Icon icon="account_circle_off" size="xl" /> : <Icon icon="person_check" size="xl" />}
                </ActionIcon>
            </Tooltip>
        ) : (
            <Menu.Item
                leftSection={isActive ? <Icon icon="account_circle_off" size="xl" /> : <Icon icon="person_check" size="xl" />}
                onClick={handleDeactivate}
            >
                {labelText}
            </Menu.Item>
        ))
    );
};
