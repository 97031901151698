import { NumberInput as ExternalNumberInput, NumberInputProps as ExternalInputProps } from '@mantine/core';
import { forwardRef, RefAttributes } from 'react';

import { IMantineFormControl } from './ExtendedInputControls';
import { InputLabel, InputLabelProps } from './InputLabel';

export type NumberInputProps = IMantineFormControl &
    InputLabelProps &
    Omit<ExternalInputProps, 'form'> &
    RefAttributes<HTMLInputElement>;

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
    ({ form, propertyPath: propertyName, label, iconDescription, optional, ...others }: NumberInputProps, ref) => {
        const props = form.getInputProps(propertyName);

        return (
            <ExternalNumberInput
                {...props}
                ref={ref}
                {...others}
                label={
                    (label || optional || iconDescription) && (
                        <InputLabel iconDescription={iconDescription} label={label} optional={optional} />
                    )
                }
            />
        );
    }
);

NumberInput.displayName = 'NumberInput';
