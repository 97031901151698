import { Outlet } from 'react-router-dom';

import { DefaultErrorBoundary } from 'shared/components';
import { BaseLayout } from 'shared/components/baseLayout/BaseLayout';
import { applicationInsightsReactPlugin } from 'utils/applicationInsights';

export const UserManagementFrame = () => {
    return (
        <BaseLayout>
            <DefaultErrorBoundary reactPlugin={applicationInsightsReactPlugin}>
                <Outlet />
            </DefaultErrorBoundary>
        </BaseLayout>
    );
};
