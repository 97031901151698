import { Text, ScrollArea, HoverCard, List } from '@mantine/core';
import { LoadingAnimation } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetIdentityPermissionAssignments } from 'api/v3/role/role';
import { getAssignmentTypeForPrincipalType } from 'modules/shared/PrincipalSelector';
import { RoleAssignmentsFormRowType } from './RoleAssignmentsFormProvider';

type Props = {
    roleAssignment: RoleAssignmentsFormRowType;
};

export const RoleColumn = ({ roleAssignment }: Props) => {
    const [isOpened, setIsOpened] = useState(false);
    const { data: permissions } = useGetIdentityPermissionAssignments(
        {
            contextType: roleAssignment.context.contextAssignmentType ?? undefined,
            contextId: roleAssignment.context.contextId ?? undefined,
            includeInheritedContextAssignments: false,
            includeInheritedPrincipalAssignments: false,
            includeGlobalAssignments: true,
            principalType: getAssignmentTypeForPrincipalType(roleAssignment.principal.principalType),
            principalIdentifier: roleAssignment.principal.id,
            roleIdentifiers: [roleAssignment.role.roleIdentifier]
        },
        { query: { enabled: isOpened } }
    );
    const { t } = useTranslation();

    const permissionDescription =
        permissions && permissions.data ? (
            permissions.data.map((permission) => (
                <List.Item key={permission.permission.permissionIdentifier}>{permission.permission.permissionIdentifier}</List.Item>
            ))
        ) : (
            <LoadingAnimation />
        );

    return (
        <HoverCard
            arrowSize={10}
            position="bottom"
            shadow="lg"
            zIndex={9999}
            withArrow
            withinPortal
            onClose={() => setIsOpened(false)}
            onOpen={() => setIsOpened(true)}
        >
            <HoverCard.Target>
                <Text td="underline">{roleAssignment.role.roleDisplayName}</Text>
            </HoverCard.Target>
            <HoverCard.Dropdown>
                <ScrollArea className=" z-50" mah={500}>
                    <div>
                        <Text>{t('includedPermissions')}</Text>
                        <List>{permissionDescription}</List>
                    </div>
                </ScrollArea>
            </HoverCard.Dropdown>
        </HoverCard>
    );
};
