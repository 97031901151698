import {
    AssignmentPrincipalType,
    CreateOrUpdateTenantModel,
    DetailTenantModel,
    TenantConfigurationModel
} from 'api/v3/models';
import { TenantFormType } from './TenantFormProvider';

export function getTenantFormValues(tenant?: DetailTenantModel, localLoginDomains?: string[]): TenantFormType {
    return tenant && localLoginDomains
        ? getTenantFormValuesFromExisting(tenant, localLoginDomains)
        : getNewTenantFormValues();
}

function getTenantFormValuesFromExisting(tenant: DetailTenantModel, localLoginDomains: string[]): TenantFormType {
    return {
        basicInformation: {
            ...tenant
        },
        localLoginDomains,
        configuration: {
            enableUserDataSynchronization: tenant.tenantConfiguration.synchronizeExternalUserProfileData,
            allowUserBrowsing: !!tenant.tenantConfiguration.allowSearchingUsers,
            isDeleteDeactivatedUsersEnabled: !!tenant.tenantConfiguration.inactiveUsersDeletionDays,
            deleteDeactivatedUsers: tenant.tenantConfiguration.inactiveUsersDeletionDays ?? 1,
            isDeactivateInactiveUsersEnabled: !!tenant.tenantConfiguration.userDeactivationDays,
            deactivateInactiveUsers: tenant.tenantConfiguration.userDeactivationDays ?? 1,
            isPasswordExpirationEnabled: !!tenant.tenantConfiguration.passwordExpirationDays,
            passwordExpiration: tenant.tenantConfiguration.passwordExpirationDays ?? 1,
            isUmdaschGroupTenant: tenant.tenantConfiguration.isUmdaschGroupTenant
        }
    };
}

function getNewTenantFormValues(): TenantFormType {
    return {
        basicInformation: {
            name: '',
            owners: [],
            collaborators: []
        },
        localLoginDomains: [],
        configuration: {
            enableUserDataSynchronization: true,
            allowUserBrowsing: true,
            isDeleteDeactivatedUsersEnabled: false,
            deleteDeactivatedUsers: 1,
            isDeactivateInactiveUsersEnabled: false,
            deactivateInactiveUsers: 1,
            isPasswordExpirationEnabled: false,
            passwordExpiration: 1,
            isUmdaschGroupTenant: false
        }
    };
}

export const getTenantModel = (tenantFormValues: TenantFormType): CreateOrUpdateTenantModel => ({
    name: tenantFormValues.basicInformation.name,
    owners: tenantFormValues.basicInformation.owners.map((owner) => ({
        identifier: owner.id ?? owner.email ?? '',
        type: owner.id === owner.email ? AssignmentPrincipalType.UserEmail : AssignmentPrincipalType.UserId
    })),
    collaborators: tenantFormValues.basicInformation.collaborators.map((collaborator) => ({
        identifier: collaborator.id ?? collaborator.email ?? '',
        type:
            collaborator.id === collaborator.email ? AssignmentPrincipalType.UserEmail : AssignmentPrincipalType.UserId
    }))
});

export const getTenantConfigurationModel = (tenantFormValues: TenantFormType): TenantConfigurationModel => ({
    passwordExpirationDays: tenantFormValues.configuration.isPasswordExpirationEnabled
        ? tenantFormValues.configuration.passwordExpiration
        : null,
    userDeactivationDays: tenantFormValues.configuration.isDeactivateInactiveUsersEnabled
        ? tenantFormValues.configuration.deactivateInactiveUsers
        : null,
    inactiveUsersDeletionDays: tenantFormValues.configuration.isDeleteDeactivatedUsersEnabled
        ? tenantFormValues.configuration.deleteDeactivatedUsers
        : null,
    allowSearchingUsers: tenantFormValues.configuration.allowUserBrowsing,
    synchronizeExternalUserProfileData: tenantFormValues.configuration.enableUserDataSynchronization,
    isUmdaschGroupTenant: tenantFormValues.configuration.isUmdaschGroupTenant
});
