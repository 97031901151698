import { useContext } from 'react';

import { RoleAssignmentContextType } from 'api/v3/models';
import { PermissionContext } from './PermissionContext';
import { PermissionUtils } from './PermissionUtils';
import { PermittedItem } from './types';

export const usePermissions = (
    permission: string | string[],
    on?: RoleAssignmentContextType,
    ...items: (PermittedItem | undefined)[]
) => {
    const permittedItem = PermissionUtils.getPermittedItem(...items);

    return usePermission(permission, on, permittedItem);
};
export const usePermission = (permission: string | string[], on?: RoleAssignmentContextType, item?: PermittedItem) => {
    const permissionContext = useContext(PermissionContext);

    if (!permissionContext?.context && !on) {
        throw new Error(
            'The hook must be either used within a permission context or the property on (value of RoleAssignmentContextType) has to be set.'
        );
    }

    const permittedItem = item ?? permissionContext?.item;

    if (!permittedItem) {
        return false;
    }

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    /* the check above ensures that either the on property or the permission context is set */

    return typeof permission === 'string'
        ? PermissionUtils.hasPermission(on ?? permissionContext!.context!, permission, permittedItem)
        : permission.some((permissionString) =>
              PermissionUtils.hasPermission(on ?? permissionContext!.context!, permissionString, permittedItem)
          );
    /* eslint-enable */
};
