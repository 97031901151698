import { Button } from '@mantine/core';
import { Icon } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { generateNewPrincipalAssignment } from 'modules/shared/manageAssignedPrincipals/functions';
import { usePrincipalAssignmentFormContext } from './FormProvider';

export const AddAssignedPrincipal = () => {
    const form = usePrincipalAssignmentFormContext();
    const { t } = useTranslation();

    return (
        <Button
            disabled={form.values.principalsAssignments.some((item) => item.isEditable)}
            leftSection={<Icon icon="add" />}
            onClick={() => form.insertListItem('principalsAssignments', generateNewPrincipalAssignment())}
        >
            {t('addItem', { itemType: t('assignment') })}
        </Button>
    );
};
