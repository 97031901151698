import { Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { MultiPersonSelect } from 'modules/shared/multiPersonSelect';
import { usePermissions } from 'modules/shared/permissions';
import { DetailFormChildProps } from 'shared/components/detailForm';
import { ExtendedTextarea, ExtendedTextInput } from 'shared/components/inputs';
import { useGroupFormContext } from '../GroupFormProvider';

type Props = DetailFormChildProps;

export const BasicInformation = (_: Props) => {
    const { t } = useTranslation();
    const form = useGroupFormContext();
    const canUpdateOrCreate = usePermissions(['Update', 'Create']);

    return (
        <Stack>
            <ExtendedTextInput
                form={form}
                label={t('name')}
                placeholder={t('namePlaceholder')}
                propertyPath="basicInformation.name"
                readOnly={!canUpdateOrCreate}
            />
            <ExtendedTextarea
                form={form}
                label={t('description')}
                maxRows={4}
                minRows={4}
                optional={t('optional')}
                placeholder={t('descriptionPlaceholder')}
                propertyPath="basicInformation.description"
                readOnly={!canUpdateOrCreate}
            />
            <MultiPersonSelect
                form={form}
                iconDescription={t('groupOwnersDescription')}
                label={t('owners')}
                placeholder={t('ownersPlaceholder')}
                propertyPath="basicInformation.owners"
                readOnly={!canUpdateOrCreate}
            />
        </Stack>
    );
};
