import { UnstyledButton, getThemeColor, useMantineTheme } from '@mantine/core';
import { CSSProperties, forwardRef } from 'react';
import { default as InitialsAvatar } from 'react-avatar';

import { default as styles } from './Avatar.module.scss';

interface AvatarProps {
    src?: string | undefined;
    name?: string | undefined;
    size?: number;
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
    ({ src, name, size = 35, ...others }: AvatarProps, ref) => {
        const baseStyles: CSSProperties = {
            border: '1px solid rgba(0, 0, 0, 0)',
            boxSizing: 'border-box',
            overflow: 'hidden'
        };
        const theme = useMantineTheme();

        return (
            <div ref={ref} {...others}>
                <UnstyledButton className={styles.avatar} style={{ ...baseStyles }}>
                    <InitialsAvatar
                        alt={''}
                        color={getThemeColor('primary', theme)}
                        maxInitials={3}
                        name={name}
                        round={true}
                        size={size?.toString()}
                        src={src}
                        title={''}
                    />
                </UnstyledButton>
            </div>
        );
    }
);

Avatar.displayName = 'Avatar';
