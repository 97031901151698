import { Route, Routes } from 'react-router-dom';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

import { useGetGroupDetailsById } from 'api/v3/groups/groups';
import { EditGroup } from './EditGroup';
import { Groups } from './Groups';

export const GroupBaseRoute = 'groups';

export const GroupBreadcrumb = ({ match }: BreadcrumbComponentProps<'groupId'>) => {
    const { data: group } = useGetGroupDetailsById(match.params.groupId ?? '');
    return <>{group?.data.name ?? '...'}</>;
};

export const GroupRoutes = () => (
    <Routes>
        <Route element={<Groups />} path="/" />
        <Route element={<EditGroup />} path="/:groupId" />
    </Routes>
);
