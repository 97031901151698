import { Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { MultiPersonSelect } from 'modules/shared/multiPersonSelect';
import { usePermissions } from 'modules/shared/permissions';
import { DetailFormChildProps } from 'shared/components/detailForm';
import { ExtendedTextInput } from 'shared/components/inputs';
import { useTenantFormContext } from '../TenantFormProvider';

type Props = DetailFormChildProps;

export const BasicInformation = (_: Props) => {
    const { t } = useTranslation();
    const form = useTenantFormContext();
    const canCreateOrUpdate = usePermissions(['Create', 'Update']);

    return (
        <Stack>
            <ExtendedTextInput
                form={form}
                label={t('name')}
                placeholder={t('namePlaceholder')}
                propertyPath="basicInformation.name"
                readOnly={!canCreateOrUpdate}
            />
            <MultiPersonSelect
                form={form}
                iconDescription={t('tenantOwnersDescription')}
                label={t('owners')}
                placeholder={t('ownersPlaceholder')}
                propertyPath="basicInformation.owners"
                readOnly={!canCreateOrUpdate}
            />
            <MultiPersonSelect
                form={form}
                iconDescription={t('tenantCollaboratorsDescription')}
                label={t('collaborators')}
                placeholder={t('collaboratorsPlaceholder')}
                propertyPath="basicInformation.collaborators"
                readOnly={!canCreateOrUpdate}
            />
        </Stack>
    );
};
