import { CreateOrUpdateApplicationModel, PrincipalModel, AssigneeModel, DetailApplicationModel } from 'api/v3/models';
import { ApplicationFormValues } from './ApplicationFormProvider';

const GetRoleAssignees = (principals?: PrincipalModel[]): AssigneeModel[] =>
    principals?.map((principal) => ({
        identifier: principal.id ?? principal.email ?? '',
        type: principal.id === principal.email ? 'UserEmail' : 'UserId'
    })) ?? [];

export function getFormApplication(Application?: DetailApplicationModel): ApplicationFormValues {
    return Application ? getFormApplicationFromExisting(Application) : getNewFormApplication();
}

export function getFormApplicationFromExisting(application: DetailApplicationModel): ApplicationFormValues {
    return {
        application
    };
}
export function getNewFormApplication(): ApplicationFormValues {
    return {
        application: {
            id: '',
            description: '',
            displayName: '',
            owners: [],
            collaborators: []
        }
    };
}
export function getApplicationModel(formApplication: ApplicationFormValues): CreateOrUpdateApplicationModel {
    return {
        ...formApplication.application,
        description: formApplication.application.description ?? '',
        owners: GetRoleAssignees(formApplication.application.owners),
        collaborators: GetRoleAssignees(formApplication.application.collaborators)
    };
}
