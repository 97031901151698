/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Umdasch Group Identity
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CreateClientSecretModel,
  CreateClientSecretResponse,
  GetClientSecretByIdResponse,
  GetClientSecretsByClientIdResponse,
  ProblemDetails
} from '.././models'
import { customMutator } from '../../customMutator-v3';
import type { ErrorType } from '../../customMutator-v3';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Returns client secrets by their clientId.<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.read
 */
export const getClientSecretsByClientId = (
    clientId: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetClientSecretsByClientIdResponse>(
      {url: `/clients/${clientId}/secrets`, method: 'GET', signal
    },
      options);
    }
  

export const getGetClientSecretsByClientIdQueryKey = (clientId: string,) => {
    return [`/clients/${clientId}/secrets`] as const;
    }

    
export const getGetClientSecretsByClientIdQueryOptions = <TData = Awaited<ReturnType<typeof getClientSecretsByClientId>>, TError = ErrorType<ProblemDetails>>(clientId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientSecretsByClientId>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClientSecretsByClientIdQueryKey(clientId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientSecretsByClientId>>> = ({ signal }) => getClientSecretsByClientId(clientId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(clientId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getClientSecretsByClientId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetClientSecretsByClientIdQueryResult = NonNullable<Awaited<ReturnType<typeof getClientSecretsByClientId>>>
export type GetClientSecretsByClientIdQueryError = ErrorType<ProblemDetails>


export function useGetClientSecretsByClientId<TData = Awaited<ReturnType<typeof getClientSecretsByClientId>>, TError = ErrorType<ProblemDetails>>(
 clientId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientSecretsByClientId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getClientSecretsByClientId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetClientSecretsByClientId<TData = Awaited<ReturnType<typeof getClientSecretsByClientId>>, TError = ErrorType<ProblemDetails>>(
 clientId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientSecretsByClientId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getClientSecretsByClientId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetClientSecretsByClientId<TData = Awaited<ReturnType<typeof getClientSecretsByClientId>>, TError = ErrorType<ProblemDetails>>(
 clientId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientSecretsByClientId>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetClientSecretsByClientId<TData = Awaited<ReturnType<typeof getClientSecretsByClientId>>, TError = ErrorType<ProblemDetails>>(
 clientId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientSecretsByClientId>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetClientSecretsByClientIdQueryOptions(clientId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Creates a new client secret for the given clientId.<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.write
 */
export const createClientSecret = (
    clientId: string,
    createClientSecretModel: CreateClientSecretModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<CreateClientSecretResponse>(
      {url: `/clients/${clientId}/secrets`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createClientSecretModel
    },
      options);
    }
  


export const getCreateClientSecretMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createClientSecret>>, TError,{clientId: string;data: CreateClientSecretModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof createClientSecret>>, TError,{clientId: string;data: CreateClientSecretModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createClientSecret>>, {clientId: string;data: CreateClientSecretModel}> = (props) => {
          const {clientId,data} = props ?? {};

          return  createClientSecret(clientId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateClientSecretMutationResult = NonNullable<Awaited<ReturnType<typeof createClientSecret>>>
    export type CreateClientSecretMutationBody = CreateClientSecretModel
    export type CreateClientSecretMutationError = ErrorType<ProblemDetails>

    export const useCreateClientSecret = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createClientSecret>>, TError,{clientId: string;data: CreateClientSecretModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof createClientSecret>>,
        TError,
        {clientId: string;data: CreateClientSecretModel},
        TContext
      > => {

      const mutationOptions = getCreateClientSecretMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Returns a client secret by its clientSecretId.<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.read
 */
export const getClientSecretById = (
    clientSecretId: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetClientSecretByIdResponse>(
      {url: `/clients/secrets/${clientSecretId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetClientSecretByIdQueryKey = (clientSecretId: string,) => {
    return [`/clients/secrets/${clientSecretId}`] as const;
    }

    
export const getGetClientSecretByIdQueryOptions = <TData = Awaited<ReturnType<typeof getClientSecretById>>, TError = ErrorType<ProblemDetails>>(clientSecretId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientSecretById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClientSecretByIdQueryKey(clientSecretId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientSecretById>>> = ({ signal }) => getClientSecretById(clientSecretId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(clientSecretId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getClientSecretById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetClientSecretByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getClientSecretById>>>
export type GetClientSecretByIdQueryError = ErrorType<ProblemDetails>


export function useGetClientSecretById<TData = Awaited<ReturnType<typeof getClientSecretById>>, TError = ErrorType<ProblemDetails>>(
 clientSecretId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientSecretById>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getClientSecretById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetClientSecretById<TData = Awaited<ReturnType<typeof getClientSecretById>>, TError = ErrorType<ProblemDetails>>(
 clientSecretId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientSecretById>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getClientSecretById>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetClientSecretById<TData = Awaited<ReturnType<typeof getClientSecretById>>, TError = ErrorType<ProblemDetails>>(
 clientSecretId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientSecretById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetClientSecretById<TData = Awaited<ReturnType<typeof getClientSecretById>>, TError = ErrorType<ProblemDetails>>(
 clientSecretId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClientSecretById>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetClientSecretByIdQueryOptions(clientSecretId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Deletes a client secret by its clientSecretId.<br><br><b>Required Scopes</b><br>login.umdaschgroup.com/clients.write
 */
export const deleteClientSecret = (
    clientSecretId: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/clients/secrets/${clientSecretId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteClientSecretMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteClientSecret>>, TError,{clientSecretId: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteClientSecret>>, TError,{clientSecretId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteClientSecret>>, {clientSecretId: string}> = (props) => {
          const {clientSecretId} = props ?? {};

          return  deleteClientSecret(clientSecretId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteClientSecretMutationResult = NonNullable<Awaited<ReturnType<typeof deleteClientSecret>>>
    
    export type DeleteClientSecretMutationError = ErrorType<ProblemDetails>

    export const useDeleteClientSecret = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteClientSecret>>, TError,{clientSecretId: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteClientSecret>>,
        TError,
        {clientSecretId: string},
        TContext
      > => {

      const mutationOptions = getDeleteClientSecretMutationOptions(options);

      return useMutation(mutationOptions);
    }
    