import { Route, Routes } from 'react-router-dom';

import { ActivityLog } from './ActivityLog';

export const ActivityLogBaseRoute = 'userAcitvities';

export const ActivityLogRoutes = () => (
    <Routes>
        <Route element={<ActivityLog />} path="/" />
    </Routes>
);
