import { Tooltip, Flex, Text, Input } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { clsx } from 'clsx';
import { useState } from 'react';

import classes from './Cell.module.css';
import { DisplayFieldRenderer } from './DisplayFieldRenderer';
import { EditFieldRenderer } from './EditFieldRenderer';
import { IRowData, IFieldDescription } from './interfaces';

type Props<TRowType extends IRowData> = {
    item: TRowType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form?: UseFormReturnType<any, any>;
    propertyPathBase?: string;
    index: number;
    fieldDescription: IFieldDescription<TRowType>;
    autoFocus?: boolean;
};

function renderPrefix<TRowType extends IRowData>(fieldDescription: IFieldDescription<TRowType>, item: TRowType) {
    if (typeof fieldDescription.prefix === 'function') {
        return fieldDescription.prefix(item);
    }
    return fieldDescription.prefix;
}

export const Cell = <TRowType extends IRowData>({ item, form, propertyPathBase, index, fieldDescription, autoFocus }: Props<TRowType>) => {
    const editMode = item.isEditable && (!fieldDescription.lockUpdate || !item.id);
    const propertyPath = `${propertyPathBase}.${index}.${fieldDescription.data}`;
    const prefix = renderPrefix(fieldDescription, item);
    const [focused, setFocused] = useState(false);

    return (
        <td className={classes.column}>
            <div className={clsx('data-table-cell-content', { [classes.deleted]: item.isRemoved })}>
                <>
                    {editMode && form && (
                        <Tooltip label={fieldDescription.description} opened={focused && !!fieldDescription.description} position="top-start">
                            <Flex align="top">
                                <Text mt={5}>{prefix}</Text>
                                <Input.Wrapper style={{ flexGrow: 1 }}>
                                    <EditFieldRenderer
                                        autoFocus={autoFocus}
                                        fieldDescription={fieldDescription}
                                        form={form}
                                        index={index}
                                        prefix={prefix}
                                        propertyPath={propertyPath}
                                        onBlur={() => setFocused(false)}
                                        onFocus={() => setFocused(true)}
                                    />
                                </Input.Wrapper>
                            </Flex>
                        </Tooltip>
                    )}
                    {!editMode && <DisplayFieldRenderer fieldDescription={fieldDescription} item={item} prefix={prefix} />}
                </>
            </div>
        </td>
    );
};
